import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import { defaultTahkCsgState } from "../constants";
import { postInitializeTahkCsg, TahkCsgStateResponse, TahkCsgStateResponseScheme } from "@/models/tahk";
import { FluidType, ModuleIdentity } from "@/models/Generic";
import { CsvData } from "@/features/app/app.types";
import { ApiError } from "@/models/APIGeneric";
import { parseErrorThrown } from "@/utils/errorHandling";
import useGenericDatasetInfo, { UseGenericDatasetInfoProps } from "@/utils/useGenericModuleInfo";

export type PollHelper = {
  setApiError: (error?: ApiError) => void;
  apiError?: ApiError;
  setIsLoading: (isLoading: boolean) => void;
  setProgress: (progress: number | null) => void;
  setPollStatus: (status?: string) => void;

  setLoadingBlocker?: (val: boolean) => void;
  setLoadingBlockerText?: (val?: string) => void;
};

type TahkCsgState = {
  tabIndex: number;
  onChangeTab: (index: number) => void;
  dataSets: string[];
  isLoading: boolean;
  tahkCsgState?: TahkCsgStateResponse | null;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  analysisIdentity?: ModuleIdentity;
  setCsvData: (csvData?: CsvData[]) => void;
  loadingDependency: boolean;
  setLoadingDependency: React.Dispatch<React.SetStateAction<boolean>>;
  fluidType: FluidType;
  expandParameter: boolean;
} & PollHelper;

type TahkCsgProviderProps = {
  children: React.ReactNode;
  isLoading: boolean;
  setCsvData: (csvData?: CsvData[]) => void;
  expandParameter: boolean;
} & PollHelper &
  UseGenericDatasetInfoProps;

const TahkCsgContext = createContext<TahkCsgState>(defaultTahkCsgState);

const TahkCsgProvider = ({
  setCsvData,
  children,
  selectedDataSets,
  isLoading,
  group,
  project,
  setApiError,
  setIsLoading,
  setPollStatus,
  setProgress,
  apiError,
  setLoadingBlocker,
  setLoadingBlockerText,
  expandParameter,
}: Readonly<TahkCsgProviderProps>) => {
  const [tahkCsgState, setTahkCsgState] = useState<TahkCsgStateResponse | null>();
  const [latestDataSets, setLatestDataSets] = useState<string[]>([]);

  const { analysisIdentity, dataSets, fluidType, setTabIndex, tabIndex } = useGenericDatasetInfo({
    selectedDataSets,
    group,
    project,
  });

  //we need to stop initializing api when other tab is still validating
  // for example switching from analysis tab -> input tab, we called validate api which can change analysis option
  // if we poll input api immediately which require analysis option, when that validate api is done, we need to call the initialize again
  // which is why we need to wait for a few seconds
  const [loadingDependency, setLoadingDependency] = useState(false);

  const client = useQueryClient();

  const { isLoading: isLoadingInitialize, isFetching } = useQuery({
    queryKey: ["initialize-tahk-csg", dataSets, analysisIdentity],
    queryFn: async () => {
      return postInitializeTahkCsg(analysisIdentity);
    },
    select(data) {
      try {
        if (data?.data && !tahkCsgState) {
          const parsed = TahkCsgStateResponseScheme.parse(data.data);
          setTahkCsgState(parsed);
          setLatestDataSets(dataSets);
        }
      } catch (error: any) {
        console.log(error.issues);
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      }
    },
    refetchOnWindowFocus: false,
    enabled: dataSets && dataSets.length > 0 && !!analysisIdentity.group_id && !!analysisIdentity.project_id && tabIndex > 0,
  });

  useEffect(() => {
    if (latestDataSets.length > 0 && !_.isEqual(latestDataSets, dataSets)) {
      setTahkCsgState(null);
      client?.invalidateQueries();
    }
  }, [client, dataSets, latestDataSets]);

  const onChangeTab = useCallback(
    (index: number) => {
      setTabIndex(index);
      setCsvData();
    },
    [setCsvData, setTabIndex]
  );

  const state = useMemo(() => {
    return {
      tabIndex,
      onChangeTab,
      dataSets,
      isLoading: isLoading || isLoadingInitialize || isFetching,
      tahkCsgState,
      setTahkCsgState,
      analysisIdentity,
      setApiError,
      setCsvData,
      setIsLoading,
      setPollStatus,
      setProgress,
      apiError,
      setLoadingBlocker,
      setLoadingBlockerText,
      loadingDependency,
      setLoadingDependency,
      fluidType,
      expandParameter,
    };
  }, [
    fluidType,
    tabIndex,
    onChangeTab,
    dataSets,
    isLoading,
    isLoadingInitialize,
    isFetching,
    tahkCsgState,
    analysisIdentity,
    setApiError,
    setCsvData,
    setIsLoading,
    setPollStatus,
    setProgress,
    apiError,
    setLoadingBlocker,
    setLoadingBlockerText,
    loadingDependency,
    setLoadingDependency,
    expandParameter,
  ]);

  return <TahkCsgContext.Provider value={state}>{children}</TahkCsgContext.Provider>;
};

export function useTahkCsgState() {
  return useContext(TahkCsgContext);
}

export default TahkCsgProvider;
