import { useMemo } from "react";
import { Row } from "@silevis/reactgrid";

import { analysisDataColumns, analysisTableHeader } from "../../constants";

import { AnalysisCalculation } from "@/models/gaz/autoRta";
import { tableHeaderStyle } from "@/components/CustomTable";
import { formatToEngineeringNotation } from "@/utils/general";

const useAutoRtaAnalysisDataTable = (analysisCalculation?: AnalysisCalculation) => {
  const modelCurvesRows = useMemo<Row[]>(() => {
    if (!analysisCalculation?.curves_model) return [];

    const headerRow: Row = {
      rowId: "header",
      height: 35,
      cells: Object.values(analysisTableHeader).map((name) => ({
        type: "header",
        text: name,
        style: tableHeaderStyle,
      })),
    };

    const curvesModel = analysisCalculation.curves_model;
    const pressureArrayKeys = curvesModel.dimensionless_pressure_arrays;
    const timeArrayKeys = curvesModel.dimensionless_time_arrays;
    const rateArrayKeys = curvesModel.dimensionless_rate_arrays;

    const allCurvesData: { [key: string]: any } = {
      ...pressureArrayKeys,
      ...timeArrayKeys,
      ...rateArrayKeys,
    };

    return [
      headerRow,
      ...analysisCalculation.curves_model.dimensionless_pressure_arrays.beta_derivative.map((_, j) => ({
        rowId: j,
        cells: Object.keys(analysisTableHeader).map((key) => {
          const text = formatToEngineeringNotation(allCurvesData[key][j]);
          return {
            type: "text",
            text,
            nonEditable: true,
            hideZero: false,
            style: { display: "flex", justifyContent: "center", zIndex: 0 },
          };
        }),
      })),
    ] as Row[];
  }, [analysisCalculation?.curves_model]);

  const dataTableRows = useMemo<Row[]>(() => {
    if (!analysisCalculation?.data_points) return [];

    const curvesData = analysisCalculation.data_points;
    const pressureArrayKeys = curvesData.dimensionless_pressure_arrays;
    const timeArrayKeys = curvesData.dimensionless_time_arrays;
    const rateArrayKeys = curvesData.dimensionless_rate_arrays;

    const allCurvesData: { [key: string]: any } = {
      ...pressureArrayKeys,
      ...timeArrayKeys,
      ...rateArrayKeys,
    };

    const headerRow: Row = {
      rowId: "header",
      height: 35,
      cells: Object.values(analysisTableHeader).map((name) => ({
        type: "header",
        text: name,
        style: tableHeaderStyle,
      })),
    };

    return [
      headerRow,
      ...analysisCalculation.data_points.dimensionless_pressure_arrays.beta_derivative.map((_, j) => ({
        rowId: j,
        cells: Object.keys(analysisTableHeader).map((key) => {
          const text = formatToEngineeringNotation(allCurvesData[key][j]);

          return {
            type: "text",
            text,
            nonEditable: true,
            hideZero: false,
            style: { display: "flex", justifyContent: "center", zIndex: 0 },
          };
        }),
      })),
    ] as Row[];
  }, [analysisCalculation]);

  return {
    dataTableRows,
    columns: analysisDataColumns,
    modelCurvesRows,
  };
};

export default useAutoRtaAnalysisDataTable;
