import { postExportFmbApi } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { ModuleIdentity, Interval } from "@/models/Generic";

type ExportPayload = {
  projectId: string;
  body: ModuleIdentity;
  interval: Interval;
};

// doesnt need fluid
export const postExportAutoFmbForecast = ({ body, interval, projectId }: ExportPayload) => {
  return fetchApi<Blob>({
    path: postExportFmbApi(projectId),
    body,
    type: "post",
    responseType: "blob",
    queryStringParameters: {
      interval,
    },
  });
};
