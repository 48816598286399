import { useCallback, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";

import { AutoRtaState, postCalculateInputAutoRta } from "@/models/gaz/autoRta";
import { DataSet, Project } from "@/model";
import { FluidType } from "@/models/Generic";
import { ApiError } from "@/models/APIGeneric";
import { parseErrorThrown } from "@/utils/errorHandling";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { InputGridCalculation, inputGridCalculationScheme } from "@/models/InputGeneric";
import { useAutoRtaState } from "../AutoRtaContext";

export type AutoRtaInputGridTabProps = {
  isLoading: boolean;
  currentTab: number;
  autoRtaState: null | AutoRtaState;
  setAutoRtaState: React.Dispatch<React.SetStateAction<AutoRtaState | null>>;
  dataSet?: DataSet[];
  project?: Project;
  fluidType: FluidType;
  setApiError: (err?: ApiError) => void;
  apiError?: ApiError;
  setValidationError: React.Dispatch<React.SetStateAction<ErrorValidationDetail[]>>;
  validationError: ErrorValidationDetail[];
};

const useAutoRtaInputGridTab = ({
  autoRtaState,
  currentTab,
  isLoading,
  setAutoRtaState,
  dataSet,
  project,
  fluidType,
  setApiError,
  apiError,
  setValidationError,
}: AutoRtaInputGridTabProps) => {
  const { setAnalysisCalculation, setAutoRtaForecastCalculation, flowingPressureOptions } = useAutoRtaState();
  const [inputGridCalculate, setInputGridCalculate] = useState<InputGridCalculation>();

  const { isFetching, isLoading: isLoadingCalculation } = useQuery({
    queryKey: ["input-grid-calculation", autoRtaState?.inputs, project?.id, dataSet],
    queryFn: async () => {
      if (autoRtaState?.inputs && dataSet && project?.id) {
        return postCalculateInputAutoRta(project.id, fluidType, {
          data_set_ids: dataSet.map((data) => data.id),
          inputs: autoRtaState.inputs,
        });
      }
    },
    refetchOnWindowFocus: false,
    enabled: currentTab === 1 && !!autoRtaState?.inputs && !!project?.id && !apiError,
    select(data) {
      try {
        if (data?.data) {
          const parsed = inputGridCalculationScheme.parse(data.data);

          if (!_.isEqual(parsed, inputGridCalculate)) setInputGridCalculate(parsed);
        }
      } catch (error: any) {
        console.log(error);
        parseErrorThrown({
          error,
          setApiError,
          apiError,
          setValidationError,
        });
      }
      return data?.data;
    },
  });

  const updateInputGridValue = useCallback(
    (value: any, key: string, objectKey: string) => {
      if (String(value) === "undefined") return;
      setValidationError([]);
      setApiError();
      setAutoRtaState((prev) => {
        const prevInputs: any = _.cloneDeep(prev);
        if (!prevInputs?.inputs?.[objectKey]) return prevInputs;
        prevInputs.inputs[objectKey] = {
          ...prevInputs.inputs[objectKey],
          [key]: value,
        };
        return prevInputs;
      });
      // Reset analysisCalculation, forecastCalculation
      setAnalysisCalculation(undefined);
      setAutoRtaForecastCalculation(undefined);
    },
    [setValidationError, setAutoRtaState, setApiError, setAnalysisCalculation, setAutoRtaForecastCalculation]
  );

  return {
    inputGridValues: autoRtaState?.inputs,
    inputGridCalculate,
    loadInputGrid: isFetching || isLoading || isLoadingCalculation,
    updateInputGridValue,
    flowingPressureOptions,
  };
};
export default useAutoRtaInputGridTab;
