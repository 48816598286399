import { useCallback, useMemo, useState } from "react";
import { CellChange, DateCell, DropdownCell, Row } from "@silevis/reactgrid";
import _ from "lodash";

import {
  AutoFmbState,
  ForecastFmbRequest,
  ForecastCalculationState,
  forecastFmbResultResponseScheme,
  postExportAutoFmbForecast,
} from "@/models/autoFmb";
import { AutoForecastEvent, Interval, ModuleIdentity } from "@/models/Generic";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

import { tableHeaderNotationStyle, tableHeaderStyle } from "@/components/CustomTable";

import { usePolling } from "@/utils/apiFetcher";
import dictionary from "@/constants/dictionary";
import { pollAutoFmbForecastCalcApi } from "@/constants/apiUrl";
import { convertDateToUtcTimeZoneIsoString } from "@/utils/dateTime";

import { ApiHelper, SummaryFmb } from "../context/AutoFmbContext";
import useThemeStyling from "@/utils/useThemeStyling";
import { saveBlob } from "@/util";
import { parseErrorThrown } from "@/utils/errorHandling";

export const forecastEventTableColumn = [
  {
    columnId: "dates",
    width: 100,
    label: "",
    notation: "",
  },
  {
    columnId: "flowing_pressure",
    width: 90,
    label: dictionary.fmb.flowingPressure,
    notation: dictionary.tableUnits.flowingPressure,
  },
  {
    columnId: "flowing_rate",
    width: 105,
    label: dictionary.fmb.flowingRate,
    notation: dictionary.tableUnits.flowingRate,
  },
  {
    columnId: "bound_condition_is_rate",
    width: 120,
    label: dictionary.fmb.constraint,
    notation: "",
  },
];

const dropdownOption = [
  {
    value: "Rate",
    label: "Rate",
  },
  {
    value: "Pressure",
    label: "Pressure",
  },
];

type UseAutoFmbForecastProps = {
  autoFmbState?: AutoFmbState;
  setAutoFmbState: React.Dispatch<React.SetStateAction<AutoFmbState | undefined>>;
  analysisIdentity?: ModuleIdentity;
  tabIndex: number;
  isLoading: boolean;
  setAutoFmbForecastCalculation: React.Dispatch<React.SetStateAction<ForecastCalculationState | undefined>>;
  autoFmbForecastCalculation?: ForecastCalculationState;
} & ApiHelper;

const useAutoFmbForecast = ({
  setApiError,
  setAutoFmbState,
  setIsLoading,
  setPollStatus,
  setProgress,
  analysisIdentity,
  apiError,
  autoFmbState,
  isLoading,
  setAutoFmbForecastCalculation,
  autoFmbForecastCalculation,
}: UseAutoFmbForecastProps) => {
  const [errorInputValidation, setErrorInputValidation] = useState<ErrorValidationDetail[]>([]);
  const [isDropdownOpened, setIsDropdownOpened] = useState<number>(-1);

  const { palette } = useThemeStyling();

  const { createPoll } = usePolling({
    setApiError,
    setLoadingState: setIsLoading,
    setProgressStatus: (val) => {
      setProgress(val.progress ?? null);
      setPollStatus(val.pollStatus);
    },
    setErrorValidation: setErrorInputValidation,
    apiError,
    setInfoContinuous: (value: { task_status: string; task_info: any }) => {
      console.log(value.task_info);
    },
  });

  const onCalculateForecastFmb = useCallback(async () => {
    if (
      !autoFmbState?.inputs ||
      !autoFmbState.analysis ||
      !autoFmbState.forecast ||
      !analysisIdentity?.project_id ||
      (analysisIdentity?.data_set_ids && analysisIdentity?.data_set_ids.length < 1)
    )
      return;

    try {
      setErrorInputValidation([]);

      const calculation = await createPoll<ForecastCalculationState, ForecastFmbRequest>({
        path: pollAutoFmbForecastCalcApi(analysisIdentity.project_id),
        type: "post",
        body: {
          data_set_ids: analysisIdentity.data_set_ids,
          inputs: autoFmbState.inputs,
          analysis: autoFmbState.analysis,
          forecast: autoFmbState.forecast,
        },
      });
      if (calculation.task_result) {
        const parsed = forecastFmbResultResponseScheme.parse(calculation.task_result);
        setAutoFmbForecastCalculation({
          field_data: parsed.forecast_result.field_data,
          forecast_result: parsed.forecast_result,
        });
        if (!_.isEqual(autoFmbState.forecast, parsed.forecast_inputs)) {
          setAutoFmbState((prev) => {
            if (!prev) return prev;
            return {
              ...prev,
              forecast: parsed.forecast_inputs,
            };
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    analysisIdentity?.data_set_ids,
    analysisIdentity?.project_id,
    autoFmbState?.analysis,
    autoFmbState?.forecast,
    autoFmbState?.inputs,
    createPoll,
    setAutoFmbForecastCalculation,
    setAutoFmbState,
  ]);

  const updateForecastFmb = useCallback(
    (key: string, value: any) => {
      setErrorInputValidation([]);
      setApiError();
      setAutoFmbForecastCalculation(undefined);
      setAutoFmbState((prev) => {
        const prevInputs: any = _.cloneDeep(prev);
        if (!prevInputs?.forecast) return prevInputs;
        prevInputs.forecast = {
          ...prevInputs.forecast,
          [key]: value,
        };
        return prevInputs;
      });
    },
    [setApiError, setAutoFmbState, setAutoFmbForecastCalculation]
  );

  const flowingPressureRow: Row<any>[] = useMemo(() => {
    const headerNotationRows = [
      {
        rowId: "header",
        height: 55,
        cells: [
          ...forecastEventTableColumn.map((col) => {
            return {
              type: "header",
              text: col.label,
              style: tableHeaderStyle,
            };
          }),
        ],
      },
      {
        rowId: "notation",
        height: 30,
        cells: [
          ...forecastEventTableColumn.map((col) => {
            return {
              type: "header",
              text: col.notation,
              style: tableHeaderNotationStyle,
            };
          }),
        ],
      },
    ];
    if (!autoFmbState?.forecast?.forecast_events) return headerNotationRows;
    const style = {
      backgroundColor: autoFmbState?.forecast.smart_fill ? "rgba(128, 128, 128, 0.1)" : "white",
      cursor: autoFmbState?.forecast.smart_fill ? "not-allowed" : "auto",
    };

    // combine date array from backend with the frontend empty array
    const combinedDateArray = [...autoFmbState.forecast.forecast_events.dates, ...Array(100).fill(undefined)];
    const constantInputsRows = [
      ...headerNotationRows,
      ...(combinedDateArray.map((date, i) => {
        return {
          rowId: i,
          cells: [
            ...forecastEventTableColumn.map((col) => {
              const isRate = autoFmbState.forecast.forecast_events.bound_condition_is_rate?.[i];

              let rateSelectedVal: any = isRate ? "Rate" : "Pressure";

              if (String(isRate) === "undefined") rateSelectedVal = undefined;

              if (col.columnId === "dates") {
                return {
                  type: "date",
                  date: date ? new Date(date) : undefined,
                  format: Intl.DateTimeFormat(),
                  hideZero: true,
                  nonEditable: autoFmbState?.forecast.smart_fill || isLoading,
                  style,
                };
              }
              if (col.columnId === "bound_condition_is_rate") {
                return {
                  type: "dropdown",
                  selectedValue: rateSelectedVal,
                  values: dropdownOption,
                  isOpen: isDropdownOpened === i,
                  nonEditable: autoFmbState?.forecast.smart_fill || isLoading,

                  style: {
                    ...style,
                    color: isRate ? palette.success.main : palette.warning.main,
                  },
                };
              }
              return {
                type: "number",
                value: autoFmbState.forecast.forecast_events[col.columnId as keyof AutoForecastEvent]?.[i] ?? NaN,
                nonEditable: autoFmbState?.forecast.smart_fill || isLoading,
                style,
              };
            }),
          ],
        };
      }) ?? []),
    ] as Row[];

    return constantInputsRows;
  }, [autoFmbState, isDropdownOpened, isLoading, palette.success.main, palette.warning.main]);

  const onTableCellChange = useCallback(
    (changes: CellChange[]) => {
      if (!autoFmbState?.analysis) return;
      let haveChange = false;

      setErrorInputValidation([]);
      setAutoFmbForecastCalculation(undefined);
      const updatedForecastEvent: AutoForecastEvent = _.cloneDeep(autoFmbState.forecast.forecast_events);

      // Apply the changes to the cloned data
      for (const element of changes) {
        let { rowId, columnId, newCell, type, previousCell } = element as CellChange<any>;
        rowId = rowId as number;
        columnId = columnId as string;

        if (type === "dropdown") {
          const prevCell = previousCell as DropdownCell;
          const dropDownNewCell = newCell as DropdownCell;

          const newDropdown = dropDownNewCell.isOpen ? rowId : -1;
          if (prevCell.isOpen !== dropDownNewCell.isOpen && newDropdown !== isDropdownOpened) {
            setIsDropdownOpened(newDropdown);
          } else {
            setIsDropdownOpened(-1);
          }
          if (dropDownNewCell.isOpen) return;
          if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
            haveChange = true;
            updatedForecastEvent.bound_condition_is_rate[rowId] = dropDownNewCell.selectedValue === "Rate";
          }
        } else {
          let val = newCell.value ?? newCell.text ?? 0;
          if (columnId === "dates") {
            const dateCell = newCell as DateCell;
            val = convertDateToUtcTimeZoneIsoString(new Date(dateCell.date ?? 0));
          }

          updatedForecastEvent[columnId as keyof AutoForecastEvent][rowId] = val;
          haveChange = true;
        }
      }

      if (haveChange) {
        setAutoFmbState((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            forecast: {
              ...prev.forecast,
              forecast_events: updatedForecastEvent,
            },
          };
        });
      }
    },
    [autoFmbState?.analysis, setAutoFmbForecastCalculation, isDropdownOpened, autoFmbState?.forecast.forecast_events, setAutoFmbState]
  );

  const summaryTableData = useMemo(() => {
    // parse the low mid high table into 1 table
    // so 1 row will contain: parameter, low, mid, high, units
    // difference in unit will be ignore
    const result: SummaryFmb = [];

    if (autoFmbForecastCalculation?.forecast_result) {
      // take either one, because it is guaranteed to be the same + parse using index
      autoFmbForecastCalculation.forecast_result?.low.summary_card.forEach((item, index) => {
        const midItem = autoFmbForecastCalculation.forecast_result?.mid.summary_card;
        const highItem = autoFmbForecastCalculation.forecast_result?.high.summary_card;

        result.push({
          parameter: item.parameter,
          low: item.value,
          mid: midItem[index]?.value,
          high: highItem[index]?.value,
          units: item.unit,
        });
      });
    }
    return result;
  }, [autoFmbForecastCalculation?.forecast_result]);

  const onExportForecastFmb = useCallback(
    async (interval: Interval) => {
      try {
        if (!analysisIdentity) return;

        setIsLoading(true);
        const res = await postExportAutoFmbForecast({
          projectId: analysisIdentity.project_id,
          body: analysisIdentity,
          interval,
        });

        if (res.data) {
          const fileName = `${res.headers["content-disposition"].split("filename=")[1]}`;
          saveBlob(res.data, fileName);
        }
      } catch (error: any) {
        console.log(error);

        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [analysisIdentity, apiError, setApiError, setIsLoading]
  );

  return {
    onCalculateForecastFmb,
    flowingPressureRow,
    onTableCellChange,
    updateForecastFmb,
    errorInputValidation,
    autoFmbForecastCalculation,
    summaryTableData,
    onExportForecastFmb,
  };
};

export default useAutoFmbForecast;
