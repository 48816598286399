import React, { ReactNode, useRef } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";

import { MenuSection } from "./useNavMenu";

const MenuNav = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-family: "Baloo 2", sans-serif;
  margin-left: 15px;

  a {
    text-decoration: none;
    color: unset;
  }
`;

type NavMenuProps = {
  customTitle?: ReactNode;
  title: string;
  menuList?: MenuSection[];
  customFunc?: {
    id: string;
    onClick: () => void;
  };
  resetProject?: () => void;
};

const NavMenu = ({ title, menuList = [], customTitle, customFunc, resetProject }: NavMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const csvLink = useRef<any>();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!window.location.pathname.includes("/modules") && !customTitle) {
      navigate("/modules");
      resetProject?.();
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuItem = (menu: MenuSection) => {
    return menu.items.map((item, index) => {
      return (
        <MenuItem
          disabled={item.disabled}
          key={item.label}
          onClick={() => {
            if (customFunc && item.id === customFunc.id) {
              customFunc.onClick();
            }
            if (item.id === "export-csv") csvLink?.current?.link?.click();
            item.onClickMenu();
            handleClose();
          }}
          style={{
            paddingBottom: index !== menu.items.length - 1 ? 0 : 6,
          }}
        >
          <ListItemText primary={item.label} />
        </MenuItem>
      );
    });
  };

  return (
    <MenuNav>
      <span id={"nav-title"} onClick={handleClick} onKeyDown={(event) => event.key === "Enter" && handleClick}>
        {customTitle ?? title}
      </span>

      {menuList.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {menuList.map((menu, index) => {
            const allMenuDisabled = menu.items.filter((item) => !item.disabled).length === 0;

            return (
              <div key={menu.section}>
                <List
                  component="div"
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  {menu.hideSectionTitle ? (
                    <>
                      {menu.items.length > 0 ? (
                        renderMenuItem(menu)
                      ) : (
                        <div
                          style={{
                            padding: "8px 16px",
                          }}
                        >
                          <ListItemText primary={menu?.section} />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {index !== 0 && <Divider />}

                      <div
                        style={{
                          padding: "8px 16px",
                          paddingBottom: 0,
                          opacity: allMenuDisabled ? 0.38 : 1,
                        }}
                      >
                        <ListItemText primary={menu.section} />
                      </div>

                      <List style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 0, paddingBottom: 0 }}>{renderMenuItem(menu)}</List>
                    </>
                  )}
                </List>
              </div>
            );
          })}
        </Menu>
      )}
    </MenuNav>
  );
};

export default NavMenu;
