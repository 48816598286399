import { useMemo, useState } from "react";
import { Row } from "@silevis/reactgrid";

import useThemeStyling from "@/utils/useThemeStyling";
import dictionary from "@/constants/dictionary";
import { AutoFmbState, ForecastCalculationState } from "@/models/autoFmb";
import { FossilyticsChartSeries } from "@/components/FossilyticsChart";

import { caseKey } from "../constants";
import { tableCellStyle, tableHeaderNotationStyle, tableHeaderStyle } from "@/components/CustomTable";
import { capitalizeFirstLetter, formatToEngineeringNotation } from "@/utils/general";
import symbolUnits from "@/constants/symbolUnit";
import { transformHeaderOrNotation } from "@/utils/cellRendering";

type UseAutoFmbForecastResultProps = {
  autoFmbForecastCalculation?: ForecastCalculationState;
  autoFmbState?: AutoFmbState;
};

const forecastFmbModelDataTableList = [
  "dates",
  "flowing_pressure",
  "dry_gas_rate",
  "dry_cumulative_gas",
  "recombined_gas_rate",
  "recombined_cumulative_gas",
  "reservoir_pressure",
  "pseudo_reservoir_pressure",
  "pseudo_flowing_pressure",
  "gas_formation_volume_factor",
  "gas_viscosity",
  "gas_compressibility",
  "z",
];

const fieldGasDataTableHeader = [
  "dates",
  "flowing_pressure",
  "dry_gas_rate",
  "dry_cumulative_gas",
  "recombined_gas_rate",
  "recombined_cumulative_gas",
];

const safeDic: { [key: string]: string } = dictionary.fmb;

const useAutoFmbForecastResult = ({ autoFmbForecastCalculation, autoFmbState }: UseAutoFmbForecastResultProps) => {
  const { palette } = useThemeStyling();

  const [selectedCase, setSelectedCase] = useState<string>(caseKey[0]);

  const chartAxes = useMemo(() => {
    return {
      xAxes: [{ name: dictionary.genericChart.date, type: "time", color: palette.customColor.black }],
      yAxes: [
        { name: dictionary.genericChart.gasRate, type: "value", color: palette.customColor.red },
        { name: dictionary.genericChart.pressure, type: "value", color: palette.customColor.black },
      ],
    };
  }, [palette.customColor.black, palette.customColor.red]);

  const colorScheme = useMemo(() => {
    const seriesColorScheme = [palette.customColor.pinkLight, palette.customColor.red, palette.customColor.redDark];
    return seriesColorScheme;
  }, [palette.customColor.pinkLight, palette.customColor.red, palette.customColor.redDark]);

  const forecastCalcSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (autoFmbForecastCalculation?.forecast_result && autoFmbForecastCalculation?.field_data) {
      const safeFieldData = autoFmbForecastCalculation.field_data;

      const metrics = [
        {
          type: "dry_gas_rate",
          nameKey: `${dictionary.autoRta.modelled} ${dictionary.autoRta.gasRate}`,
          colorScheme,
          yAxisIndex: 0,
          defaultDisabled: false,
        },
        {
          type: "recombined_gas_rate",
          nameKey: `${dictionary.autoRta.modelled} ${dictionary.autoRta.recombinedGasRate}`,
          colorScheme,
          defaultDisabled: true,
          yAxisIndex: 0,
        },
        {
          type: "flowing_pressure",
          nameKey: `${dictionary.autoRta.modelled} ${dictionary.gas.flowingPressure}`,
          colorScheme,
          defaultDisabled: true,
          yAxisIndex: 1,
        },
      ];

      let mappedCase: FossilyticsChartSeries[] = [];

      metrics.forEach((metric) => {
        const eachMap = caseKey.map((key, index) => {
          const safeData: any = autoFmbForecastCalculation?.forecast_result[key as keyof ForecastCalculationState["forecast_result"]].data;

          return {
            name: `${safeDic[key]} ${metric.nameKey}`,
            type: "line",
            color: metric.colorScheme[index],
            yAxisIndex: metric.yAxisIndex,
            data: safeData.dates.map((x: string, i: number) => [x, safeData[metric.type][i]]) ?? [],
            hideSymbol: true,
            lineWidth: 2,
            defaultDisabled: metric.defaultDisabled,
          };
        });
        mappedCase = [...mappedCase, ...eachMap];
      });

      return [
        ...mappedCase,
        {
          name: `${dictionary.autoRta.measured} ${dictionary.autoRta.gasRate}`,
          type: "scatter",
          color: palette.customColor.red,
          yAxisIndex: 0,
          data: safeFieldData.dates.map((x: string, i: number) => [x, safeFieldData.dry_gas_rate?.[i]]) ?? [],
          hideSymbol: true,
          lineWidth: 2,
        },
        {
          name: `${dictionary.autoRta.measured} ${dictionary.autoRta.recombinedGasRate}`,
          type: "scatter",
          color: palette.customColor.red,
          defaultDisabled: true,
          yAxisIndex: 0,
          data: safeFieldData.dates.map((x: string, i: number) => [x, safeFieldData.recombined_gas_rate?.[i]]) ?? [],
          hideSymbol: true,
          lineWidth: 2,
        },
      ];
    }
    return [];
  }, [autoFmbForecastCalculation?.field_data, autoFmbForecastCalculation?.forecast_result, colorScheme, palette.customColor.red]);

  const cumulativeChartAxes = useMemo(() => {
    return {
      xAxes: [{ name: dictionary.genericChart.date, type: "time", color: palette.customColor.black }],
      yAxes: [{ name: dictionary.genericChart.gasRate, type: "value", color: palette.customColor.red }],
    };
  }, [palette.customColor.black, palette.customColor.red]);

  const cumulativeChartSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!autoFmbForecastCalculation?.forecast_result || !autoFmbForecastCalculation?.field_data) return [];
    const safeFieldData = autoFmbForecastCalculation.field_data;

    const metrics = [
      {
        type: "dry_cumulative_gas",
        nameKey: `${dictionary.autoRta.modelled} ${dictionary.autoRta.gasCumulative}`,
        colorScheme,
        defaultDisabled: false,
      },
      {
        type: "recombined_cumulative_gas",
        nameKey: `${dictionary.autoRta.modelled} ${dictionary.autoRta.recombinedGasCumulative}`,
        colorScheme: colorScheme,
        defaultDisabled: true,
      },
    ];

    let mappedCase: FossilyticsChartSeries[] = [];

    metrics.forEach((metric) => {
      const eachMap = caseKey.map((key, index) => {
        const safeData: any = autoFmbForecastCalculation?.forecast_result[key as keyof ForecastCalculationState["forecast_result"]].data;

        return {
          name: `${safeDic[key]} ${metric.nameKey}`,
          type: "line",
          color: metric.colorScheme[index],
          yAxisIndex: 0,
          data: safeData.dates.map((x: string, i: number) => [x, safeData[metric.type][i]]) ?? [],
          hideSymbol: true,
          lineWidth: 3,
          defaultDisabled: metric.defaultDisabled,
        };
      });
      mappedCase = [...mappedCase, ...eachMap];
    });

    return [
      ...mappedCase,
      {
        name: `${dictionary.autoRta.measured} ${dictionary.autoRta.gasCumulative}`,
        type: "scatter",
        color: palette.customColor.red,
        yAxisIndex: 0,
        data: safeFieldData.dates.map((x: string, i: number) => [x, safeFieldData.dry_cumulative_gas[i]]) ?? [],
        hideSymbol: true,
        lineWidth: 3,
      },
      {
        name: `${dictionary.autoRta.measured} ${dictionary.autoRta.recombinedGasCumulative}`,
        type: "scatter",
        color: palette.customColor.red,
        yAxisIndex: 0,
        data: safeFieldData.dates.map((x: string, i: number) => [x, safeFieldData.recombined_cumulative_gas[i]]) ?? [],
        hideSymbol: true,
        lineWidth: 3,
        defaultDisabled: true,
      },
    ];
  }, [autoFmbForecastCalculation?.field_data, autoFmbForecastCalculation?.forecast_result, colorScheme, palette.customColor.red]);

  const forecastCalculationDataTableCol = useMemo(() => {
    return forecastFmbModelDataTableList.map((header) => {
      return {
        columnId: header,
      };
    });
  }, []);

  const forecastCalculationDataTableRow = useMemo(() => {
    if (!autoFmbForecastCalculation?.forecast_result) return [];

    const safeCalc: any = autoFmbForecastCalculation?.forecast_result[selectedCase as keyof ForecastCalculationState["forecast_result"]].data;
    const constantInputsRows = [
      {
        rowId: "header",
        height: 50,
        cells: forecastFmbModelDataTableList.map((head: string) => {
          const item = autoFmbState?.symbols_key[head] ?? "";

          return {
            type: "custom",
            isLatex: item,
            text: symbolUnits?.[item] ?? capitalizeFirstLetter(transformHeaderOrNotation(head)),
            style: tableHeaderStyle,
          };
        }),
      },

      {
        rowId: "notation",
        height: 30,
        cells: forecastFmbModelDataTableList.map((head: string) => {
          return {
            type: "header",
            text: transformHeaderOrNotation(autoFmbState?.units_key[head]),
            style: tableHeaderNotationStyle,
          };
        }),
      },
      ...safeCalc.dates.map((_: any, index: number) => {
        return {
          key: index,
          rowId: index + 1,
          cells: forecastFmbModelDataTableList.map((head) => {
            const result: { [key: string]: any } = {
              type: head === "dates" ? "date" : "text",
              nonEditable: true,
              style: tableCellStyle,
            };
            let text = safeCalc[head][index];
            if (head === "dates") {
              result.date = new Date(text);
              result.format = Intl.DateTimeFormat();
            } else {
              result.text = formatToEngineeringNotation(text);
            }
            return result;
          }),
        };
      }),
    ] as Row[];
    return constantInputsRows;
  }, [autoFmbForecastCalculation?.forecast_result, autoFmbState?.symbols_key, autoFmbState?.units_key, selectedCase]);

  const fieldDataRow = useMemo(() => {
    if (!autoFmbForecastCalculation?.field_data) return [];
    const safeCalc: any = autoFmbForecastCalculation?.field_data;

    const constantInputsRows = [
      {
        rowId: "header",
        height: 50,
        cells: fieldGasDataTableHeader.map((head: string) => {
          const item = autoFmbState?.symbols_key[head] ?? "";

          return {
            type: "custom",
            isLatex: item,
            text: symbolUnits?.[item] ?? capitalizeFirstLetter(transformHeaderOrNotation(head)),
            style: tableHeaderStyle,
          };
        }),
      },
      {
        rowId: "notation",
        height: 30,
        cells: fieldGasDataTableHeader.map((head: string) => {
          return {
            type: "header",
            text: transformHeaderOrNotation(autoFmbState?.units_key[head]),
            style: tableHeaderNotationStyle,
          };
        }),
      },
      ...safeCalc.dates.map((_: any, index: number) => {
        return {
          key: index,
          rowId: index + 1,
          cells: fieldGasDataTableHeader.map((head) => {
            const result: { [key: string]: any } = {
              type: head === "dates" ? "date" : "text",
              nonEditable: true,
              style: tableCellStyle,
            };
            let text = safeCalc[head][index];
            if (head === "dates") {
              result.date = new Date(text);
              result.format = Intl.DateTimeFormat();
            } else {
              result.text = formatToEngineeringNotation(text);
            }
            return result;
          }),
        };
      }),
    ] as Row[];
    return constantInputsRows;
  }, [autoFmbForecastCalculation?.field_data, autoFmbState?.symbols_key, autoFmbState?.units_key]);

  const fieldDataCol = useMemo(() => {
    return fieldGasDataTableHeader.map((header) => {
      return {
        columnId: header,
      };
    });
  }, []);

  return {
    setSelectedCase,
    chartAxes,
    forecastCalcSeries,
    cumulativeChartAxes,
    cumulativeChartSeries,
    forecastCalculationDataTableCol,
    forecastCalculationDataTableRow,
    fieldDataCol,
    fieldDataRow,
    selectedCase,
  };
};

export default useAutoFmbForecastResult;
