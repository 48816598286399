import React, { useCallback } from "react";
import { AFA_WELL_TYPE } from "../constants";
import { Popover, MenuList, MenuItem, ListItemText, ListItemIcon, Checkbox, Box, Divider, Button } from "@mui/material";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { Tags } from "../types";

interface TagFilterMenuProps {
  anchorEl: HTMLElement | null;
  openTagKeys: string[];
  setOpenTagKeys: React.Dispatch<React.SetStateAction<string[]>>;
  handleMenuClose: () => void;
  tags: Tags;
  selectedTags: Record<string, string[]>;
  setSelectedTags: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
  hasModifiedDefaultSelection: boolean;
}

const TagFilterMenu: React.FC<TagFilterMenuProps> = ({
  anchorEl,
  openTagKeys,
  setOpenTagKeys,
  handleMenuClose,
  tags,
  selectedTags,
  setSelectedTags,
  hasModifiedDefaultSelection,
}) => {
  // Ensure 'AFA-WELL_TYPE' shows up first
  const tagKeys = [AFA_WELL_TYPE, ...Object.keys(tags).filter((key) => key !== AFA_WELL_TYPE)];

  const handleTagKeyClick = (tagKey: string) => {
    setOpenTagKeys((prevOpenTagKeys) =>
      prevOpenTagKeys.includes(tagKey) ? prevOpenTagKeys.filter((key) => key !== tagKey) : [...prevOpenTagKeys, tagKey]
    );
  };

  const handleTagValueToggle = useCallback(
    (tagKey: string, tagValue: string) => {
      setSelectedTags((prevSelectedTags) => {
        const tagValues = prevSelectedTags[tagKey] || [];
        if (tagValues.includes(tagValue)) {
          const newTagValues = tagValues.filter((value) => value !== tagValue);
          if (newTagValues.length === 0) {
            const { [tagKey]: _, ...rest } = prevSelectedTags;
            return rest;
          } else {
            return { ...prevSelectedTags, [tagKey]: newTagValues };
          }
        } else {
          return { ...prevSelectedTags, [tagKey]: [...tagValues, tagValue] };
        }
      });
    },
    [setSelectedTags]
  );

  const areAllValuesSelected = useCallback(
    (tagKey: string): boolean => {
      const allTagValues = Object.keys(tags[tagKey] || {});
      const selectedTagValues = selectedTags[tagKey] || [];
      return allTagValues.length > 0 && selectedTagValues.length === allTagValues.length;
    },
    [tags, selectedTags]
  );

  const areSomeValuesSelected = useCallback(
    (tagKey: string): boolean => {
      const allTagValues = Object.keys(tags[tagKey] || {});
      const selectedTagValues = selectedTags[tagKey] || [];
      return selectedTagValues.length > 0 && selectedTagValues.length < allTagValues.length;
    },
    [tags, selectedTags]
  );

  const handleSelectAllToggle = useCallback(
    (tagKey: string) => {
      const allTagValues = Object.keys(tags[tagKey] || {});
      setSelectedTags((prevSelectedTags) => {
        const selectedTagValues = prevSelectedTags[tagKey] || [];
        if (selectedTagValues.length === allTagValues.length) {
          const { [tagKey]: _, ...rest } = prevSelectedTags;
          return rest;
        } else {
          return { ...prevSelectedTags, [tagKey]: allTagValues };
        }
      });
    },
    [tags, setSelectedTags]
  );

  const handleClearFilters = () => {
    setSelectedTags({
      [AFA_WELL_TYPE]: Object.keys(tags[AFA_WELL_TYPE] || {}),
    });
  };

  const getMenuItemTagKeyColor = (tagKey: string) => {
    if (tagKey === AFA_WELL_TYPE && areAllValuesSelected(AFA_WELL_TYPE)) {
      return "inherit";
    } else if (selectedTags[tagKey]?.length > 0) {
      return "primary.main";
    }
    return "inherit";
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      data-testid="tag-filter-menu"
    >
      {hasModifiedDefaultSelection && (
        <>
          <Box
            data-testid="clear-filters-box"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "2px",
            }}
          >
            <Button
              data-testid="clear-filters-button"
              onClick={handleClearFilters}
              sx={{
                color: "primary.main",
                textTransform: "none",
              }}
            >
              Clear
            </Button>
          </Box>
          <Divider sx={{ margin: 0 }} />
        </>
      )}
      <MenuList data-testid="tag-filter-menu-list" style={{ minWidth: "200px", maxHeight: "800px" }}>
        {tagKeys.map((tagKey) => {
          const tagValuesDict = tags[tagKey];
          if (!tagValuesDict) return null;

          return (
            <div key={tagKey} id={tagKey}>
              <MenuItem
                data-testid={`tag-key-${tagKey}`}
                onClick={() => handleTagKeyClick(tagKey)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: getMenuItemTagKeyColor(tagKey),
                }}
              >
                <ListItemText primary={tagKey} />
                <ListItemIcon>{openTagKeys.includes(tagKey) ? <ExpandMore /> : <ChevronRight />}</ListItemIcon>
              </MenuItem>
              {openTagKeys.includes(tagKey) && (
                <Box sx={{ pl: 2 }}>
                  {/* Add Select All option */}
                  <MenuItem
                    key={`${tagKey}-select-all`}
                    data-testid={`${tagKey}-select-all`}
                    sx={{ py: 0.25, minHeight: "20px" }}
                    onClick={() => handleSelectAllToggle(tagKey)}
                  >
                    <ListItemIcon sx={{ minWidth: "20px", color: "inherit" }}>
                      <Checkbox
                        edge="start"
                        data-testid={tagKey}
                        checked={areAllValuesSelected(tagKey)}
                        indeterminate={areSomeValuesSelected(tagKey) && !areAllValuesSelected(tagKey)}
                        tabIndex={-1}
                        disableRipple
                        size="small"
                        sx={{ height: "20px" }}
                        onClick={(event) => event.stopPropagation()}
                        onChange={() => handleSelectAllToggle(tagKey)}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Select All" sx={{ margin: 0 }} />
                  </MenuItem>
                  {/* Render tag values */}
                  {Object.keys(tagValuesDict || {}).map((tagValue) => {
                    const isSelected = !!selectedTags[tagKey]?.includes(tagValue);
                    return (
                      <MenuItem
                        key={`${tagKey}-${tagValue}`}
                        data-testid={`tag-value-${tagKey}-${tagValue}`}
                        sx={{ py: 0.25, minHeight: "20px" }}
                        onClick={() => handleTagValueToggle(tagKey, tagValue)}
                      >
                        <ListItemIcon sx={{ minWidth: "20px", color: "inherit" }}>
                          <Checkbox
                            edge="start"
                            checked={isSelected}
                            tabIndex={-1}
                            disableRipple
                            size="small"
                            sx={{ height: "20px" }}
                            onClick={(event) => event.stopPropagation()}
                            onChange={() => handleTagValueToggle(tagKey, tagValue)}
                          />
                        </ListItemIcon>
                        <ListItemText primary={tagValue} sx={{ margin: 0 }} />
                      </MenuItem>
                    );
                  })}
                </Box>
              )}
            </div>
          );
        })}
      </MenuList>
    </Popover>
  );
};

export default TagFilterMenu;
