import { SummaryCardScheme } from "@/models/Generic";
import { object, z, number, nativeEnum, boolean } from "zod";

export enum TransientModelBoundaryConditions {
  NoFlow = "NoFlow",
  InfiniteActing = "InfiniteActing",
  ConstantPressure = "ConstantPressure",
}

export enum DualPorosityModel {
  NoModel = "NoModel",
  WarrenRoot = "WarrenRoot",
  BelloSlab = "BelloSlab",
}

export enum TransientModel {
  Radial = "Radial",
  Linear = "Linear",
  RadialComposite = "RadialComposite",
  UniformFluxFracture = "UniformFluxFracture",
  FiniteConductivityFracture = "FiniteConductivityFracture",
  MultiFractureHorizontalRadialReservoir = "MultiFractureHorizontalRadialReservoir",
}

const transientModelRadialScheme = object({
  drainage_radius: number(),
});

const transientModelLinearScheme = object({
  drainage_length: number(),
  drainage_width: number(),
});

const transientModelCompositeRadialScheme = object({
  drainage_radius: number(),
  inner_region_radius: number(),
  inner_region_permeability: number(),
});

const transientModelUniformFluxScheme = object({
  drainage_radius: number(),
  fracture_half_length: number(),
});

const transientModelFiniteConductivityScheme = object({
  drainage_radius: number(),
  fracture_half_length: number(),
  dimensionless_fracture_conductivity: number(),
});

const transientModelMultiFractureHorizontalRadial = object({
  drainage_radius: number(),
  horizontal_length: number(),
  number_fractures: number(),
  fracture_half_length: number(),
});

const sectionTransientModeltypeModelScheme = object({
  selected_transient_model: nativeEnum(TransientModel),
  transient_model_radial: transientModelRadialScheme,
  transient_model_linear: transientModelLinearScheme,
  transient_model_composite_radial: transientModelCompositeRadialScheme,
  transient_model_uniform_flux_fracture: transientModelUniformFluxScheme,
  transient_model_finite_conductivity_fracture: transientModelFiniteConductivityScheme,
  transient_model_multi_fracture_horizontal_radial: transientModelMultiFractureHorizontalRadial,
});

const sectionDualPorosityScheme = object({
  selected_dual_porosity_model: nativeEnum(DualPorosityModel),
  storage_capacity_ratio: number(),
  interporosity_flow_parameter: number(),
});

const transientFlowScheme = object({
  selected_boundary_condition: nativeEnum(TransientModelBoundaryConditions),
  skin: number(),
  permeability: number(),
});

export const autoRtaAnalysisScheme = object({
  smart_fit: boolean(),
})
  .merge(sectionTransientModeltypeModelScheme)
  .merge(transientFlowScheme)
  .merge(sectionDualPorosityScheme);

export type AutoRtaAnalysis = z.infer<typeof autoRtaAnalysisScheme>;

// response
const dimensionlessTimeArrayModelScheme = object({
  wellbore_radius_normalized_time: number().nullable().array(),
  area_normalized_time: number().nullable().array(),
  decline_time: number().nullable().array(),
});
export type DimensionlessTimeArrayModel = z.infer<typeof dimensionlessTimeArrayModelScheme>;

const dimensionlessPressureArraysModelScheme = object({
  normalized_pressure: number().nullable().array(),
  bourdet_derivative: number().nullable().array(),
  normalized_pressure_integral: number().nullable().array(),
  normalized_pressure_integral_derivative: number().nullable().array(),
  beta_derivative: number().nullable().array(),
  chow_pressure_group: number().nullable().array(),
});
export type DimensionlessPressureArraysModel = z.infer<typeof dimensionlessPressureArraysModelScheme>;

const dimensionlessRateArrayScheme = object({
  normalized_rate: number().nullable().array(),
  decline_rate: number().nullable().array(),
  decline_rate_integral: number().nullable().array(),
  decline_rate_integral_derivative: number().nullable().array(),
});

export type DimensionlessRateArrays = z.infer<typeof dimensionlessRateArrayScheme>;

const transientAnalysisCurvesScheme = object({
  dimensionless_time_arrays: dimensionlessTimeArrayModelScheme,
  dimensionless_pressure_arrays: dimensionlessPressureArraysModelScheme,
  dimensionless_rate_arrays: dimensionlessRateArrayScheme,
});

const analysisCalculationScheme = object({
  log_transformed_curves_model: transientAnalysisCurvesScheme,
  curves_model: transientAnalysisCurvesScheme,
  log_transformed_data_points: transientAnalysisCurvesScheme,
  data_points: transientAnalysisCurvesScheme,
  summary_card: SummaryCardScheme.array(),
});

export type AnalysisCalculation = z.infer<typeof analysisCalculationScheme>;

export const analysisAutoRtaResScheme = object({
  analysis_inputs: autoRtaAnalysisScheme,
  analysis_result: analysisCalculationScheme,
});

export type AnalysisAutoRtaRes = z.infer<typeof analysisAutoRtaResScheme>;
