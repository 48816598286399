import { boolean, number, object, string, z } from "zod";
import { autoForecastEventScheme, SummaryCardScheme } from "../Generic";

export const forecastFmbStateScheme = object({
  economic_cutoff: number(),
  smart_fill: boolean(),
  forecast_events: autoForecastEventScheme,
});

export type ForecastFmbState = z.infer<typeof forecastFmbStateScheme>;

// response
const forecastFmbChartDataScheme = object({
  dates: string().date().array(),
  flowing_pressure: number().array(),
  dry_gas_rate: number().array(),
  dry_cumulative_gas: number().array(),
  recombined_gas_rate: number().array(),
  recombined_cumulative_gas: number().array(),
  reservoir_pressure: number().array(),
  pseudo_reservoir_pressure: number().array(),
  pseudo_flowing_pressure: number().array(),
  gas_formation_volume_factor: number().array(),
  gas_viscosity: number().array(),
  gas_compressibility: number().array(),
  z: number().array(),
});

const forecastFmbChartScheme = object({
  data: forecastFmbChartDataScheme,
  summary_card: SummaryCardScheme.array(),
});

export const forecastFmbFieldDataScheme = object({
  dates: string().date().array(),
  flowing_pressure: number().array(),
  dry_gas_rate: number().array(),
  dry_cumulative_gas: number().array(),
  recombined_gas_rate: number().array(),
  recombined_cumulative_gas: number().array(),
});

export const forecastFmbResultScheme = object({
  low: forecastFmbChartScheme,
  mid: forecastFmbChartScheme,
  high: forecastFmbChartScheme,
});

export const forecastFmbResultApi = object({
  field_data: forecastFmbFieldDataScheme,
}).merge(forecastFmbResultScheme);

type ForecastFmbResult = z.infer<typeof forecastFmbResultScheme>;

export type ForecastFmbFieldDataScheme = z.infer<typeof forecastFmbFieldDataScheme>;

export const forecastFmbResultResponseScheme = object({
  forecast_inputs: forecastFmbStateScheme,
  forecast_result: forecastFmbResultApi,
});

export type ForecastCalculationState = {
  field_data: ForecastFmbFieldDataScheme;
  forecast_result: ForecastFmbResult;
};

export type ForecastFmbResultResponse = z.infer<typeof forecastFmbResultResponseScheme>;
