import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { CellChange, Row } from "@silevis/reactgrid";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import CustomCard from "@/components/Card";
import SummaryTable from "@/components/SummaryTable";
import CustomTable from "@/components/CustomTable";

import dictionary from "@/constants/dictionary";
import useThemeStyling from "@/utils/useThemeStyling";

import { AnalysisFmbState } from "@/models/autoFmb";

import { summaryFmbTableHeader } from "../../constants";
import { caseTableColumn, manualPointColumn } from "../../hooks/useAutoFmbAnalysis";
import { SummaryFmb } from "../../context/AutoFmbContext";
import { useState } from "react";

type AnalysisParameterProps = {
  parameters?: AnalysisFmbState;
  onChangeParameter: (key: string, value: any) => void;
  isLoading: boolean;
  onClickCalculate: () => void;
  summaryTableData: SummaryFmb;
  parameterTableRow: Row<any>[];
  onTableCellChange: (changes: CellChange[]) => void;

  pzPointTableRow: Row<any>[];
  onPointTableCellChange: (changes: CellChange[]) => void;
};

const AnalysisParameter = ({
  parameters,
  isLoading,
  onChangeParameter,
  onClickCalculate,
  summaryTableData,
  parameterTableRow,
  onTableCellChange,
  pzPointTableRow,
  onPointTableCellChange,
}: AnalysisParameterProps) => {
  const { palette } = useThemeStyling();

  const [isExpand, setIsExpand] = useState(false);

  return (
    <CustomCard
      style={{
        height: "100%",
      }}
    >
      <h3 style={{ color: palette.primary.main, margin: 0 }}>{dictionary.fmb.fmbParameter}</h3>

      <FormControlLabel
        control={
          parameters ? (
            <Checkbox
              onChange={(e) => {
                onChangeParameter("auto_update_smart_fit", e.target.checked);
              }}
              checked={parameters?.auto_update_smart_fit}
              size="small"
              data-testid="auto-update-smart-fit-checkbox"
            />
          ) : (
            <></>
          )
        }
        label={dictionary.fmb.autoUpdateSmartFit}
      />

      <CustomTable
        columns={caseTableColumn}
        rows={parameterTableRow}
        enableRangeSelection
        enableColumnSelection
        stickyTopRows={2}
        onCellsChanged={onTableCellChange}
        isLoading={false}
      />

      <h3
        onKeyDown={() => setIsExpand(!isExpand)}
        onClick={() => setIsExpand(!isExpand)}
        style={{ color: palette.primary.main, margin: 0, display: "flex", justifyContent: "space-between", marginTop: 20, cursor: "pointer" }}
      >
        {dictionary.fmb.pzManualPoint} {isExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </h3>

      {isExpand && (
        <CustomTable
          columns={manualPointColumn}
          rows={pzPointTableRow}
          enableRangeSelection
          enableColumnSelection
          stickyTopRows={2}
          onCellsChanged={onPointTableCellChange}
          isLoading={false}
          style={{
            maxHeight: 150,
          }}
          dataTestId="manual-point-table"
        />
      )}

      <Button
        style={{
          color: "white",
          width: "100%",
          fontWeight: "bold",
          marginBottom: 15,
          marginTop: 20,
        }}
        disabled={isLoading}
        variant="contained"
        onClick={onClickCalculate}
      >
        {dictionary.genericButton.applyUpdates}
      </Button>
      <h3 style={{ color: palette.primary.main, margin: 0 }}>{dictionary.fmb.summary}</h3>
      <SummaryTable rows={summaryTableData} headers={summaryFmbTableHeader} />
    </CustomCard>
  );
};

export default AnalysisParameter;
