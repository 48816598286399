import { boolean, number, object, z } from "zod";
import { summaryCardTableScheme } from "../InputGeneric";
import { handlebarScheme } from "../Generic";
import { fmbInputStateScheme } from "./input";

const analysisFmbParameterScheme = object({
  area: number(),
  permeability: number(),
});

export type AnalysisFmbParameter = z.infer<typeof analysisFmbParameterScheme>;

const pzPoints = object({
  gas_cumulative_production: number(),
  static_pressure: number(),
});

export type PzPoint = z.infer<typeof pzPoints>;

export const analysisFmbStateScheme = object({
  auto_update_smart_fit: boolean(),
  parameters: object({
    low: analysisFmbParameterScheme,
    mid: analysisFmbParameterScheme,
    high: analysisFmbParameterScheme,
  }),
  add_p_on_z_points: pzPoints.array(),
});

export type AnalysisFmbState = z.infer<typeof analysisFmbStateScheme>;

// response
const chartLineScheme = object({
  x1: number(),
  x2: number(),
  y1: number(),
  y2: number(),
});

const fmbChartScheme = object({
  normalized_rates: number().array(),
  normalized_cumulative_production: number().array(),
  handle_bar: handlebarScheme,
});

export type FmbChartAnalysis = z.infer<typeof fmbChartScheme>;

const pzChartScheme = object({
  flowing_p_star_on_z_double_star: number().array(),
  cumulative_production: number().array(),
  handle_bar: handlebarScheme,
});

export type FmbPzChartAnalysis = z.infer<typeof pzChartScheme>;

const productivityIndexChartScheme = object({
  productivity_index: number().array(),
  cumulative_production: number().array(),
  handle_bar: handlebarScheme,
});

export type FmbPiChartAnalysis = z.infer<typeof productivityIndexChartScheme>;

const analysisFmbDataTableScheme = object({
  gas_rate: number().array(),
  gas_cumulative_production: number().array(),
  flowing_pressure: number().array(),
  reservoir_pressure: number().array(),
  pseudo_flowing_pressure: number().array(),
  pseudo_reservoir_pressure: number().array(),
  normalized_rate: number().array(),
  normalized_cumulative_production: number().array(),
  z: number().array(),
  z_star: number().array(),
  reservoir_pressure_on_z_star: number().array(),
  reservoir_pressure_star: number().array(),
  pseudo_reservoir_pressure_star: number().array(),
  z_double_star: number().array(),
  reservoir_pressure_star_on_z_double_star: number().array(),
  productivity_index: number().array(),
});

export type AnalysisFmbDataTable = z.infer<typeof analysisFmbDataTableScheme>;

function CaseScheme<T extends z.ZodTypeAny>(itemSchema: T) {
  return object({
    low: itemSchema,
    mid: itemSchema,
    high: itemSchema,
  });
}

export type CaseResult<T> = {
  low: T;
  mid: T;
  high: T;
};

const manualPzDataTableScheme = object({
  cumulative_production: number().array(),
  static_pressure: number().array(),
  z: number().array(),
  z_star: number().array(),
  p_on_z_star: number().array(),
});

export type ManualPzDataTable = z.infer<typeof manualPzDataTableScheme>;

const analysisFmbChartResultScheme = object({
  fmb_chart: CaseScheme(fmbChartScheme),
  p_on_z_chart: CaseScheme(pzChartScheme).merge(
    object({
      manual_p_on_z: object({
        p_on_z_chart_manually_add: object({
          x: number(),
          y: number(),
        }).array(),
        p_on_z_chart_manual_line: chartLineScheme,
      }).nullable(),
    })
  ),
  productivity_index_chart: CaseScheme(productivityIndexChartScheme),
  data_tables: CaseScheme(analysisFmbDataTableScheme).merge(object({ p_on_z_manual_table: manualPzDataTableScheme.nullable() })),
  // this will be the same across all item (low, mid high) all parameter, unit and length
  // the only diff is the value
  summary_cards: CaseScheme(summaryCardTableScheme.array()),
});

export type AnalysisFmbChartResult = z.infer<typeof analysisFmbChartResultScheme>;

export const analysisFmbResultScheme = object({
  analysis_input: analysisFmbStateScheme,
  analysis_results: analysisFmbChartResultScheme,
});

export type AnalysisFmbResult = z.infer<typeof analysisFmbResultScheme>;

export const analysisFmbChartResponseScheme = object({
  analysis_input: analysisFmbStateScheme,
  analysis_results: analysisFmbChartResultScheme,
  input_page_inputs: fmbInputStateScheme,
});

export type AnalysisFmbChartResponse = z.infer<typeof analysisFmbChartResponseScheme>;
