import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import dictionary from "@/constants/dictionary";

import { camelToSnakeCase, formatToEngineeringNotation, mapEnumToDropdownReactGrid } from "@/utils/general";
import { TahkCsgInputState, TahkCsgSelectedGeometryEnum, TahkCsgStateResponse } from "@/models/tahk";
import {
  reservoirInputRowsHeader,
  tableHeaderStyle,
  tableHeaderNotationStyle,
  tableCellStyle,
  reservoirInputRowsHeaderWidth,
} from "../../constants/grid";
import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";
import helpLinkUrl from "@/constants/helpLinkUrl";
import { FormationCompressibilityCorrelationEnum } from "@/models/InputGeneric";
import { mergedHeaderLeft, mergedHeaderRight } from "@/components/CustomTable";

export type UseReservoirInputProps = {
  inputs?: TahkCsgInputState;
  loadingState: boolean;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
};

const useReservoirInput = ({ setTahkCsgState, inputs, loadingState }: UseReservoirInputProps) => {
  // which row is the dropdown opened
  // will use row id + 1, to differentiate if there's no dropdown open
  const [isDropdownOpened, setIsDropdownOpened] = useState<number>(0);

  const filteredHeaderKeys = useMemo(() => {
    if (!inputs) return [];
    return Object.keys(reservoirInputRowsHeader).filter((key) => {
      if (
        (inputs.selected_geometry === TahkCsgSelectedGeometryEnum.SpecifyDimension && key === "reservoirArea") ||
        (inputs.selected_geometry === TahkCsgSelectedGeometryEnum.SpecifyArea && (key === "reservoirWidth" || key === "reservoirLength"))
      ) {
        return false;
      }

      return true;
    });
  }, [inputs]);

  const onChangeInput = useCallback(
    (value: any, key: string) => {
      setTahkCsgState((prev) => {
        if (!prev) return prev;
        if (key === "number_of_layers") {
          if (value < 1 || value === prev.inputs.layers.length) return prev;
          if (value < prev.inputs.layers.length) {
            prev.inputs.layers.splice(value, prev.inputs.layers.length + 1);
          }
        }
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            [key]: value,
          },
        };
      });
    },
    [setTahkCsgState]
  );

  const reservoirInputColumns = filteredHeaderKeys.map((columnId) => {
    return { columnId: camelToSnakeCase(columnId), width: reservoirInputRowsHeaderWidth[columnId] };
  });

  const getHeaderStyle = (header: string) => {
    switch (header) {
      case "selectedFormationCompressibilityCorrelation":
        return mergedHeaderLeft;

      case "formationCompressibility":
        return mergedHeaderRight;

      default:
        return tableHeaderStyle;
    }
  };

  const reservoirInputRows: Row<any>[] = useMemo(() => {
    if (!inputs) return [];
    const input = _.cloneDeep(inputs);
    const headerKeys = Object.keys(reservoirInputRowsHeader);
    return [
      {
        rowId: "header",
        cells: filteredHeaderKeys.reduce((res: any, header) => {
          res.push({
            type: "custom",
            text: reservoirInputRowsHeader[header],
            style: getHeaderStyle(header),
            collSpan: header === "selectedFormationCompressibilityCorrelation" ? 2 : 1,
            link: helpLinkUrl.input?.[header] ?? undefined,
          });

          return res;
        }, []),
        height: 50,
      },
      {
        rowId: "notation",
        cells: filteredHeaderKeys.reduce((res: any, header) => {
          res.push({
            type: "header",
            text: dictionary.tableUnits[header],
            style: tableHeaderNotationStyle,
          });

          return res;
        }, []),
      },
      ...input.layers.map((layer, rowIndex) => {
        const reservoirParam: { [key: string]: string | number } = layer.reservoir_parameters as { [key: string]: string | number };
        return {
          rowId: rowIndex,
          height: 30,
          cells: [
            {
              type: "number",
              value: rowIndex + 1,
              nonEditable: true,
              style: tableCellStyle,
            },
            ...filteredHeaderKeys.slice(1, headerKeys.length).reduce((res: any, header, index) => {
              const val = reservoirParam[camelToSnakeCase(header)];

              if (index === 5) {
                res.push({
                  type: "dropdown",
                  selectedValue: val ?? undefined,
                  values: mapEnumToDropdownReactGrid(FormationCompressibilityCorrelationEnum),
                  style: tableCellStyle,
                  isOpen: isDropdownOpened === rowIndex + 1,
                  nonEditable: loadingState,
                });
              } else {
                res.push({
                  type: "text",
                  text: formatToEngineeringNotation(val),
                  style: tableCellStyle,
                  nonEditable: loadingState,
                  validator: (text: string) => {
                    return !isNaN(Number(text));
                  },
                });
              }

              return res;
            }, []),
          ],
        };
      }),
    ];
  }, [inputs, filteredHeaderKeys, isDropdownOpened, loadingState]);

  const onChangeReservoirParameter = useCallback(
    (changes: CellChange[]) => {
      if (!inputs) return;
      const updatedRows = [...inputs.layers];

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell, previousCell, type } = change as CellChange<any>;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        rowId = rowId as number;
        columnId = columnId as number;

        const reservoirParams = updatedRows[rowId].reservoir_parameters as { [key: string]: any };

        if (type === "dropdown") {
          const newDropdown = dropDownNewCell.isOpen ? rowId + 1 : 0;
          if (prevCell.isOpen !== dropDownNewCell.isOpen && newDropdown !== isDropdownOpened) {
            setIsDropdownOpened(dropDownNewCell.isOpen ? rowId + 1 : 0);
          }
          if (dropDownNewCell.isOpen) return;
          if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
            reservoirParams[columnId] = dropDownNewCell.selectedValue;
          }
        } else {
          reservoirParams[columnId] = Number(newCell.value ?? newCell.text ?? 0);
        }
      }

      setTahkCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            layers: updatedRows,
          },
        };
      });
    },
    [inputs, setTahkCsgState, isDropdownOpened]
  );

  const errorInputValidation = useMemo(() => {
    return (
      inputs?.layers.reduce((res: string[], curr) => {
        return Array.from(new Set(res));
      }, []) ?? []
    );
  }, [inputs?.layers]);

  return {
    onChangeInput,
    reservoirInputColumns,
    reservoirInputRows,
    onChangeReservoirParameter,
    errorInputValidation,
  };
};

export default useReservoirInput;
