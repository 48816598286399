import React from "react";
import { shallow } from "zustand/shallow";

import { useAppStore } from "@/features/app";
import ModulePage from "@/modules/ModulePage";

import TahkCsgProvider, { useTahkCsgState } from "./context/TahkCsgContext";
import dictionary from "@/constants/dictionary";

// components
import TahkCsgInput from "./components/Input";
import TahkCsgForecast from "./components/Forecast";
import TahkCsgAnalysis from "./components/Analysis";
import DataviewPage from "@/features/modules/dataview";
import useExpandParameter from "@/utils/useExpandParameter";

const ModuleTahkCsg = () => {
  const { tabIndex, onChangeTab, dataSets, isLoading, apiError } = useTahkCsgState();

  return (
    <ModulePage
      title={dictionary.tahk.title}
      onTabChange={onChangeTab}
      tabIndex={tabIndex}
      tabs={
        dataSets && [
          {
            headerText: "Data View",
            itemIcon: "ScatterChart",
            disabled: isLoading || !!apiError,
            content: <DataviewPage />,
            canSaveAsImg: true,
          },
          {
            headerText: "Input",
            itemIcon: "InputField",
            disabled: isLoading || !!apiError,
            content: <TahkCsgInput />,
            canSaveAsImg: true,
          },
          {
            headerText: "Analysis",
            itemIcon: "LineChart",
            disabled: isLoading || !!apiError,
            content: <TahkCsgAnalysis />,
            canSaveAsImg: true,
          },
          {
            headerText: "Forecast",
            itemIcon: "LineChart",
            disabled: isLoading || !!apiError,
            content: <TahkCsgForecast />,
            canSaveAsImg: true,
          },
        ]
      }
    />
  );
};

const ModuleTahkCsgWrapper = () => {
  const {
    selectedDataSets,
    isLoading,
    group,
    project,
    setLoadingBlockerText,
    setApiError,
    setCsvData,
    setIsLoading,
    setLoadingBlocker,
    setPollStatus,
    setProgress,
    apiError,
  } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      group: state.group,
      project: state.project,
      setApiError: state.setApiError,
      setCsvData: state.setCsvData,
      setIsLoading: state.setIsLoading,
      setPollStatus: state.setPollStatus,
      setProgress: state.setProgress,
      apiError: state.apiError,
      setLoadingBlocker: state.setLoadingBlocker,
      setLoadingBlockerText: state.setLoadingBlockerText,
    }),
    shallow
  );

  const { expandParameter } = useExpandParameter();

  return (
    <TahkCsgProvider
      setCsvData={setCsvData}
      setApiError={setApiError}
      group={group}
      project={project}
      selectedDataSets={selectedDataSets}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      setPollStatus={setPollStatus}
      setProgress={setProgress}
      apiError={apiError}
      setLoadingBlocker={setLoadingBlocker}
      setLoadingBlockerText={setLoadingBlockerText}
      expandParameter={expandParameter}
    >
      <ModuleTahkCsg />
    </TahkCsgProvider>
  );
};

export default ModuleTahkCsgWrapper;
