import { useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useRef } from "react";
import ScatterPlotOutlinedIcon from "@mui/icons-material/ScatterPlotOutlined";
import SsidChartOutlinedIcon from "@mui/icons-material/SsidChartOutlined";
import KeyboardAltOutlinedIcon from "@mui/icons-material/KeyboardAltOutlined";

import { shallow } from "zustand/shallow";

import Page from "../Page";
import { DataSet, isDataSet, isDataSets } from "../model";
import { useAppStore } from "@/features/app";

import "./ModulePage.css";
import Tabs from "@/components/Tabs";

interface ModulePageTab {
  headerText: string;
  itemIcon: "ScatterChart" | "LineChart" | "InputField";
  disabled: boolean;
  disableHideSidebar?: boolean;
  content: JSX.Element;
  canSaveAsImg?: boolean;
}

interface ModulePageProps {
  title: string;
  tabs?: ModulePageTab[];
  tabIndex?: number;
  onTabChange?: (index: number) => void;
}

function ModulePage({ title, tabs = [], tabIndex, onTabChange }: Readonly<ModulePageProps>) {
  const navigate = useNavigate();

  const [selectedTabKey, setSelectedTabKey] = React.useState(0);

  const key = window.location.search;
  const queryTabIndex = new URLSearchParams(key).get("tab");

  const { currentModule, selectedDataSets, setCanSaveAsImg, group, project } = useAppStore(
    (state) => ({
      currentModule: state.currentModule,
      selectedDataSets: state.selectedDataSets,
      group: state.group,
      project: state.project,
      setCanSaveAsImg: state.setCanSaveAsImg,
    }),
    shallow
  );

  const lastModule = useRef("");

  const haveUpdateLastModule = useRef(false);

  // check latest module to set active tab key
  // this is to retain which tab user prev access
  useEffect(() => {
    if (currentModule) {
      if (lastModule.current !== currentModule) {
        lastModule.current = currentModule;
        haveUpdateLastModule.current = false;
      }
      if (key && selectedTabKey !== Number(queryTabIndex) && !haveUpdateLastModule.current) {
        // prevent infinite loop
        haveUpdateLastModule.current = true;
        onTabChange?.(Number(queryTabIndex));
        setSelectedTabKey(Number(queryTabIndex));
      } else {
        haveUpdateLastModule.current = false;
      }
    }
  }, [currentModule, key, onTabChange, queryTabIndex, selectedTabKey]);

  useEffect(() => {
    setCanSaveAsImg(!!tabs[selectedTabKey]?.canSaveAsImg);
  }, [selectedTabKey, tabIndex, tabs, setCanSaveAsImg]);

  useEffect(() => {
    if (typeof tabIndex !== "number" || Number(tabIndex) === Number(selectedTabKey) || queryTabIndex) return;
    setSelectedTabKey(tabIndex);
  }, [selectedTabKey, tabIndex, queryTabIndex]);

  const handleLinkClick = (keyNum: number) => {
    setSelectedTabKey(keyNum);
    if (window.history.pushState) {
      const url = new URL(window.location.href);
      url.searchParams.set("tab", String(keyNum));
      window.history.pushState(null, "", url.toString());
    }
    onTabChange?.(keyNum);
  };

  const moduleNotSupported = useMemo(() => {
    return (
      currentModule &&
      (selectedDataSets === undefined ||
        (isDataSet(selectedDataSets) && selectedDataSets.modules_available.indexOf(currentModule) < 0) ||
        (isDataSets(selectedDataSets) && selectedDataSets.some((ds) => ds.modules_available.indexOf(currentModule) < 0)))
    );
  }, [currentModule, selectedDataSets]);

  useEffect(() => {
    // Navigate back to modules if selected data sets do not support current module
    if (moduleNotSupported && !window.location.href.includes("V2")) {
      navigate("..");
    }
  }, [navigate, moduleNotSupported]);

  const groupOrDataSetName = (selectedDataSets as DataSet[]) instanceof Array ? group?.name : selectedDataSets && (selectedDataSets as DataSet).name;

  const getIcon = (option: "ScatterChart" | "LineChart" | "InputField") => {
    switch (option) {
      case "ScatterChart":
        return <ScatterPlotOutlinedIcon fontSize="small" />;
      case "LineChart":
        return <SsidChartOutlinedIcon fontSize="small" />;
      default:
        return <KeyboardAltOutlinedIcon fontSize="small" />;
    }
  };

  const tabList = useMemo(() => {
    return tabs.map((item, i) => {
      return {
        label: (
          <div
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            {getIcon(item.itemIcon)}
            <span style={{ marginLeft: 3 }}>{item.headerText}</span>
          </div>
        ),
        key: `${item.headerText}-${i.toString()}`,
        content: (
          <div
            style={{
              height: "100%",
              flex: "1 1",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            {item.content}
          </div>
        ),
        disabled: item.disabled,
      };
    });
  }, [tabs]);

  const headerText = useMemo(() => {
    const ending = ` - ${groupOrDataSetName}`;

    return `${title} ${project?.name} ${groupOrDataSetName ? ending : ""} `;
  }, [groupOrDataSetName, project?.name, title]);

  if (moduleNotSupported) return <></>;

  return (
    <Page title={title}>
      <div
        style={{
          paddingLeft: "1em",
          paddingRight: "1em",
          height: "98%",
        }}
      >
        <Tabs displayCollapse customActiveTab={selectedTabKey} alwaysRender onClickItem={handleLinkClick} tabList={tabList} headerText={headerText} />
      </div>
    </Page>
  );
}

export default ModulePage;
