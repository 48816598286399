import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Auth } from "aws-amplify";
import { shallow } from "zustand/shallow";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";
import { useQuery } from "@tanstack/react-query";

import { useAppStore } from "@/features/app";

import { openHelpLink } from "@/features/help-link";
import { TenantConfigs } from "@/tenants";
import routeUrlMap from "@/constants/routeUrl";
import { downloadCsv } from "@/utils/csvProcessing";

// hooks
import useThemeStyling from "@/utils/useThemeStyling";
import { DataSet } from "@/model";
import { downloadImg } from "@/utils/imageProcessing";
import { CsvData } from "@/features/app/app.types";
import { getIdFromActiveKey } from "../TreeView/utils";
import dictionary from "@/constants/dictionary";
import { logoutApi, revokeLicenseApi } from "@/constants/apiUrl";
import { fetchApi } from "@/utils/apiFetcher";
import { getPermissionNav } from "@/models/settings";
import { capitalizeFirstLetter } from "@/utils/general";

import useModuleList from "../Modules/hooks/useModuleList";
import { useTreeViewState } from "../TreeView/hooks/TreeViewContextV2";
import { DialogEnum } from "../TreeView/types";
import { MODULE_SPECIFIC_LINKS, OPERATIONAL_HELP_LINKS } from "@/constants/helpLinkUrl";
import { clearSessionFromLS } from "@/utils/session";

// data structure of menu dropdown
// if only have 1 section dont display section title
export type MenuSection = {
  section?: string;
  items: MenuItem[];
  hideSectionTitle?: boolean;
};

type MenuItem = {
  id?: string;
  label: string;
  onClickMenu: () => void;
  disabled?: boolean;
  csvData?: CsvData[];
};
const handleDownloadImg = async (fileName: string) => {
  const element = document.getElementById("moduleBaseSection");
  if (element) {
    htmlToImage.toJpeg(element, { quality: 0.95, skipAutoScale: true, backgroundColor: "white" }).then(function (url) {
      downloadImg(url, fileName);
    });
  }
};

const useNavMenu = () => {
  const {
    user,
    tenant,
    getBuildNumberText,
    setTenant,
    currentModule,
    setCurrentModule,
    canSaveAsImg,
    isLoading,
    selectedDataSets,
    group,
    csvData,
    project,
    selectedKey,
    setGroup,
    setProject,
    setSelectedDataSets,
    setSelectedKey,
    setCsvData,
    setUser,
    setIsLoading,
  } = useAppStore(
    (state) => ({
      setIsLoading: state.setIsLoading,
      getBuildNumberText: state.getBuildNumberText,
      user: state.user,
      tenant: state.tenant,
      setTenant: state.setTenant,
      currentModule: state.currentModule,
      setCurrentModule: state.setCurrentModule,
      canSaveAsImg: state.canSaveAsImg,
      isLoading: state.isLoading,
      selectedDataSets: state.selectedDataSets,
      group: state.group,
      csvData: state.csvData,
      project: state.project,
      selectedKey: state.selectedKey,
      setProject: state.setProject,
      setGroup: state.setGroup,
      setSelectedDataSets: state.setSelectedDataSets,
      setSelectedKey: state.setSelectedKey,
      setCsvData: state.setCsvData,
      setUser: state.setUser,
    }),
    shallow
  );

  const { modules } = useModuleList();
  const { setActiveDialog } = useTreeViewState();
  const { dataSet, groupIds } = getIdFromActiveKey(selectedKey ?? "");

  const [showLicenseDialog, setShowLicenseDialog] = useState(false);

  const { data: permissionData } = useQuery({
    queryKey: ["get-nav-permissions"],
    queryFn: async () => {
      return getPermissionNav();
    },
    select(data) {
      return data?.data ?? {};
    },
    refetchOnWindowFocus: false,
  });

  const noChart = !window.location.pathname.includes("/modules/");
  const isKoldunCsg = window.location.pathname.includes("/koldun/mcsim/csg");

  const { effects } = useThemeStyling();
  const navigate = useNavigate();

  const feSignout = useCallback(async () => {
    try {
      await Auth.signOut({ global: true });
      localStorage.clear();
      setTenant(undefined);
      setUser(undefined);
      clearSessionFromLS();
      window.location.href = "/";
    } catch (error) {
      console.log(error);
    }
  }, [setTenant, setUser]);

  const signOut = useCallback(async () => {
    try {
      await fetchApi({ path: logoutApi, type: "del" });
      await feSignout();
    } catch (e) {
      console.log(e);
    }
  }, [feSignout]);

  const onClickRevokeLicense = useCallback(async () => {
    try {
      setIsLoading(true);
      await fetchApi({ path: revokeLicenseApi, type: "del" });
      await feSignout();
    } catch (error) {
      console.log(error);
    } finally {
      setShowLicenseDialog(false);
      setIsLoading(false);
    }
  }, [feSignout, setIsLoading]);

  const resetProject = useCallback(() => {
    setGroup();
    setProject();
    setSelectedDataSets();
    setSelectedKey();
  }, [setGroup, setProject, setSelectedDataSets, setSelectedKey]);

  const handleScreenCapture = (captured: string) => {
    downloadImg(captured, "AFA-custom-capture");
    return null;
  };

  const fileMenuList: MenuSection[] = useMemo(() => {
    const saveChartImageUtil = {
      label: "JPEG",
      onClickMenu: () => {
        const imgTitle = `${currentModule} - ${
          (selectedDataSets as DataSet[]) instanceof Array ? group?.name : selectedDataSets && (selectedDataSets as DataSet).name
        } `;
        handleDownloadImg(imgTitle);
      },
      disabled: !canSaveAsImg || isLoading || noChart,
    };

    const downloadChartCsv = {
      id: "export-csv",
      label: "CSV",
      onClickMenu: () => {
        csvData?.forEach((data) => {
          downloadCsv(data.data, data.fileName);
        });
      },
      disabled: !isKoldunCsg || !csvData || noChart,

      csvData,
    };

    const screenCaptureEntireScreen = {
      id: "screen-capture-entire",
      label: "From Entire Screen...",
      onClickMenu: () => {
        const body = document.getElementById("inner-body");

        if (body) {
          const filename = `AFA-Screenshot-${new Date().toLocaleDateString()}`;
          const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
          const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

          html2canvas(body, {
            width: windowWidth,
            height: windowHeight,
          }).then((canvas: any) => {
            const url = canvas.toDataURL("image/jpeg", 0.5);
            downloadImg(url, filename);
          });
        }
      },
    };

    const saveTableAsCsv = {
      id: "export-csv",
      label: "CSV",
      onClickMenu: () => {
        csvData?.forEach((data) => {
          downloadCsv(data.data, data.fileName);
        });
      },
      disabled: !csvData || noChart,
      csvData,
    };

    const importWells = {
      label: dictionary.nav.wells,
      onClickMenu: () => {
        navigate(`/${routeUrlMap.importWells}`);
      },
    };

    const importProjectStructure = {
      label: dictionary.nav.projects,
      onClickMenu: () => {
        navigate(`/${routeUrlMap.importProjects}`);
      },
    };

    const fileMapping: { [key: string]: MenuItem } = {
      wells: importWells,
      projects: importProjectStructure,
    };

    const permissionFileListKey: string[] = Object.keys(permissionData?.file_menu ?? {}).sort(function (a, b) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });

    const defaultSection = [
      {
        section: dictionary.nav.import,
        hideSectionTitle: false,
        items: [importWells, importProjectStructure],
      },
    ];

    const permissionsList = permissionFileListKey.map((section) => {
      const menuListItems =
        permissionData?.file_menu?.[section].sort().map((item) => {
          if (item.action === "builtin") {
            return {
              ...fileMapping[item.id],
              label: item.label ?? item.id,
            };
          }
          return {
            label: item.label ?? item.id,
            onClickMenu: () => {
              navigate(`/import${item.api_url}`);
            },
          };
        }) ?? [];

      if (section === "import") {
        // add import project manually since it doesn't require any permission
        menuListItems.push(importProjectStructure);
      }

      return {
        section: dictionary.nav[section] ?? capitalizeFirstLetter(section),
        hideSectionTitle: false,
        items: menuListItems,
      };
    });

    return [
      ...(permissionsList.length > 0 ? permissionsList : defaultSection),
      {
        section: "Save Chart As",
        items: [saveChartImageUtil, downloadChartCsv],
      },
      {
        section: "Save Table As",
        items: [saveTableAsCsv],
      },
      {
        section: "Take Screenshot",
        items: [screenCaptureEntireScreen],
      },
    ];
  }, [canSaveAsImg, isLoading, noChart, isKoldunCsg, csvData, permissionData?.file_menu, currentModule, selectedDataSets, group?.name, navigate]);

  const modulesMenuList: MenuSection[] = useMemo(() => {
    if (modules.length === 0)
      return [
        {
          section: "Choose a well or group to get started.",
          items: [],
        },
      ];

    let mappedModuleList = _.flatten(modules).map((section) => {
      return {
        section: section.section,
        items: section.items.map((item) => {
          return {
            label: item.label,
            onClickMenu: () => {
              navigate(`/${routeUrlMap.modules}/${item.route}`);
              setCurrentModule(item.key);
              setCsvData();
            },
            disabled: item.disabled,
          };
        }),
      };
    });

    const gridList = [
      {
        section: "Grid",
        hideSectionTitle: true,
        items: [
          {
            label: "Grid",
            onClickMenu: () => {
              navigate(`/${routeUrlMap.modules}`);
              resetProject();
            },
          },
        ],
      },
      ...mappedModuleList,
    ];

    return gridList;
  }, [modules, navigate, resetProject, setCsvData, setCurrentModule]);

  const primaryMenuList: MenuSection[] = useMemo(() => {
    return [
      {
        section: "predico",
        hideSectionTitle: true,
        items: [
          {
            label: "About",
            onClickMenu: () => {
              resetProject();
              navigate(`/${routeUrlMap.settings}?tab=0`);
            },
          },
          {
            label: "Term of Service",
            onClickMenu: () => {
              resetProject();
              navigate(`/${routeUrlMap.settings}?tab=3`);
            },
          },
          {
            label: "Settings",
            onClickMenu: () => {
              resetProject();
              navigate(`/${routeUrlMap.settings}?tab=1`);
            },
          },
          {
            label: dictionary.license.returnLicense,
            onClickMenu: () => {
              setShowLicenseDialog(true);
            },
          },
          {
            label: "Quit AFA",
            onClickMenu: () => {
              resetProject();
              signOut();
            },
          },
        ],
      },
    ];
  }, [navigate, resetProject, signOut]);

  const projectMenuList: MenuSection[] = useMemo(() => {
    const isSelectedGroup = groupIds.length > 0;
    const selectedDataSetCount = dataSet.length;

    return [
      {
        section: "Project",
        items: [
          {
            label: "New",
            onClickMenu: () => {
              setActiveDialog(DialogEnum.NEW_PROJECT);
            },
          },
          {
            label: "Rename",
            disabled: !project,
            onClickMenu: () => {
              setActiveDialog(DialogEnum.EDIT_PROJECT);
            },
          },
          {
            label: "Delete",
            disabled: !project,
            onClickMenu: () => {
              setActiveDialog(DialogEnum.DELETE_PROJECT);
            },
          },
          {
            label: "Settings",
            disabled: !project,
            onClickMenu: () => {
              setActiveDialog(DialogEnum.PROJECT_SETTING);
            },
          },
          {
            label: "Transfer",
            disabled: !project,
            onClickMenu: () => {
              setActiveDialog(DialogEnum.TRANSFER_PROJECT);
            },
          },
        ],
      },
      {
        section: "Group",
        items: [
          {
            label: "New",
            onClickMenu: () => {
              setActiveDialog(DialogEnum.NEW_GROUP);
            },
            disabled: !project || (group?.data_set_ids && group.data_set_ids.length > 0),
          },
          {
            label: "Rename",
            onClickMenu: () => {
              setActiveDialog(DialogEnum.EDIT_GROUP);
            },
            disabled: !isSelectedGroup,
          },
          {
            label: "Delete",
            onClickMenu: () => {
              setActiveDialog(DialogEnum.DELETE_GROUP);
            },
            disabled: !isSelectedGroup,
          },
        ],
      },
      {
        section: "Well",
        items: [
          {
            label: selectedDataSetCount > 1 ? dictionary.nav.removeSelectedWells : dictionary.nav.removeCurrentWell,
            onClickMenu: () => {
              setActiveDialog(DialogEnum.REMOVE_DATASET);
            },
            disabled: selectedDataSetCount === 0,
          },
        ],
      },
    ];
  }, [dataSet.length, group?.data_set_ids, groupIds.length, project, setActiveDialog]);

  const buildNumberText = getBuildNumberText();

  const helpMenuList = useMemo(() => {
    return [
      {
        section: "Help",
        hideSectionTitle: true,
        items: [
          {
            label: "User Manual",
            onClickMenu: () => {
              if (currentModule && MODULE_SPECIFIC_LINKS.includes(currentModule)) {
                openHelpLink(OPERATIONAL_HELP_LINKS[currentModule]);
              } else {
                openHelpLink(OPERATIONAL_HELP_LINKS.index);
              }
            },
          },

          {
            label: "Contact Support",
            onClickMenu: () => {
              const mailtoLink = `mailto:support@predico.com.au?subject=Version ${buildNumberText}`;
              window.open(mailtoLink, "_blank")?.focus();
            },
          },
          {
            label: "Release Notes",
            onClickMenu: () => navigate(`/${routeUrlMap.settings}?tab=2`),
          },
        ],
      },
    ];
  }, [buildNumberText, currentModule, navigate]);

  return {
    effects,
    fileMenuList,
    modulesMenuList,
    primaryMenuList,
    projectMenuList,
    helpMenuList,
    buildNumberText,
    username: user?.username ?? user?.signInUserSession?.idToken?.payload["cognito:username"] ?? "",
    tenant: tenant && TenantConfigs[tenant] ? TenantConfigs[tenant]?.name : "Predico",
    handleScreenCapture,
    resetProject,
    onClickRevokeLicense,
    setShowLicenseDialog,
    showLicenseDialog,
    isLoading,
  };
};

export default useNavMenu;
