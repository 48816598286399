import { API } from "aws-amplify";
import { createWithEqualityFn } from "zustand/traditional";

import { DataSet, PollRequestMethod } from "@/model";
import { AppState } from "./app.types";
import { ApiError } from "@/models/APIGeneric";

import dictionary from "@/constants/dictionary";
import { APIName, calculateRollup, validateRollup } from "@/constants/apiUrl";
import { safeTimeStampToLS } from "@/utils/session";
import { TenantConfigs } from "@/tenants";

const VERSION_LIMIT = 45;
const TENANT_KEY = "afa_tenant";

const requests: Promise<any>[] = [];
const timeoutRefs = new Set<NodeJS.Timer>();

// this will be remove later only for safe guard since this poller is legacy
let totalResult = "";
let currentPage = 1;

const version = parseInt(process.env.REACT_APP_BUILD_NUMBER as string);

// !!!DISCLAIMER!!!
// Please don't use api fetcher from this context as we want to migrate it to separate functionality
// Look at utils/apifetcher for the fetch api function

export const useAppStore = createWithEqualityFn<AppState>((set, get) => {
  /**
   * Requests are tracked in requests array.
   * Calling getRequest or postRequest will track the request, wait for its result, and update the loading state.
   * Calling pollRequest will track a polling interval, wait for its result, and update the loading state.
   */
  const updateLoading = (apiError?: ApiError) => {
    // isLoading is true when we have current requests, otherwise false
    const isLoading = requests.length > 0;
    if (isLoading) {
      set((state) => ({ isLoading, apiError: apiError || state.apiError }));
    } else {
      set((state) => ({
        isLoading,
        isLoadingBlocker: isLoading,
        progress: null,
        pollStatus: undefined,
        apiError: apiError ?? state.apiError,
        loadingBlockerText: isLoading ? state.loadingBlockerText : "",
      }));
    }
  };

  const processRequest = async (request: Promise<any>) => {
    requests.push(request);
    set(() => ({ isLoading: true, apiError: undefined }));
    try {
      safeTimeStampToLS();

      const response = await request;

      return response?.data;
    } catch (err: any) {
      set({
        apiError: err.response
          ? {
              code: err.response.data?.code ?? err.response.status,
              detail: err.response.data?.detail,
              message: err.response.data.message,
              severity: err.response.data.severity,
            }
          : undefined,
      });
      throw err;
    } finally {
      // ensure we remove the request whether it succeeds or fails
      requests.splice(requests.indexOf(request), 1);
      updateLoading();
    }
  };

  const continuePollRequest = async <Response = any>({
    url,
    taskId,
    isBlocking = false,
    withTaskInfo,
    body,
  }: {
    url: string;
    taskId: string;
    isBlocking?: boolean;
    withTaskInfo?: boolean;
    body?: { [k: string]: any } | any;
  }) => {
    let intervalTime = 500;
    let haveRetryTimes = 0;
    try {
      safeTimeStampToLS();
      set(() => ({ isLoading: true, isLoadingBlocker: isBlocking, apiError: undefined }));

      // logic here is taken from below
      return (await new Promise((resolve, reject) => {
        const poll = async () => {
          try {
            const { data } = await API.get(APIName, url + "/task_poll", {
              queryStringParameters: { task_id: taskId, page: currentPage }, // add this so we can get the http status
              response: true,
            });

            if (data?.task_result) {
              const transformedResult = typeof data.task_result === "string" ? data.task_result : JSON.stringify(data.task_result);
              totalResult = totalResult + transformedResult;
              currentPage = data?.task_current_page + 1;
            }
            if (data.task_status === "SUCCESS") {
              resolve(
                withTaskInfo
                  ? {
                      ...data,
                      task_result: JSON.parse(totalResult),
                    }
                  : JSON.parse(totalResult)
              );
              return;
            } else if (data.task_status === "FAILURE") {
              let message = data?.task_info?.status;
              if (data.task_info.status === "Task failed") {
                message += `, ${dictionary.serverError}`;
              }
              reject(new Error(message));
              return;
            } else if (data.task_status === "PENDING" || data.task_status === "STARTED") {
              // When progress value is given (for long-term api)
              if (data.task_info?.progress) {
                const isRollup = url === calculateRollup || url === validateRollup;

                let loadingBlockerText = "";
                if (isRollup) {
                  const typedBody = body as { data_set_ids: string[] };
                  const wells = typedBody?.data_set_ids?.length * (Number(data.task_info?.progress) / 100);
                  loadingBlockerText = `${url === calculateRollup ? "Calculating" : "Validating"}: ${wells.toFixed()} of ${
                    typedBody?.data_set_ids?.length
                  } wells`;
                }
                set(() => ({ progress: data.task_info.progress, loadingBlockerText }));
              }

              if (data.task_info?.status) {
                set(() => ({ pollStatus: data.task_info.status }));
              }

              poll();
            }
          } catch (error: any) {
            if (haveRetryTimes < 4) {
              let nextIntervalTime = intervalTime * 2;
              if (nextIntervalTime > 10000) {
                // Max interval time is 10 seconds
                nextIntervalTime = 10000;
              }

              const timeoutId = setTimeout(() => {
                haveRetryTimes += 1;
                poll();
                timeoutRefs.delete(timeoutId);
              }, intervalTime);

              timeoutRefs.add(timeoutId);
              intervalTime = nextIntervalTime;
            } else {
              reject(new Error(error));
              return;
            }
          }
        };

        poll();
      })) as Response;
    } catch (err: any) {
      cancelPolling(
        err.response
          ? {
              code: err.response.data?.code ?? err.response.status,
              detail: err.response.data?.detail,
              message: err.response.data.message,
              severity: err.response.data.severity,
            }
          : (err.message && {
              code: err.code !== "ERR_NETWORK" ? err.code : 0,
              message: err.code !== "ERR_NETWORK" ? err.message : "",
              severity: "error",
            }) ?? {
              code: 0,
              message: "",
              severity: "error",
            }
      );
      throw new Error(dictionary.serverError);
    } finally {
      updateLoading();
    }
  };

  const pollRequest = async <Response = any, Request = any>(
    basePath: string,
    queryStringParameters?: { [k: string]: any },
    body?: { [k: string]: any } | Request,
    withTaskInfo: boolean = false,
    method: PollRequestMethod = "post",
    isBlocking: boolean = false
  ) => {
    safeTimeStampToLS();
    const isRollup = basePath === calculateRollup || basePath === validateRollup;

    let loadingBlockerText = "";
    if (isRollup) {
      const typedBody = body as { data_set_ids: string[] };
      loadingBlockerText = `${basePath === calculateRollup ? "Calculating" : "Validating"}: 0 of ${typedBody?.data_set_ids?.length} wells`;
    }

    set(() => ({
      isLoading: true,
      isLoadingBlocker: isBlocking,
      apiError: undefined,
      loadingBlockerText,
    }));

    try {
      const {
        data: { task_id },
      } = await API[method](APIName, basePath + "/task_start", {
        queryStringParameters,
        body,
        response: true,
      });

      // reset the result every time make a new poll request
      totalResult = "";
      currentPage = 1;
      return (await continuePollRequest({
        url: basePath,
        taskId: task_id,
        isBlocking,
        withTaskInfo,
        body,
      })) as Response;
    } catch (err: any) {
      cancelPolling(
        err.response
          ? {
              code: err.response.data?.code ?? err.response.status,
              detail: err.response.data?.detail,
              message: err.response.data.message,
              severity: err.response.data.severity,
            }
          : undefined
      );
      throw err;
    } finally {
      updateLoading();
    }
  };

  const cancelPolling = (apiError?: ApiError) => {
    timeoutRefs.forEach((timeoutId: NodeJS.Timer) => {
      clearTimeout(timeoutId);
    });

    timeoutRefs.clear();
    updateLoading(apiError);
  };

  return {
    buildNumber: undefined,
    setBuildNumber: (buildNumber) => set(() => ({ buildNumber })),
    getBuildNumberText: () => {
      let buildNumberText = null;
      const buildNumber = get().buildNumber;

      if (buildNumber) {
        buildNumberText = version > VERSION_LIMIT ? `${buildNumber}.${version}` : `${buildNumber}.00`;
      }

      return buildNumberText;
    },
    tenant: localStorage.getItem(TENANT_KEY) ?? undefined,
    isLoading: false,
    progress: null,
    currentModule: undefined,
    setTitle: (title) => set(() => ({ title })),

    setTenant: (oldTenant) => {
      let tenant: string | undefined = oldTenant;
      if (tenant && !TenantConfigs[tenant]) tenant = undefined;

      tenant ? localStorage.setItem(TENANT_KEY, tenant) : localStorage.removeItem(TENANT_KEY);
      set(() => ({
        tenant,
        projects: undefined,
        project: undefined,
        group: undefined,
        dataSets: undefined,
        selectedDataSets: undefined,
      }));
    },
    setUser: (user) => set(() => ({ user })),
    setProjects: (projects) => set(() => ({ projects })),
    setProject: (project) => set(() => ({ project })),
    setGroup: (group) => set(() => ({ group })),
    setDataSets: (dataSets) => set(() => ({ dataSets })),
    setSelectedDataSets: (dataSets) => {
      let selectedDataSets: DataSet | DataSet[] | undefined;

      if (dataSets) {
        selectedDataSets = Array.isArray(dataSets) ? [...dataSets] : { ...dataSets };
      }

      set(() => ({
        selectedDataSets,
      }));
    },
    postRequest: (path, queryStringParameters, body, responseType) =>
      processRequest(
        API.post(APIName, path, {
          queryStringParameters,
          body,
          responseType,
          response: true,
        })
      ),
    pollRequest: (basePath, queryStringParameters, body, withTaskInfo, method, isBlocking) =>
      pollRequest(basePath, queryStringParameters, body, withTaskInfo, method, isBlocking),
    setCurrentModule: (currentModule) => {
      set((state) => {
        if (state.currentModule !== currentModule) {
          return {
            currentModule,
            expandParameter: true,
          };
        }
        return { currentModule };
      });
    },
    isLoadingBlocker: false,
    setApiError: (apiError) => set(() => ({ apiError })),
    canSaveAsImg: false,
    setCanSaveAsImg: (canSaveAsImg) => set(() => ({ canSaveAsImg })),
    setCsvData: (csvData) => set(() => ({ csvData })),
    // user selection data set
    selectedKey: "",
    setSelectedKey: (selectedKey) => set(() => ({ selectedKey })),
    loadingBlockerText: "",
    setLoadingBlocker: (isLoadingBlocker) => set(() => ({ isLoadingBlocker })),
    setIsLoading: (isLoading) => set(() => ({ isLoading })),
    setProgress: (progress) => set(() => ({ progress })),
    pollStatus: "",
    setPollStatus: (stat) => set(() => ({ pollStatus: stat })),
    setLoadingBlockerText: (text) => set(() => ({ loadingBlockerText: text })),

    expandParameter: true,
    setExpandParameter: (expand) => set(() => ({ expandParameter: expand })),
  };
}, Object.is);
