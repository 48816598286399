import { CellChange, Row } from "@silevis/reactgrid";
import { useCallback, useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";

import { LangmuirTable, TahkCsgInputLayerCharts, TahkCsgInputState, TahkCsgLangmuirIsothermEnum, TahkCsgStateResponse } from "@/models/tahk";

import helpLinkUrl from "@/constants/helpLinkUrl";
import dictionary from "@/constants/dictionary";
import {
  additionalLangmuirIsothermOGIPHeader,
  additionalLangmuirIsothermVolumetricHeader,
  dataTableHeaderStyles,
  headerDataTableUnitStyles,
  langmuirHeaderWithLink,
  langmuirIsothermDataTable,
  langmuirIsothermOGIPHeader,
  langmuirIsothermVolumetricHeader,
  tableCellStyle,
  tableHeaderNotationStyle,
  tableHeaderStyle,
} from "../../../constants/grid";

import { camelToSnakeCase, formatToEngineeringNotation } from "@/utils/general";
import { formatReactGridCell } from "@/util";
import { CustomCell } from "@/components/CustomTable";

export type TahkCsgLangmuirIsothermProps = {
  inputs?: TahkCsgInputState;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  loadingState: boolean;
  layers: TahkCsgInputLayerCharts[];
};

const useTahkCsgLangmuirIsotherm = ({ layers, loadingState, setTahkCsgState, inputs }: TahkCsgLangmuirIsothermProps) => {
  const [selectedLayer, setSelectedLayer] = useState(0);
  const { palette } = useTheme();

  const selectedInput = useMemo(() => {
    return inputs?.layers[0]?.selected_langmuir_inputs;
  }, [inputs?.layers]);

  const header = selectedInput === TahkCsgLangmuirIsothermEnum.Ogip ? langmuirIsothermOGIPHeader : langmuirIsothermVolumetricHeader;
  const additionalHeader =
    selectedInput === TahkCsgLangmuirIsothermEnum.Ogip ? additionalLangmuirIsothermOGIPHeader : additionalLangmuirIsothermVolumetricHeader;

  const cumulativeHeader = useMemo(() => ({ ...header, ...additionalHeader }), [additionalHeader, header]);

  const onChangeDropdownInput = useCallback(
    (val: TahkCsgLangmuirIsothermEnum) => {
      setTahkCsgState((prev) => {
        if (!prev) return prev;

        // change every layers selected langmuir input;
        const newLayers = prev.inputs.layers.map((layer) => ({ ...layer, selected_langmuir_inputs: val }));
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            layers: newLayers,
          },
        };
      });
    },
    [setTahkCsgState]
  );

  const rows: Row<any>[] = useMemo(() => {
    if (!inputs || !layers) return [];
    const input = _.cloneDeep(inputs);
    const headerKeys = Object.keys(cumulativeHeader);

    return [
      {
        rowId: "header",
        cells: headerKeys.map((head) => {
          return {
            type: "custom",
            text: cumulativeHeader[head],
            style: tableHeaderStyle,
            link: helpLinkUrl.input?.[head] ?? null,
            sub: langmuirHeaderWithLink.indexOf(head) > -1 ? "L" : null,
          };
        }),
        height: 50,
      },
      {
        rowId: "notation",
        cells: headerKeys.map((header: string) => {
          return {
            type: "header",
            text: dictionary.tableUnits[header],
            style: tableHeaderNotationStyle,
          };
        }),
      },
      ...input.layers.map((layer, index) => {
        const langmuirIsotherm = layer.langmuir_isotherm as { [key: string]: any };

        return {
          rowId: index,
          height: 30,
          cells: [
            {
              type: "number",
              value: index + 1,
              nonEditable: true,
              style: tableCellStyle,
            },
            ...Object.keys(header)
              .slice(1, Object.keys(header).length)
              .map((header) => {
                const val = langmuirIsotherm[camelToSnakeCase(header)];

                return {
                  type: "text",
                  text: formatToEngineeringNotation(val),
                  style: tableCellStyle,
                  nonEditable: loadingState,
                };
              }),
            ...Object.keys(additionalHeader).map((header) => {
              const val = layers[index].langmuir_table[camelToSnakeCase(header) as keyof LangmuirTable];

              return {
                type: "text",
                text: formatToEngineeringNotation(val),
                style: {
                  ...tableCellStyle,
                  background: palette.grey[100],
                },
                nonEditable: true,
              };
            }),
          ],
        };
      }),
    ];
  }, [additionalHeader, cumulativeHeader, header, inputs, layers, loadingState, palette.grey]);

  const columns = useMemo(() => {
    return Object.keys(cumulativeHeader).map((columnId, index) => {
      return { columnId: camelToSnakeCase(columnId), width: index === 0 ? 50 : 120 };
    });
  }, [cumulativeHeader]);

  const onCellsChanged = useCallback(
    (changes: CellChange[]) => {
      if (!inputs) return;
      const updatedRows = [...inputs.layers];

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell } = change as CellChange<any>;

        rowId = rowId as number;
        columnId = columnId as string;

        const langmuirIsotherm = updatedRows[rowId].langmuir_isotherm as { [key: string]: any };

        langmuirIsotherm[columnId] = newCell.value ?? newCell.text ?? 0;
      }

      setTahkCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            layers: updatedRows,
          },
        };
      });
    },
    [inputs, setTahkCsgState]
  );

  const dataTableRows = useMemo<Row<any>[]>(() => {
    if (!layers) return [];

    const headerRow: Row<CustomCell> = {
      rowId: "header",
      cells: Object.values(langmuirIsothermDataTable).map(({ name, sub }) => ({
        type: "custom",
        text: name,
        style: dataTableHeaderStyles,
        sub,
      })),
      height: 40,
    };

    const unitsRow: Row = {
      rowId: "units",
      cells: Object.values(langmuirIsothermDataTable).map(({ unit }) => ({
        type: "header",
        text: unit,
        style: headerDataTableUnitStyles,
      })),
    };
    const safeTable: { [key: string]: number[] } = layers[selectedLayer].langmuir_charts;
    return [
      headerRow,
      unitsRow,
      ...safeTable.pressure.map((_, j) => ({
        rowId: j,
        cells: Object.keys(langmuirIsothermDataTable).map((key) => formatReactGridCell(formatToEngineeringNotation(safeTable[key][j]))),
      })),
    ];
  }, [layers, selectedLayer]);

  const layerOption = useMemo(() => {
    return Array.from(Array(layers?.length).keys()).map((_, index) => {
      return {
        key: index,
        text: `${dictionary.tahk.layer} ${index + 1}`,
      };
    });
  }, [layers?.length]);

  return {
    onChangeDropdownInput,
    rows,
    columns,
    onCellsChanged,
    dataTableRows,
    selectedLayer,
    setSelectedLayer,
    layerOption,
  };
};

export default useTahkCsgLangmuirIsotherm;
