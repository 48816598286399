import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import EChartsReact, { EChartsOption } from "echarts-for-react";
import { AxisBaseOption } from "echarts/types/src/coord/axisCommonTypes";
import { SeriesOption } from "echarts";
import * as ecStat from "echarts-stat";
import { DateTime } from "luxon";
import { shallow } from "zustand/shallow";
import "echarts/lib/component/tooltip";

import { formatNumber } from "../util";
import resetIcon from "./chart-icons/reset.svg";
import toggleIcon from "./chart-icons/toggle.svg";
import { useAppStore } from "@/features/app";
import _ from "lodash";
import useThemeStyling from "@/utils/useThemeStyling";

const valueFormatter = (value: number | string) => {
  if (typeof value === "number") return value.toFixed(2);
  const dateTime = DateTime.fromISO(value);
  if (dateTime.isValid) return dateTime.toFormat("D");
  return value;
};

const symbolSize = 12;

const axisOptions = {
  nameLocation: "middle",
  nameGap: 30,
  axisLabel: { show: true },
} as AxisBaseOption;

const seriesOptions = {
  animation: false,
  symbolSize: 6,
} as SeriesOption;

const options = {
  title: {
    left: "center",
    padding: 0,
    paddingTop: 15,
  },
  tooltip: {
    show: true,
    renderMode: "richText",
    trigger: "item",
    position: "bottom",
    formatter: function (params: any) {
      const x = params.data[0];
      const y = params.data[1];
      const z = params.data[2];
      let output = "";

      //add condition check for
      if (z === undefined) {
        output = "x = " + valueFormatter(x) + "\ny = " + valueFormatter(y);
      } else {
        output = z + ":\n x =" + valueFormatter(x) + "\n y = " + valueFormatter(y);
      }
      return output;
    },
  },
  legend: {
    bottom: 0,
  },
  // Enable data throttling
  large: true,
  // Specify the `largeThreshold` value
  largeThreshold: 20000,
  xAxis: [],
  yAxis: [],
  series: [],
} as EChartsOption;

export interface FossilyticsChartAxis {
  name: string;
  type: string;
  color: string;
  min?: number | string;
  max?: number | string;
  inverse?: boolean;
  show?: boolean;
  position?: string;
  offset?: number;
  nameLocation?: string;
  nameGap?: number;
  axis?: number;
  data?: any;
}

export interface FossilyticsChartSeries {
  id?: string;
  name: string;
  type: string;
  defaultDisabled?: boolean;
  color?: string | ((params: Object) => any);
  lineWidth?: number;
  lineType?: string;
  symbolSize?: number;
  hideSymbol?: boolean;
  xAxisIndex?: number;
  yAxisIndex?: number;
  z?: number;
  data: any[];
  markPointData?: any[];
}

export enum FossilyticsChartLineConstraint {
  AXIS,
  LAST_VALUE,
  POSITIVE,
  VALUE,
  FIXED_VALUE,
}
export interface FossilyticsChartToggle {
  showToggle?: boolean;
  title: string;
}

export interface FossilyticsChartCustomDataZoom {
  customDataZoom?: boolean;
  dataZoomPercentage: number;
}

const getConstraintValue = (constraintValue: [number | undefined, number | string | undefined], v: number) => {
  if (constraintValue[0] !== undefined && v <= constraintValue[0]) {
    return constraintValue[0];
  } else if (constraintValue[1] !== undefined && v >= Number(constraintValue[1])) {
    return Number(constraintValue[1]);
  } else {
    return v;
  }
};

// previously have max value of 0.01
const applyXLineConstraints = (v: number, i: number, l: FossilyticsChartLine): number | string => {
  let val = v;
  if (String(l.xMin) !== "undefined" && String(l.xMin) !== "null") val = Math.max(l.xMin ?? 0, v);

  switch (l.xConstraint) {
    case FossilyticsChartLineConstraint.AXIS:
      return i === 0 ? 0 : val;

    case FossilyticsChartLineConstraint.LAST_VALUE:
      return i === 1 && l.line ? l.line[1][0] : val;

    case FossilyticsChartLineConstraint.FIXED_VALUE:
      return l?.xConstraintValue?.[i] ?? val;

    case FossilyticsChartLineConstraint.VALUE:
      if (l.xConstraintValue) {
        return getConstraintValue(l.xConstraintValue, val);
      }
      return val;
    default:
      return val;
  }
};

const applyYLineConstraints = (v: number, i: number, l: FossilyticsChartLine): number => {
  let val = v;

  if (String(l.xMin) !== "undefined" && String(l.xMin) !== "null") val = Math.max(l.yMin ?? 0, v);

  switch (l.yConstraint) {
    case FossilyticsChartLineConstraint.AXIS:
      return i === 0 ? val : 0;

    case FossilyticsChartLineConstraint.LAST_VALUE:
      return i === 1 && l.line ? l.line[1][1] : val;

    case FossilyticsChartLineConstraint.FIXED_VALUE:
      return l?.yConstraintValue?.[i] ?? val;

    case FossilyticsChartLineConstraint.VALUE:
      if (l.yConstraintValue) {
        return getConstraintValue(l.yConstraintValue, val);
      }
      return val;

    default:
      return val;
  }
};

// line => handle circle point coordinate not the actual line
// unless it is vertical line
export interface FossilyticsChartLine {
  id?: string;
  name: string;
  type?: "default" | "vertical";
  controllable?: boolean;
  defaultDisabled?: boolean;
  linked?: string;
  color?: string;
  handleColor?: string;
  lineType?: string;
  lineWidth?: number;
  xMin?: number;
  yMin?: number;
  xConstraint?: FossilyticsChartLineConstraint;
  xConstraintValue?: [number | undefined, number | string | undefined];
  yConstraint?: FossilyticsChartLineConstraint;
  yConstraintValue?: [number | undefined, number | undefined];
  xAxisIndex?: number;
  yAxisIndex?: number;
  line?: number[][] | [string, number][];
  xValue?: number | string;
  key?: string;
}

export interface FossilyticsChartLegendSelected {
  [legend: string]: boolean;
}

// TODO: Implement more options from the legend.data option
export interface FossilyticsChartLegendData {
  name: string;
  color?: any;
  show?: boolean;
}

interface FossilyticsChartProps {
  id: string;
  isLoading: boolean;
  title?: string;
  blockSizing?: boolean;
  hideLegend?: boolean;
  legends?: FossilyticsChartLegendData[];
  xAxes: FossilyticsChartAxis[];
  yAxes: FossilyticsChartAxis[];
  series: FossilyticsChartSeries[];
  lines?: FossilyticsChartLine[];
  onLineChange?: (line: number[][] | [string, number][], index: number, isEnd?: boolean, item?: FossilyticsChartLine, xAxesState?: boolean) => void;
  xAxesState?: boolean;
  handleXAxesChange?: () => void;
  switchToggle?: FossilyticsChartToggle;
  customDataZoom?: FossilyticsChartCustomDataZoom;
  handleSetIsManual?: () => void;
  disableZoomBar?: boolean;
  draggable?: boolean;
  customLoadingText?: string;
}

// utils
const formatZoomLabel = (_: any, strValue: string) => {
  // currently will disable this formatter because it doesn't make sense for zoombar label
  // if (!/\d{4}-\d{2}-\d{2}/.test(strValue)) {
  //   return formatNumber(value);
  // }

  return strValue;
};

const getAxisBound = (value: { min: number; max: number }, axisType: string, boundType: "min" | "max") => {
  if (axisType === "log") return undefined;

  const padding = Math.abs(value.max - value.min) * 0.5;

  let bound: number;
  if (boundType === "min") {
    bound = value.min - padding;
  } else {
    bound = value.max + padding;
  }

  return bound;
};

const getYAxisPosition = (yAxes: FossilyticsChartAxis[]) => {
  let yAxesLeft: number[] = [];
  let yAxisRight: number[] = [];

  yAxes.forEach((axis, axisIndex) => {
    if (axis.show || !Object(axis).hasOwnProperty("show")) {
      if (axis.position === "left" || (!axis.position && axisIndex === 0)) {
        yAxesLeft.push(axisIndex);
      }
      if (axis.position === "right" || (!axis.position && axisIndex > 0)) {
        yAxisRight.push(axisIndex);
      }
    }
  });

  return {
    yAxesLeft,
    yAxisRight,
  };
};
// recommend to set container height if not echart will keep recalc

const FossilyticsChart = ({
  isLoading,
  title = "",
  blockSizing,
  hideLegend,
  legends,
  xAxes,
  yAxes,
  series,
  lines,
  onLineChange,
  children,
  switchToggle,
  xAxesState,
  handleXAxesChange,
  handleSetIsManual,
  customDataZoom,
  disableZoomBar,
  draggable = true,
  customLoadingText,
}: PropsWithChildren<FossilyticsChartProps>) => {
  const { progress } = useAppStore(
    (state) => ({
      progress: state.progress,
    }),
    shallow
  );

  const echartsWrap = useRef<HTMLDivElement>(null);
  const echartsRef = useRef<EChartsReact>(null);
  const [echartsGridRect, setEchartsGridRect] = useState<any>();
  const [legendSelected, setLegendSelected] = useState<FossilyticsChartLegendSelected>();
  const [customDataZoomState, setCustomDataZoomState] = useState<FossilyticsChartCustomDataZoom | undefined>(customDataZoom);

  const haveChangeZoomBrush = useRef(false);

  const { palette } = useThemeStyling();

  const [legendHeight, setLegendHeight] = useState(0);
  const [localLoading, setLocalLoading] = useState(false);

  const prevGridSize = useRef({});

  // this function is to generate grid size and accommodate zoom bar, y,x axis
  // need to call this function evertime chart is updating something
  const fixClipping = useCallback(() => {
    const myChart = echartsRef.current!.getEchartsInstance();
    const found = myChart["_componentsViews"].find((entry: any) => entry.type === "legend.plain");
    const currentLegendHeight = found._backgroundEl.shape.height;

    if (currentLegendHeight) {
      // Round height to avoid more renders as value fluctuates slightly.
      setLegendHeight(Math.round(currentLegendHeight));

      let yAxesLeft = 0;
      let yAxisRight = 0;

      yAxes.forEach((axis, axisIndex) => {
        if (axis.show || !Object(axis).hasOwnProperty("show")) {
          if (axis.position === "left" || (!axis.position && axisIndex === 0)) {
            yAxesLeft += 1;
          }
          if (axis.position === "right" || (!axis.position && axisIndex > 0)) {
            yAxisRight += 1;
          }
        }
      });

      const grid = {
        // to make space if there's multiple y axis
        left: 50 + yAxesLeft * 50,
        right: 50 + yAxisRight * 50,
        bottom: currentLegendHeight + 90,
      };
      if (_.isEqual(prevGridSize.current, grid)) return;
      myChart.setOption({
        grid,
      });
      prevGridSize.current = grid;
    }
  }, [yAxes]);

  // set chart title
  useEffect(() => {
    const myChart = echartsRef.current!.getEchartsInstance();
    myChart.setOption({ title: { text: title } });
  }, [echartsRef, title]);

  // set legend
  useEffect(() => {
    const myChart = echartsRef.current!.getEchartsInstance();
    myChart.setOption({ legend: { show: !hideLegend } });
  }, [echartsRef, hideLegend]);

  // change legend on off
  const onLegendSelectChange = useCallback(
    (params: any) => {
      const newLegendSelected = {
        ...params.selected,
      };
      setLegendSelected(newLegendSelected);
    },
    [setLegendSelected]
  );

  // if user select a legend
  useEffect(() => {
    if (legendSelected) {
      const myChart = echartsRef.current!.getEchartsInstance();

      myChart.setOption(
        {
          legend: {
            selected: legendSelected ?? {},
          },
        },
        { silent: true }
      );
    }
  }, [legendSelected]);

  const prevLegend = useRef({});

  // custom legend logic rendering to chart
  useEffect(() => {
    if (legends) {
      const myChart = echartsRef.current!.getEchartsInstance();
      const data: any[] = [
        ...series.map((s) => ({ name: s.name })),
        ...(lines ? lines.filter((l) => l.name != null && l.name.length > 0).map((l) => ({ name: l.name })) : []),
      ];

      legends
        .filter((l) => series.some((s) => s.name === l.name) || lines?.some((ln) => ln.name === l.name))
        .forEach((l) => {
          const idx = data.findIndex((d) => d.name === l.name);
          data.splice(idx >= 0 ? idx : data.length, idx >= 0 ? 1 : 0, {
            name: l.name,
            itemStyle: {
              color: l.color ?? "inherit",
            },
          });
          if (String(l.show) === "false") {
            data.splice(idx >= 0 ? idx : data.length, idx >= 0 ? 1 : 0);
          }
        });
      if (_.isEqual(data, prevLegend.current)) return;

      myChart.setOption({
        legend: {
          data,
        },
      });
      prevLegend.current = data;
    }
  }, [legends, lines, series]);

  // default zoom, meaning brush zoom behavior by echarts
  // only applied for draggable lines
  const updateChartGraphicPosition = useCallback(() => {
    const myChart = echartsRef?.current?.getEchartsInstance();
    let chartGraphicData: any[] = [];
    // this section is to add draggable touch point that the user can interact with
    // the handle doesn't have any ui, it is just overlaying touchable on top of the handle above on line 687
    if (lines && onLineChange && myChart) {
      const onPointDragging = (
        dataIndex: number,
        convertedPos: number[],
        isEnd: boolean,
        xAxesState: boolean,
        line: FossilyticsChartLine,
        i: number
      ) => {
        const newLine = line.line ? (xAxesState ? ([...line.line] as [string, number][]) : ([...line.line] as number[][])) : [[], []];

        const newPoint = [...convertedPos];
        const newDatePoint: [string, number] = ["", 0];

        // Apply constraints
        if (xAxesState) {
          newDatePoint[0] = applyXLineConstraints(newPoint[0], dataIndex, line) as string;
          newDatePoint[1] = applyYLineConstraints(newPoint[1], dataIndex, line);

          if (line.type === "vertical") {
            newLine[0] = [new Date(newDatePoint[0]).toISOString().slice(0, 19), 0];
            newLine[1] = [new Date(newDatePoint[0]).toISOString().slice(0, 19), 1];
          } else {
            newLine[dataIndex] = [new Date(newDatePoint[0]).toISOString().slice(0, 19), newDatePoint[1]];
          }

          onLineChange(newLine as [string, number][], i, isEnd, line, xAxesState);
        } else {
          newPoint[0] = applyXLineConstraints(newPoint[0], dataIndex, line) as number;
          newPoint[1] = applyYLineConstraints(newPoint[1], dataIndex, line);

          if (line.type === "vertical") {
            newLine[0] = [newPoint[0], 0];
            newLine[1] = [newPoint[0], 1];
          } else {
            newLine[dataIndex] = newPoint;
          }

          onLineChange(newLine as [number][], dataIndex, isEnd, line);
        }
      };

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];

        const controllable = line.controllable ?? true;
        if (controllable) {
          const lineId = line.id ?? line.name;

          // for vertical line, it can draw a line from top to the bottom of the chart -1 -> 1
          // in 1 condition that we provide yAxisValue, so echarts know where to plot theme
          const lineData =
            line.type === "vertical" && line.xValue
              ? [
                  [line.xValue, -1],
                  [line.xValue, 1],
                ]
              : line.line;

          if (lineData) {
            let showSymbol = true;
            if (legendSelected) {
              showSymbol = String(legendSelected?.[line?.linked ?? ""]) === "false" ? false : line.controllable ?? true;
            } else {
              const matchSeries = series.filter((serie) => serie.name === line?.linked);
              if (matchSeries.length > 0 && matchSeries[0].defaultDisabled) showSymbol = false;
            }

            chartGraphicData = [
              ...chartGraphicData,
              ...lineData.map((item, dataIndex) => {
                const id = `${lineId}-${dataIndex}`;
                let position: number[] = myChart.convertToPixel({ seriesId: line.id ?? line.name, yAxisIndex: 0 }, item);

                // force to get first load graphic position
                // this only apply for draggable vertical line
                // because echarts zooming, can't get the accurate pixel position after zoom, if we are using -1 and 1 as our y axis position with yAxis Index
                // which is supposed to show line from top to bottom
                if (line.type === "vertical" && haveChangeZoomBrush.current) {
                  // @ts-ignore
                  const prevOption = myChart?.getOption()?.graphic?.[0]?.elements;
                  const prevGraphic = prevOption.find((graph: { id: string }) => graph.id === id);
                  if (prevGraphic) position[1] = prevGraphic?.position[1];
                }

                return {
                  id,
                  type: "circle",
                  position,
                  shape: {
                    cx: 0,
                    cy: 0,
                    r: symbolSize / 2,
                  },
                  invisible: !showSymbol,
                  draggable: true,
                  style: {
                    stroke: line.handleColor ?? line.color,
                    fill: "white",
                    lineWidth: 2,
                  },
                  ondragend: (e: any) => {
                    if (!showSymbol) return;
                    const pos = [e.offsetX, e.offsetY];
                    const convertedPosition = myChart.convertFromPixel("grid", pos);
                    onPointDragging(dataIndex, convertedPosition, true, xAxesState!, line, i);

                    if (handleSetIsManual) {
                      handleSetIsManual();
                    }

                    setLocalLoading(true);
                    prevLineSeries.current = {};
                  },
                  z: 199,
                };
              }),
            ];
          }
        }
      }
    }

    return chartGraphicData;
  }, [handleSetIsManual, legendSelected, lines, onLineChange, series, xAxesState]);

  const prevZoomParam = useRef<any>({});

  const haveInitCustomDataZoom = useRef(false);

  const formatAxisLabel = (val: number, axis: "x" | "y", type: string) => {
    if (type === "value") {
      return formatNumber(val);
    }
    return val;
  };

  // zoom functionality, custom zoom
  useEffect(() => {
    const zoomParam = { customDataZoomState, legendHeight, xAxes, yAxes };
    const myChart = echartsRef.current!.getEchartsInstance();
    const option: any = myChart.getOption();

    const prev = {
      customDataZoomState: prevZoomParam.current?.customDataZoomState,
      legendHeight: prevZoomParam.current?.legendHeight,
      xAxes: prevZoomParam.current?.xAxes,
      yAxes: prevZoomParam.current?.yAxes,
    };

    if (_.isEqual(zoomParam, prev) && option.xAxis.length !== 0 && prevZoomParam.current?.lines?.length === lines?.length) return;
    const sharedDataZoomOptions = {
      type: "slider",
      filterMode: "none",
      showDataShadow: false,
      textStyle: { color: palette.customColor.black },
      labelFormatter: formatZoomLabel,
      borderColor: palette.customColor.neutralLight,
      fillerColor: palette.customColor.neutralLight,
      handleStyle: { borderColor: palette.customColor.neutralTertiary },
      moveHandleStyle: { color: palette.customColor.neutralTertiary },
      emphasis: {
        handleStyle: { borderColor: palette.customColor.neutralSecondary },
        moveHandleStyle: { color: palette.customColor.neutralSecondary },
      },
    };

    const { yAxesLeft, yAxisRight } = getYAxisPosition(yAxes);

    // this part of code to determine left or right for yaxes width, position scrollbar
    const yAxisScrollbar = yAxes.reduce((dataZooms, axis, i) => {
      if (axis.show ?? true) {
        const style: {
          [key: string]: number | string;
        } = {
          left: "unset",
          right: "unset",
        };

        const leftAxisIndex = yAxesLeft.indexOf(i);
        if (leftAxisIndex !== -1) {
          style.left = 75 * leftAxisIndex + 10;
        }
        const rightAxisIndex = yAxisRight.indexOf(i);
        if (rightAxisIndex !== -1) {
          style.right = 75 * rightAxisIndex + 10;
        }

        dataZooms.push({
          ...sharedDataZoomOptions,
          id: `y-${i}`,
          yAxisIndex: i,
          width: 15,
          ...style,
        });
      }
      return dataZooms;
    }, [] as any[]);

    const chartGraphicData = updateChartGraphicPosition();
    // ignore custom zoom state except the first time
    haveInitCustomDataZoom.current = true;

    myChart.setOption({
      xAxis: xAxes.map((axis) => ({
        ...axisOptions,
        name: axis.name,
        type: axis.type,
        min: axis.min, // auto re-scale when undefined is given
        max: axis.max, // auto re-scale when undefined is given
        inverse: !!axis.inverse,
        axisLabel: {
          formatter: axis.type === "log" || axis.type === "value" ? (val: number) => formatAxisLabel(val, "x", axis.type) : undefined,
        },
        axisLine: {
          onZeroAxisIndex: 0,
          lineStyle: { color: axis.color },
        },
        position: axis.position === undefined ? "bottom" : axis.position,
      })),
      yAxis: [
        ...yAxes.map((axis, i) => {
          return {
            ...axisOptions,
            ...axis,
            name: axis.name,
            show: axis.show ?? true,
            type: axis.type,
            min: axis.min ?? undefined,
            max: axis.max === undefined ? (value: any) => getAxisBound(value, axis.type, "max") : axis.max,
            inverse: !!axis.inverse,
            axisLabel: {
              formatter: axis.type === "log" || axis.type === "value" ? (val: number) => formatAxisLabel(val, "y", axis.type) : undefined,
            },
            axisLine: {
              show: true,
              lineStyle: { color: axis.color },
            },
            axisTick: {
              show: true,
            },
            splitLine: {
              show: i === 0,
            },
            offset: axis.offset,
            nameLocation: axis.nameLocation ?? "middle",
            position: axis?.position,
          };
        }),
        ...(lines && lines.filter((l) => l.type === "vertical").length > 0
          ? [
              {
                name: "Vertical Line Axis",
                show: false,
                min: -1,
                type: "value",
              },
            ]
          : []),
      ],
      dataZoom: disableZoomBar
        ? []
        : [
            // generate slider scrollbar
            ...xAxes.reduce((dataZooms, axis, i) => {
              if ((axis.show || customDataZoomState?.customDataZoom) && !haveInitCustomDataZoom.current) {
                dataZooms.push({
                  ...sharedDataZoomOptions,
                  id: `x-${i}`,
                  xAxisIndex: i,
                  height: 20,
                  end: customDataZoomState?.customDataZoom === true && customDataZoomState?.dataZoomPercentage,
                  ...(i > 0 ? { top: 30 } : { bottom: legendHeight + 20 }),
                  filterMode: "none",
                });
              } else if (axis.show ?? true) {
                dataZooms.push({
                  ...sharedDataZoomOptions,
                  id: `x-${i}`,
                  xAxisIndex: i,
                  height: 20,
                  ...(i > 0 ? { top: 30 } : { bottom: legendHeight + 20 }),
                  filterMode: "none",
                });
              }
              return dataZooms;
            }, [] as any[]),
            ...yAxisScrollbar,
            ...yAxes.reduce((dataZooms, axis, i) => {
              (axis.show === undefined || axis.show) &&
                dataZooms.push({
                  id: `scroll-${i}`,
                  type: "inside",
                  orient: "vertical",
                  filterMode: "none",
                  yAxisIndex: i,
                });
              return dataZooms;
            }, [] as any[]),
          ],
      graphic: chartGraphicData,
    });
    prevZoomParam.current = { ...zoomParam, lines };
    fixClipping();
  }, [
    echartsRef,
    legendHeight,
    xAxes,
    yAxes,
    lines,
    fixClipping,
    customDataZoomState,
    disableZoomBar,
    updateChartGraphicPosition,
    palette.customColor.black,
    palette.customColor.neutralLight,
    palette.customColor.neutralTertiary,
    palette.customColor.neutralSecondary,
  ]);

  // this is plugin function by echarts can provide multiple functionality
  // detail here: https://echarts.apache.org/en/option.html#toolbox.feature
  // the one we use now is only dataZoom
  useEffect(() => {
    const myChart = echartsRef.current!.getEchartsInstance();
    myChart.setOption({
      toolbox: {
        feature: {
          dataZoom: {
            iconStyle: {
              borderColor: "black",
            },
            emphasis: {
              iconStyle: {
                borderColor: palette.primary.main,
              },
            },
            title: {
              back: "Zoom Toggle",
            },
            filterMode: "none",
          },

          // this is custom reset zoom
          myResetZoom: {
            show: true,
            title: "Zoom Reset",
            icon: `image://${resetIcon}`,
            emphasis: {
              iconStyle: {
                borderColor: palette.primary.main,
                color: palette.primary.main,
              },
            },

            onclick: () => {
              const myChart = echartsRef.current!.getEchartsInstance();
              myChart.dispatchAction({
                type: "dataZoom",
                start: 0,
                end: 100,
              });
            },
          },
        },
      },
    });
  }, [palette.primary.main, updateChartGraphicPosition]);

  useEffect(() => {
    if (switchToggle?.showToggle !== true) return;
    // custom toolbox to toggle x state
    // currently only used in spad decline to toggle date -> days
    const myChart = echartsRef.current!.getEchartsInstance();
    myChart.setOption({
      toolbox: {
        feature: {
          mySwitchDayTime: {
            show: true,
            title: `${switchToggle.title}`,
            icon: `image://${toggleIcon}`,
            onclick: () => {
              handleXAxesChange?.();
            },
          },
        },
      },
    });
  }, [xAxes, series, switchToggle, handleXAxesChange]);

  // setting custom data zoom
  // need to be in use effect, because a lot of our modules show empty chart
  // before actually calculating and get the zoom percentage for ui reason
  useEffect(() => {
    if (customDataZoom && customDataZoomState?.dataZoomPercentage !== customDataZoom.dataZoomPercentage) {
      setCustomDataZoomState(customDataZoom);
    }
  }, [customDataZoom, customDataZoomState]);

  const chartSize = useRef({});

  // Handle chart resize
  // everytime resizing chart / closing nav / dragging or minimize window, need to call this to update every position since it is a canvas
  useEffect(() => {
    const resizeChart = (cr: DOMRect) => {
      if (echartsRef.current?.getEchartsInstance) {
        const size = { width: Number(cr.width.toFixed()), height: Number(cr.height.toFixed()) };
        if (_.isEqual(chartSize.current, size)) return;

        // Trigger Echarts resize with new bounds
        const myChart = echartsRef.current.getEchartsInstance();

        myChart.resize(size);

        // Extract Echarts grid rect for sizing other elements
        // @ts-ignore
        const gridRect = myChart.getModel().getComponent("grid").coordinateSystem.getRect();
        setEchartsGridRect(gridRect);

        fixClipping();

        // using async function to make sure chart line is already updated / the timeout doesn't matter
        setTimeout(() => {
          const chartGraphicData = updateChartGraphicPosition();
          if (chartGraphicData.length > 0) {
            myChart.setOption({
              graphic: chartGraphicData,
            });
          }
        }, 50);
        chartSize.current = size;
      }
    };

    // Configure resize observer on Echarts wrapper element
    const myWrap = echartsWrap.current!;

    resizeChart(myWrap.getBoundingClientRect());

    const resizeObserver = new ResizeObserver((entries) => {
      requestAnimationFrame(() => {
        for (const entry of entries) {
          const cr = entry.contentRect;
          resizeChart(cr);
        }
      });
    });

    resizeObserver.observe(myWrap);
    return () => {
      resizeObserver.disconnect();
    };
  }, [fixClipping, updateChartGraphicPosition]);

  // when user change zoom level we need to update the draggable
  useEffect(() => {
    const myChart = echartsRef.current!.getEchartsInstance();
    function updatePosition(...args: any) {
      const chartGraphicData = updateChartGraphicPosition();
      if (chartGraphicData.length > 0) {
        myChart.setOption({
          graphic: chartGraphicData,
        });
      }
    }
    myChart.on("dataZoom", updatePosition);

    return () => {
      myChart.off("dataZoom");
    };
  }, [lines, updateChartGraphicPosition]);

  const prevLineSeries = useRef<any>({});

  // this effect is to update the line and series ui
  useEffect(() => {
    const myChart = echartsRef.current!.getEchartsInstance();
    const yAxes = myChart.getOption().yAxis as any[];
    const vertAxisIndex = yAxes.findIndex((a) => a.name === "Vertical Line Axis");
    const seriesSelected = series.reduce((obj, s) => {
      obj[s.name] = s.defaultDisabled !== undefined ? !s.defaultDisabled : true;
      return obj;
    }, {} as FossilyticsChartLegendSelected);

    const mappedSeries = [
      // this part is to handle the series plotting in chart
      ...series.map((serie) => ({
        ...seriesOptions,
        id: serie.id ? serie.id : serie.name,
        name: serie.name,
        type: serie.type === "histogram" ? "bar" : serie.type,
        showSymbol: !serie.hideSymbol,
        symbolSize: serie.symbolSize ?? symbolSize * 0.5,
        xAxisIndex: serie.xAxisIndex,
        yAxisIndex: serie.yAxisIndex,
        z: serie.z,
        itemStyle: {
          color: serie.color,
        },
        lineStyle: {
          width: serie.lineWidth,
          type: serie.lineType,
        },
        data: serie.type === "histogram" && serie.data.length > 0 ? ecStat.histogram(serie.data as number[], "squareRoot").data : serie.data,
        markPoint: {
          symbol: "circle",
          symbolSize: symbolSize / 2,
          data: serie.markPointData ? serie.markPointData.map((d) => ({ coord: d })) : undefined,
        },
      })),
      // this part is to draw the line
      // we need this part to get the draggable position
      ...(lines ?? [])
        .filter((l) => l.type === undefined || l.type === "default")
        .map((line) => {
          return {
            ...seriesOptions,
            showSymbol: false,
            id: line.id ? line.id : line.name,
            name: line.name,
            type: "line",
            xAxisIndex: line.xAxisIndex,
            yAxisIndex: line.yAxisIndex,
            lineStyle: {
              color: line.color,
              width: line.lineWidth,
              type: line.lineType,
            },
            data: line.line,
          };
        }),
      // this part is to draw vertical line
      // typically to determine day
      ...(lines ?? [])
        .filter((l) => l.type === "vertical")
        .map((line) => ({
          id: line.id ? line.id : line.name,
          name: line.name,
          type: "line",
          showSymbol: false,
          xAxisIndex: line.xAxisIndex,
          yAxisIndex: vertAxisIndex,
          lineStyle: {
            color: line.color,
            width: line.lineWidth,
            type: line.lineType,
          },
          data: [
            [line.xValue, -1],
            [line.xValue, 1],
          ],
        })),
    ];

    const lineSeriesProps = { series, mappedSeries, legendSelected, draggable };

    if (_.isEqual(lineSeriesProps, prevLineSeries.current)) return;

    if (prevLineSeries.current.series && !_.isEqual({ series }, { series: prevLineSeries.current.series })) {
      setLocalLoading(false);
    } else {
      setTimeout(() => {
        setLocalLoading(false);
      }, 50);
    }

    myChart.setOption(
      {
        series: mappedSeries,
        legend: {
          selected: { ...seriesSelected, ...legendSelected },
        },
      },
      { replaceMerge: ["series"] }
    );

    setTimeout(() => {
      const chartGraphicData = updateChartGraphicPosition();

      myChart.setOption(
        {
          graphic: chartGraphicData.length > 0 ? chartGraphicData : undefined,
        },
        { replaceMerge: ["graphic"] }
      );
    }, 5);

    prevLineSeries.current = { mappedSeries, series, legendSelected, draggable };

    fixClipping();
  }, [echartsRef, fixClipping, lines, series, legendSelected, draggable, onLineChange, xAxesState, handleSetIsManual, updateChartGraphicPosition]);

  let loadingText = "Loading chart...";

  const loadingStatus = useMemo(() => {
    return isLoading || localLoading;
  }, [isLoading, localLoading]);

  if (progress) {
    loadingText += ` ${progress}%`;
  }

  const loadingOptions = {
    text: customLoadingText ?? loadingText,
    color: palette.primary.main,
    textColor: palette.primary.main,
  };

  return (
    <div
      onContextMenu={(event) => event.preventDefault()}
      ref={echartsWrap}
      style={{ position: "relative", ...(blockSizing ? {} : { inlineSize: "100%", blockSize: "100%" }) }}
    >
      <EChartsReact
        style={blockSizing ? { height: 400 } : { position: "absolute", top: 0, left: 0, height: "100%" }}
        loadingOption={loadingOptions}
        showLoading={loadingStatus}
        ref={echartsRef}
        onEvents={{
          legendselectchanged: onLegendSelectChange,
        }}
        option={options}
      />
      <div
        style={{
          display: !isLoading && echartsGridRect ? "block" : "none",
          position: "absolute",
          top: echartsGridRect?.y,
          left: echartsGridRect?.x,
          width: echartsGridRect?.width,
          height: echartsGridRect?.height,
          pointerEvents: "none",
          overflow: "hidden",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default FossilyticsChart;
