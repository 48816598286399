import { DataSet, Group, Project } from "@/model";
import { MissingDataSet } from "@/models/wells/State";

export type TreeNode = {
  key: string;
  title: string;
  children?: TreeNode[];
  type: "project" | "group" | "dataset";
  level: number;
  parents: string[];
  data_set_ids?: string[];
  childIdList?: string[];
  childKeyList?: string[];
  id: string;
  dataSet?: DataSet;
};

export function transformProjectsToTreeNodes(
  projects: Project[],
  dataSetMap: Map<string, DataSet>,
  filteredDataSetIds: Set<string>,
  missingDataSetMap: Map<string, MissingDataSet>
): TreeNode[] {
  return projects.map((project) => transformProjectToTreeNode(project, dataSetMap, filteredDataSetIds, missingDataSetMap));
}

function transformProjectToTreeNode(
  project: Project,
  dataSetMap: Map<string, DataSet>,
  filteredDataSetIds: Set<string>,
  missingDataSetMap: Map<string, MissingDataSet>
): TreeNode {
  const level = 1;
  const treeNode: TreeNode = {
    key: project.id,
    id: project.id,
    title: project.name,
    type: "project",
    level,
    parents: [],
    children: transformGroupsToTreeNodes(project.groups, level + 1, [project.id], dataSetMap, filteredDataSetIds, missingDataSetMap),
  };
  const { ids: childIdList, keys: childKeyList } = collectAllChildIdsAndKeys(treeNode.children);
  treeNode.childIdList = childIdList;
  treeNode.childKeyList = childKeyList;
  return treeNode;
}

function transformGroupsToTreeNodes(
  groups: Group[],
  level: number,
  parents: string[],
  dataSetMap: Map<string, DataSet>,
  filteredDataSetIds: Set<string>,
  missingDataSetMap: Map<string, MissingDataSet>
): TreeNode[] {
  return groups.map((group) => transformGroupToTreeNode(group, level, parents, dataSetMap, filteredDataSetIds, missingDataSetMap));
}

function transformGroupToTreeNode(
  group: Group,
  level: number,
  parents: string[],
  dataSetMap: Map<string, DataSet>,
  filteredDataSetIds: Set<string>,
  missingDataSetMap: Map<string, MissingDataSet>
): TreeNode {
  const parentIds = [...parents, group.id];
  const childrenNodes: TreeNode[] = transformGroupsToTreeNodes(group.groups, level + 1, parentIds, dataSetMap, filteredDataSetIds, missingDataSetMap);

  // Add datasets as children nodes if they exist
  if (group.data_set_ids) {
    const dataSetNodes: TreeNode[] = group.data_set_ids
      .filter((dataSetId) => filteredDataSetIds.has(dataSetId.toLowerCase()))
      .map((dataSetId) => {
        const dataSetIdLower = dataSetId.toLowerCase();
        const currDataSet = dataSetMap.get(dataSetIdLower);
        const missingDataSet = missingDataSetMap.get(dataSetIdLower);

        return {
          key: `${dataSetId};${group.id}`,
          id: dataSetId,
          title: currDataSet?.name ?? missingDataSet?.name ?? dataSetId,
          type: "dataset",
          level: level + 1,
          parents: parentIds,
          dataSet: currDataSet,
        };
      });

    dataSetNodes.sort((a, b) => a.title.localeCompare(b.title));
    childrenNodes.push(...dataSetNodes);
  }

  const treeNode: TreeNode = {
    key: group.id,
    id: group.id,
    title: group.name,
    type: "group",
    level,
    parents,
    data_set_ids: group.data_set_ids,
    children: childrenNodes.length > 0 ? childrenNodes : undefined,
  };
  const { ids: childIdList, keys: childKeyList } = collectAllChildIdsAndKeys(treeNode.children);
  treeNode.childIdList = childIdList;
  treeNode.childKeyList = childKeyList;
  return treeNode;
}

// Collects all child IDs and keys from a tree of TreeNodes.
function collectAllChildIdsAndKeys(children?: TreeNode[]): { ids: string[]; keys: string[] } {
  if (!children) return { ids: [], keys: [] };
  let allChildIds: string[] = [];
  let allChildKeys: string[] = [];

  for (const child of children) {
    allChildIds.push(child.id);
    allChildKeys.push(child.key);

    if (child.children) {
      const { ids, keys } = collectAllChildIdsAndKeys(child.children);
      allChildIds.push(...ids);
      allChildKeys.push(...keys);
    }
  }
  return { ids: allChildIds, keys: allChildKeys };
}

export function findParentByKey(tree: TreeNode[], searchKey: string): TreeNode | null {
  for (const node of tree) {
    if (node.children) {
      for (const child of node.children) {
        if (child.key === searchKey) {
          return node; // Return the parent if the child's key matches
        }
      }
      const result = findParentByKey(node.children, searchKey);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

export function filterDataSetsByTagsAndText(
  dataSets: DataSet[],
  selectedTags: Record<string, string[]>,
  dataSetTagsMap: Map<string, Map<string, string>>,
  searchText: string
): DataSet[] {
  return dataSets.filter((dataSet) => {
    const tagMap = dataSetTagsMap.get(dataSet.id);
    if (!tagMap) {
      // Exclude datasets without tags (missing datasets)
      return false;
    }

    // Filter by tags
    for (const [tagKey, tagValues] of Object.entries(selectedTags)) {
      const dataSetTagValue = tagMap.get(tagKey);
      if (!dataSetTagValue || !tagValues.includes(dataSetTagValue)) {
        return false;
      }
    }

    // Filter by dataset name
    if (searchText.trim() !== "") {
      if (!dataSet.name.toLowerCase().includes(searchText.toLowerCase())) {
        return false;
      }
    }

    return true;
  });
}
