import React from "react";
import ScreenCapture from "@/utils/screenCapture";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { ReactComponent as Logo } from "../../logo.svg";

import useNavMenu from "./useNavMenu";
import NavMenu from "./NavMenu";
import useLicenseInfo from "@/utils/useLicenseInfo";
import useThemeStyling from "@/utils/useThemeStyling";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import dictionary from "@/constants/dictionary";

const HeaderContainer = styled.header((props: any) => ({
  boxShadow: props.boxShadow,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingRight: 10,
  svg: {
    width: 116,
    height: 48,
  },
}));

const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: rgb(133, 133, 133);
  align-items: center;

  .profile-detail {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-right: 20px;
  }
  .email-text {
    color: black;
    font-size: 14px;
  }
`;

const Header = () => {
  const { expiryDateMessage } = useLicenseInfo();
  const { palette } = useThemeStyling();

  const {
    effects,
    fileMenuList,
    modulesMenuList,
    primaryMenuList,
    projectMenuList,
    helpMenuList,
    buildNumberText,
    username,
    tenant,
    handleScreenCapture,
    resetProject,
    setShowLicenseDialog,
    showLicenseDialog,
    isLoading,
    onClickRevokeLicense,
  } = useNavMenu();

  return (
    <>
      <ScreenCapture onEndCapture={handleScreenCapture}>
        {({ onStartCapture }) => {
          return (
            <HeaderContainer boxShadow={effects.elevation8} className="App-header">
              <NavContainer>
                <NavMenu
                  title="Predico"
                  customTitle={
                    <Link
                      onClick={() => {
                        resetProject();
                        // for handling error state by boundary
                        if (document.getElementById("error-boundary-msg")) {
                          window.location.href = "/modules";
                        }
                      }}
                      to="/"
                    >
                      <Logo style={{ marginTop: 11 }} aria-label="Predico" />
                    </Link>
                  }
                  menuList={primaryMenuList}
                  resetProject={resetProject}
                />
                <NavMenu
                  customFunc={{
                    id: "screen-capture",
                    onClick: () => {
                      onStartCapture();
                    },
                  }}
                  title="File"
                  menuList={fileMenuList}
                />
                <NavMenu title="Modules" menuList={modulesMenuList} />
                <NavMenu title="Project" menuList={projectMenuList} />
                <NavMenu title="Help" menuList={helpMenuList} />
              </NavContainer>

              <ProfileContainer>
                <div className="profile-detail">
                  <span className="email-text">{username}</span>
                  <div>
                    <span
                      style={{
                        color: palette.error.main,
                      }}
                    >
                      {expiryDateMessage}
                    </span>

                    <span>
                      {expiryDateMessage && "  -  "}
                      {tenant ?? "Predico"}
                    </span>
                  </div>
                  <span>Version: {buildNumberText}</span>
                </div>
                <Avatar>{username.slice(0, 1).toUpperCase()}</Avatar>
              </ProfileContainer>
            </HeaderContainer>
          );
        }}
      </ScreenCapture>
      <Dialog open={showLicenseDialog} keepMounted>
        <DialogTitle className="primaryColor" style={{ textAlign: "left", fontWeight: "bold" }}>
          {dictionary.license.returnLicense}
        </DialogTitle>
        <DialogContent style={{ width: 350 }}>{dictionary.license.returnLicenseDescription}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowLicenseDialog(false);
            }}
            disabled={isLoading}
            variant="outlined"
          >
            {dictionary.genericButton.cancel}
          </Button>
          <Button
            style={{ color: "white", marginTop: 10, marginBottom: 10 }}
            variant="contained"
            disabled={isLoading}
            onClick={onClickRevokeLicense}
            data-testid="remove-well-btn"
          >
            {dictionary.genericButton.continue}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Header;
