import { TableHeader } from "@/models/Generic";
import { dataTableHeaderStyles } from "./constants/grid";
import { ForecastEvent } from "@/models/tahk";

export const getDataTableHeader = (tableHeader: TableHeader) => {
  return {
    rowId: "header",
    cells: Object.keys(tableHeader).map((key) => {
      return {
        type: "custom",
        text: tableHeader[key].text,
        style: dataTableHeaderStyles,
        sub: tableHeader[key].sub,
        link: tableHeader[key].link,
      };
    }),
    height: 40,
  };
};

export const dataTableNotation = (tableHeader: TableHeader) => {
  return {
    rowId: "header",
    cells: Object.keys(tableHeader).map((key) => {
      return {
        type: "custom",
        text: tableHeader[key].notation,
        style: {
          background: "#fff",
          display: "flex",
          justifyContent: "center",
          zIndex: 1,
        },
      };
    }),
  };
};

export const restructureForecast = (forecastList: ForecastEvent[]) => {
  return forecastList.filter((forecast) => forecast.date && forecast.pressure);
};
