import _ from "lodash";
import { useCallback, useMemo, useState } from "react";
import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";

import dictionary from "@/constants/dictionary";
import { camelToSnakeCase, formatToEngineeringNotation, mapEnumToDropdownReactGrid } from "@/utils/general";

import { TahkCsgInputLayerCharts, TahkCsgInputState, TahkCsgStateResponse } from "@/models/tahk";
import {
  relativePermeabilityHeader,
  relativePermeabilityWidth,
  tableCellStyle,
  tableHeaderNotationStyle,
  tableHeaderStyle,
} from "../../../constants/grid";
import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { RelativePermeability, RelativePermeabilityCorrelationEnum } from "@/models/InputGeneric";

export type TahkCsgRelativePermeabilityProps = {
  inputs?: TahkCsgInputState;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  loadingState: boolean;
  layers: TahkCsgInputLayerCharts[];
};

const useTahkCsgRelativePermeability = ({ inputs, setTahkCsgState, loadingState, layers }: TahkCsgRelativePermeabilityProps) => {
  const [activeDropdown, setActiveDropdown] = useState<{ columnId: string; row: number } | null>(null);

  const relativePermeabilityColumns = Object.keys(relativePermeabilityHeader).map((columnId) => {
    return { columnId: camelToSnakeCase(columnId), width: relativePermeabilityWidth[columnId] };
  });

  const relativePermeabilityRows: Row<any>[] = useMemo(() => {
    if (!inputs) return [];
    const input = _.cloneDeep(inputs);
    const headerKeys = Object.keys(relativePermeabilityHeader);
    return [
      {
        rowId: "header",
        cells: Object.keys(relativePermeabilityHeader).map((header) => {
          return {
            type: "custom",
            text: relativePermeabilityHeader[header].label,
            style: tableHeaderStyle,
            sub: relativePermeabilityHeader[header]?.sub ?? null,
          };
        }),
        height: 50,
      },
      {
        rowId: "notation",
        cells: headerKeys.map((header: string) => {
          return {
            type: "header",
            text: dictionary.tableUnits[header],
            style: tableHeaderNotationStyle,
          };
        }),
      },
      ...input.layers.map((layer, index) => {
        const relativePermeability: RelativePermeability = layer.relative_permeability;
        return {
          rowId: index,
          height: 30,
          cells: [
            {
              type: "number",
              value: index + 1,
              nonEditable: true,
              style: tableCellStyle,
            },
            ...headerKeys.slice(1, headerKeys.length).map((header, headerIndex) => {
              const val =
                headerIndex === 0
                  ? relativePermeability[camelToSnakeCase(header) as keyof RelativePermeability]
                  : relativePermeability.parameter[camelToSnakeCase(header) as keyof RelativePermeability["parameter"]];
              if (headerIndex === 0) {
                return {
                  type: "dropdown",
                  selectedValue: val ?? undefined,
                  values: mapEnumToDropdownReactGrid(RelativePermeabilityCorrelationEnum),
                  style: tableCellStyle,
                  isOpen: !!(activeDropdown && activeDropdown.columnId === camelToSnakeCase(header) && index === activeDropdown.row),
                  nonEditable: loadingState,
                };
              } else if (isNaN(Number(val))) {
                return {
                  type: "text",
                  text: "-",
                  style: tableCellStyle,
                  nonEditable: true,
                };
              }

              return {
                type: "text",
                text: formatToEngineeringNotation(Number(val)),
                style: tableCellStyle,
                nonEditable: loadingState,
              };
            }),
          ],
        };
      }),
    ];
  }, [inputs, loadingState, activeDropdown]);

  const onChangeRelativePermeabilityCell = useCallback(
    (changes: CellChange[]) => {
      if (!inputs) return;
      const updatedRows = _.cloneDeep(inputs.layers);

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell, previousCell, type } = change as CellChange<any>;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        rowId = rowId as number;
        columnId = columnId as string;

        const relativePermeability = updatedRows[rowId].relative_permeability as { [key: string]: any };

        if (type === "dropdown") {
          const newDropdown = {
            row: rowId,
            columnId: columnId,
          };
          if (prevCell.isOpen !== dropDownNewCell.isOpen || !_.isEqual(newDropdown, activeDropdown)) {
            if (dropDownNewCell.isOpen) {
              setActiveDropdown(newDropdown);
            } else {
              setActiveDropdown(null);
            }
          }
          if (dropDownNewCell.isOpen) return;
          if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
            relativePermeability[columnId] = dropDownNewCell.selectedValue;
          }
        } else {
          relativePermeability.parameter[columnId] = Number(newCell.value ?? newCell.text ?? 0);
        }
      }
      const arrayEqual = _.isEqual(_.cloneDeep(updatedRows), _.cloneDeep(inputs.layers));
      if (arrayEqual) return;
      setTahkCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            layers: updatedRows,
          },
        };
      });
    },
    [inputs, setTahkCsgState, activeDropdown]
  );

  // charts

  const yAxis = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: `${dictionary.tahk.gasRelativePermeability}`,
        type: "value",
        color: "red",
        max: 1,
      },
      {
        name: `${dictionary.tahk.waterRelativePermeability}`,
        type: "value",
        color: "blue",
        max: 1,
      },
    ],
    []
  );

  const xAxis = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: `${dictionary.tahk.waterSaturation}`,
        type: "value",
        color: "black",
      },
    ],
    []
  );

  const series = useMemo<FossilyticsChartSeries[]>(() => {
    const res: FossilyticsChartSeries[] = [];

    layers.forEach((layer, index) => {
      res.push({
        name: `krg - ${dictionary.tahk.layer} ${index + 1}`,
        type: "line",
        z: index + 1,
        data: layer.rel_perm_charts
          ? layer.rel_perm_charts.water_saturation.map((x: number, y: number) => [x, layer.rel_perm_charts.gas_relative_permeability[y]])
          : [],
        hideSymbol: true,
        lineWidth: 1,
        yAxisIndex: 0,
        color: "red",
      });
      res.push({
        name: `krw - ${dictionary.tahk.layer} ${index + 1}`,
        type: "line",
        z: index + 1,
        data: layer.rel_perm_charts
          ? layer.rel_perm_charts.water_saturation.map((x: number, y: number) => [x, layer.rel_perm_charts.water_relative_permeability[y]])
          : [],
        hideSymbol: true,
        lineWidth: 1,
        yAxisIndex: 1,
        color: "blue",
      });
    });
    return res;
  }, [layers]);

  return {
    relativePermeabilityColumns,
    relativePermeabilityRows,
    onChangeRelativePermeabilityCell,
    yAxis,
    xAxis,
    series,
  };
};

export default useTahkCsgRelativePermeability;
