import { shallow } from "zustand/shallow";

import { useAppStore } from "@/features/app";

const useExpandParameter = () => {
  const { expandParameter, setExpandParameter } = useAppStore(
    (state) => ({
      expandParameter: state.expandParameter,
      setExpandParameter: state.setExpandParameter,
    }),
    shallow
  );

  return {
    expandParameter,
    setExpandParameter,
  };
};

export default useExpandParameter;
