import { useMemo } from "react";
import styled from "@emotion/styled";
import { shallow } from "zustand/shallow";

import { useAppStore } from "@/features/app";

import useThemeStyling from "@/utils/useThemeStyling";
import InputField from "@/components/fields/InputField";
import Tabs from "@/components/Tabs";
import dictionary from "@/constants/dictionary";

import useDataview from "./hooks/useDataview";
import { capitalizeFirstLetter } from "@/utils/general";
import CustomTable from "@/components/CustomTable";
import FossilyticsChart from "@/components/FossilyticsChart";
import LoadingIndicator from "@/components/LoadingIndicator";
import useTagList, { tagColumn } from "@/utils/useTagList";
import { useTreeViewState } from "@/components/TreeView/hooks/TreeViewContextV2";
import useExpandParameter from "@/utils/useExpandParameter";

const Container = styled.div`
  padding: 20px;
  overflow: auto;

  display: grid;
  grid-template-columns: 360px 1fr;
  grid-gap: 20px;
  height: 95%;
`;

const DataviewPage = () => {
  const { elevatedCard } = useThemeStyling();

  const { selectedDataSets, currentModule, project, setApiError, apiError, dataSets } = useAppStore(
    (state) => ({
      currentModule: state.currentModule,
      selectedDataSets: state.selectedDataSets,
      project: state.project,
      setApiError: state.setApiError,
      apiError: state.apiError,
      dataSets: state.dataSets,
    }),
    shallow
  );
  const { treeViewWidth } = useTreeViewState();
  const { expandParameter } = useExpandParameter();

  const { loadingState, setStateOption, stateOption, dataOptionColumn, dataOptionRows, onCellsChanged, yAxes, xAxes, series, dataColumns, dataRows } =
    useDataview({
      setApiError,
      apiError,
      currentModule,
      project,
      selectedDataSets,
    });

  const { tagTableList } = useTagList({
    dataSets,
    selectedDataSets: selectedDataSets,
  });

  const getMaxWidthRightSide = window.innerWidth - treeViewWidth - 380 - 20 - 32; // 380 data options, 20, 2em(32px) padding

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.dataView.dataChart}</span>,
        key: "Data chart",
        content: (
          <div
            style={{
              height: 620,
            }}
          >
            {series.length === 0 ? (
              <LoadingIndicator />
            ) : (
              <FossilyticsChart id={"dataview_chart"} key={"dataview_chart"} isLoading={loadingState} xAxes={xAxes} yAxes={yAxes} series={series} />
            )}
          </div>
        ),
      },
      {
        label: <span>{dictionary.dataView.dataTable}</span>,
        key: "Data table",
        content: (
          <div
            style={{
              marginTop: 20,
              height: 500,
              width: getMaxWidthRightSide,
              overflow: "auto",
            }}
          >
            <CustomTable
              style={{
                display: "flex",
                overflow: "auto",
                height: "100%",
                width: "fit-content",
              }}
              columns={dataColumns}
              rows={dataRows}
              noScrollable
              enableRangeSelection
              enableColumnSelection
              isLoading={loadingState}
              stickyTopRows={1}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.dataView.tags}</span>,
        key: "Tags",
        content: (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: 20,
              paddingTop: "1em",
            }}
          >
            {/* render tag table per well */}
            {tagTableList.map((tag) => {
              return (
                <div key={tag.well.name}>
                  {tagTableList.length > 1 && <h3 className="noMarginver">{tag.well.name}</h3>}

                  <CustomTable
                    style={{
                      display: "flex",
                      overflow: "auto",
                      height: "100%",
                      width: 415,
                      marginTop: 5,
                    }}
                    columns={tagColumn}
                    rows={tag.tagTableRow}
                    enableRangeSelection={false}
                    enableColumnSelection={false}
                    isLoading={loadingState}
                    stickyTopRows={1}
                  />
                </div>
              );
            })}
          </div>
        ),
      },
    ];
  }, [series, loadingState, xAxes, yAxes, getMaxWidthRightSide, dataColumns, dataRows, tagTableList]);

  return (
    <Container
      style={{
        gridTemplateColumns: expandParameter ? "360px 1fr" : "1fr",
      }}
    >
      {expandParameter && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div style={elevatedCard}>
            <h3 className="primaryColor noMarginVer">{dictionary.dataView.dataOptions}</h3>
            {stateOption &&
              Object.keys(stateOption?.data_option).map((option: string) => {
                return (
                  <InputField
                    key={option}
                    type="float"
                    label={capitalizeFirstLetter(option.replaceAll("_", " "))}
                    debounceDelay={1000}
                    disabled={loadingState}
                    onChange={(v) => {
                      setStateOption((prev) => {
                        if (!prev || !stateOption) return prev;
                        return {
                          ...prev,
                          data_option: { ...stateOption.data_option, [option]: Number(v) },
                        };
                      });
                    }}
                    value={stateOption.data_option[option]}
                  />
                );
              })}
          </div>
          <div style={{ ...elevatedCard, marginTop: 20, height: "100%" }}>
            <h3 className="primaryColor noMarginVer">{dictionary.dataView.chartOptions}</h3>
            <CustomTable
              dataTestId="chart-options-table"
              style={{
                display: "flex",
                width: "100%",
                overflow: "auto",
                height: "100%",
              }}
              columns={dataOptionColumn}
              rows={dataOptionRows}
              enableRangeSelection
              enableColumnSelection
              stickyTopRows={1}
              onCellsChanged={onCellsChanged}
              isLoading={false}
            />
          </div>
        </div>
      )}

      <div style={elevatedCard}>
        <Tabs tabContainerStyle={{ flex: "unset", overflow: "unset" }} tabList={tabList} />
      </div>
    </Container>
  );
};

export default DataviewPage;
