import dictionary from "@/constants/dictionary";

import { mapEnumToDropdown } from "@/utils/general";
import { TahkCsgInputState, TahkCsgSelectedGeometryEnum } from "@/models/tahk";

import IconButton from "@mui/material/IconButton";
import InputIcon from "@mui/icons-material/Input";

import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";

import useThemeStyling from "@/utils/useThemeStyling";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import useReservoirInput, { UseReservoirInputProps } from "../../hooks/input/useReservoirInput";
import DropdownField from "@/components/fields/DropdownField";
import InputField from "@/components/fields/InputField";

type ReservoirInputProps = {
  loadingState: boolean;
  inputs?: TahkCsgInputState;
  errorInputValidation: ErrorValidationDetail[];
  layerError: string[];
  setExpandReservoirInput: React.Dispatch<React.SetStateAction<boolean>>;
  expandReservoirInput: boolean;
} & UseReservoirInputProps;

const ReservoirInput = ({
  setExpandReservoirInput,
  expandReservoirInput,
  loadingState,
  inputs,
  errorInputValidation,
  setTahkCsgState,
  layerError,
}: ReservoirInputProps) => {
  const {
    onChangeInput,
    onChangeReservoirParameter,
    reservoirInputColumns,
    reservoirInputRows,
    errorInputValidation: tableErrorValidation,
  } = useReservoirInput({
    loadingState,
    setTahkCsgState,
    inputs,
  });
  const { elevatedCard, palette, shadows } = useThemeStyling();

  return (
    <div
      style={{
        ...elevatedCard,
        position: "relative",
      }}
    >
      <h4
        style={{
          color: palette.primary.main,
        }}
      >
        {dictionary.tahk.reservoirInput}
      </h4>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: 20,
          marginBottom: 20,
        }}
      >
        <InputField
          keyField="inputs.number_of_layers"
          label={dictionary.tahk.numOfLayer}
          disabled={loadingState}
          value={inputs?.number_of_layers}
          onChange={(v) => onChangeInput(v, "number_of_layers")}
          min={1}
          errors={errorInputValidation}
        />
        <DropdownField
          style={{
            marginTop: 0,
          }}
          label={dictionary.tahk.geometry}
          options={mapEnumToDropdown(TahkCsgSelectedGeometryEnum)}
          disabled={loadingState}
          selectedKey={inputs?.selected_geometry}
          onChange={(v) => {
            onChangeInput(v, "selected_geometry");
          }}
        />
      </div>

      {[...layerError, ...tableErrorValidation].map((errMsg) => (
        <div style={{ color: "red" }} key={errMsg}>
          {errMsg}
        </div>
      ))}

      <ReactGridTable
        viewWidth={"100%"}
        viewHeight={360}
        columns={reservoirInputColumns}
        rows={reservoirInputRows}
        enableRangeSelection
        enableColumnSelection
        stickyTopRows={2}
        onCellsChanged={onChangeReservoirParameter}
      />

      <IconButton
        style={{
          boxShadow: shadows[2],
        }}
        className="toggle-btn"
        aria-label="collapse"
        onClick={() => setExpandReservoirInput((prev) => !prev)}
      >
        <InputIcon
          fontSize="small"
          style={{
            color: palette.primary.main,
            transform: !expandReservoirInput ? "none" : "scaleX(-1)",
          }}
        />
      </IconButton>
    </div>
  );
};

export default ReservoirInput;
