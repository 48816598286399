import {
  defineParametersScheme,
  gasPvtScheme,
  langmuirIsothermScheme,
  oilPvtScheme,
  waterPvtScheme,
  wellboreInputScheme,
} from "@/models/InputGeneric";
import { z, object } from "zod";

export const autoRtaInputGridScheme = object({
  defined_parameters: defineParametersScheme,
  gas_pvt: gasPvtScheme,
  water_pvt: waterPvtScheme,
  oil_pvt: oilPvtScheme,
  langmuir_isotherm: langmuirIsothermScheme,
  wellbore_model: wellboreInputScheme,
});

export type AutoRtaInputGrid = z.infer<typeof autoRtaInputGridScheme>;
