import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import {
  AnalysisFmbChartRequest,
  AnalysisFmbChartResponse,
  analysisFmbChartResponseScheme,
  AnalysisFmbParameter,
  AnalysisFmbRequest,
  AnalysisFmbResult,
  analysisFmbResultScheme,
  AnalysisFmbState,
  AutoFmbState,
  ForecastFmbResultResponse,
  PzPoint,
} from "@/models/autoFmb";
import { HandlebarCoordinateItem, ModuleIdentity } from "@/models/Generic";

import { usePolling } from "@/utils/apiFetcher";
import { pollAutoFmbAnalysisCalcApi, pollAutoFmbChartApi } from "@/constants/apiUrl";

import { ApiHelper, SummaryFmb } from "../context/AutoFmbContext";
import dictionary from "@/constants/dictionary";
import { CellChange, Row } from "@silevis/reactgrid";
import { tableCellStyle, tableCellStyleDisabled, tableHeaderNotationStyle, tableHeaderStyle } from "@/components/CustomTable";
import { formatToEngineeringNotation } from "@/utils/general";
import { caseKey } from "../constants";
import { FossilyticsChartLine } from "@/components/FossilyticsChart";

const safeFmbDictionary: { [key: string]: string } = dictionary.fmb;

type UseAutoFmbAnalysisProps = {
  autoFmbState?: AutoFmbState;
  setAutoFmbState: React.Dispatch<React.SetStateAction<AutoFmbState | undefined>>;
  analysisIdentity?: ModuleIdentity;
  tabIndex: number;
  autoFmbAnalysisCalculation?: AnalysisFmbResult;
  setAutoFmbAnalysisCalculation: React.Dispatch<React.SetStateAction<AnalysisFmbResult | undefined>>;
  setAutoFmbForecastCalculation: React.Dispatch<React.SetStateAction<ForecastFmbResultResponse | undefined>>;
  setUpdateInputFromAnalysis: React.Dispatch<React.SetStateAction<boolean>>;
} & ApiHelper;

export const caseTableColumn = [
  {
    columnId: "type",
    width: 155,
    label: "",
    notation: "",
  },
  {
    columnId: "area",
    width: 146,
    label: dictionary.fmb.area,
    notation: dictionary.suffix.acres,
  },
  {
    columnId: "permeability",
    width: 146,
    label: dictionary.fmb.k,
    notation: dictionary.suffix.md,
  },
];

export const manualPointColumn = [
  {
    columnId: "gas_cumulative_production",
    width: 220,
    label: dictionary.fmb.cumulativeProduction,
    notation: dictionary.suffix.cumulativeProduction,
  },
  {
    columnId: "static_pressure",
    width: 210,
    label: dictionary.fmb.staticPressure,
    notation: dictionary.suffix.staticPressure,
  },
];

const useAutoFmbAnalysis = ({
  setApiError,
  setAutoFmbState,
  setIsLoading,
  setPollStatus,
  setProgress,
  apiError,
  analysisIdentity,
  autoFmbState,
  setAutoFmbAnalysisCalculation,
  autoFmbAnalysisCalculation,
  setAutoFmbForecastCalculation,
  setUpdateInputFromAnalysis,
}: UseAutoFmbAnalysisProps) => {
  const [errorInputValidation, setErrorInputValidation] = useState<ErrorValidationDetail[]>([]);

  const { createPoll } = usePolling({
    setApiError,
    setLoadingState: setIsLoading,
    setProgressStatus: (val) => {
      setProgress(val.progress ?? null);
      setPollStatus(val.pollStatus);
    },
    setErrorValidation: setErrorInputValidation,
    apiError,
    setInfoContinuous: (value: { task_status: string; task_info: any }) => {
      console.log(value.task_info);
    },
  });

  // specific to analysis tab api
  const cannotCallApi = useMemo(
    () =>
      !autoFmbState?.inputs ||
      !autoFmbState.analysis ||
      !analysisIdentity?.project_id ||
      (analysisIdentity?.data_set_ids && analysisIdentity?.data_set_ids.length < 1),
    [analysisIdentity?.data_set_ids, analysisIdentity?.project_id, autoFmbState?.analysis, autoFmbState?.inputs]
  );

  const onCalculateAnalysisFmb = useCallback(async () => {
    if (cannotCallApi) return;

    try {
      const calculation = await createPoll<AnalysisFmbResult, AnalysisFmbRequest>({
        path: pollAutoFmbAnalysisCalcApi(analysisIdentity!.project_id),
        type: "post",
        body: {
          data_set_ids: analysisIdentity!.data_set_ids,
          inputs: autoFmbState!.inputs,
          analysis: autoFmbState!.analysis,
        },
      });
      if (calculation.task_result) {
        const parsed = analysisFmbResultScheme.parse(calculation.task_result);
        setAutoFmbAnalysisCalculation(parsed);
        if (!_.isEqual(autoFmbState!.analysis, parsed.analysis_input)) {
          setAutoFmbForecastCalculation(undefined);
          setAutoFmbState((prev) => {
            if (!prev) return prev;
            return {
              ...prev,
              analysis: parsed.analysis_input,
            };
          });
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  }, [cannotCallApi, createPoll, analysisIdentity, autoFmbState, setAutoFmbAnalysisCalculation, setAutoFmbForecastCalculation, setAutoFmbState]);

  const updateAnalysisFmb = useCallback(
    (key: string, value: any) => {
      setErrorInputValidation([]);
      setApiError();
      setAutoFmbAnalysisCalculation(undefined);
      setAutoFmbForecastCalculation(undefined);
      setAutoFmbState((prev) => {
        const prevInputs: any = _.cloneDeep(prev);
        if (!prevInputs?.analysis) return prevInputs;
        prevInputs.analysis = {
          ...prevInputs.analysis,

          [key]: value,
        };
        return prevInputs;
      });
    },
    [setApiError, setAutoFmbState, setAutoFmbAnalysisCalculation, setAutoFmbForecastCalculation]
  );

  const summaryTableData = useMemo(() => {
    // parse the low mid high table into 1 table
    // so 1 row will contain: parameter, low, mid, high, units
    // difference in unit will be ignore
    const result: SummaryFmb = [];

    if (autoFmbAnalysisCalculation?.analysis_results) {
      // take either one, because it is guaranteed to be the same + parse using index
      autoFmbAnalysisCalculation.analysis_results?.summary_cards.low.forEach((item, index) => {
        const midItem = autoFmbAnalysisCalculation.analysis_results?.summary_cards.mid;
        const highItem = autoFmbAnalysisCalculation.analysis_results?.summary_cards.high;

        result.push({
          parameter: item.parameter,
          low: item.value,
          mid: midItem[index]?.value,
          high: highItem[index]?.value,
          units: item.unit,
        });
      });
    }
    return result;
  }, [autoFmbAnalysisCalculation?.analysis_results]);

  const parameterTableRow: Row<any>[] = useMemo(() => {
    const headerNotationRows = [
      {
        rowId: "header",
        cells: [
          ...caseTableColumn.map((column) => {
            return {
              type: "text",
              text: column.label ?? "",
              style: tableHeaderStyle,
              nonEditable: true,
            };
          }),
        ],
        height: 30,
      },
      {
        rowId: "notation",
        cells: [
          ...caseTableColumn.map((column) => {
            return {
              type: "text",
              text: column.notation,
              style: tableHeaderNotationStyle,
              height: 30,
              nonEditable: true,
            };
          }),
        ],
      },
    ];

    const disabled = autoFmbState?.analysis?.auto_update_smart_fit;

    const resultRows = caseKey.map((key: string) => {
      const analysisItem = autoFmbState?.analysis?.parameters?.[key as keyof AnalysisFmbState["parameters"]];
      return {
        rowId: key,
        height: 30,
        cells: [
          {
            type: "text",
            text: safeFmbDictionary[key],
            nonEditable: true,
            style: disabled ? tableCellStyleDisabled : tableCellStyle,
          },
          {
            type: "text",
            text: formatToEngineeringNotation(analysisItem?.area ?? ""),
            style: disabled ? tableCellStyleDisabled : tableCellStyle,
            nonEditable: disabled,
          },
          {
            type: "text",
            text: formatToEngineeringNotation(analysisItem?.permeability ?? ""),
            style: disabled ? tableCellStyleDisabled : tableCellStyle,
            nonEditable: disabled,
          },
        ],
      };
    });
    return [...headerNotationRows, ...resultRows];
  }, [autoFmbState?.analysis?.auto_update_smart_fit, autoFmbState?.analysis?.parameters]);

  const onTableCellChange = useCallback(
    (changes: CellChange[]) => {
      if (!autoFmbState?.analysis) return;
      setErrorInputValidation([]);
      setAutoFmbAnalysisCalculation(undefined);
      const updatedAnalysis: AnalysisFmbState = _.cloneDeep(autoFmbState.analysis);

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell } = change as CellChange<any>;

        updatedAnalysis.parameters[rowId as keyof AnalysisFmbState["parameters"]][columnId as keyof AnalysisFmbParameter] = Number(newCell.text);
      }

      setAutoFmbState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          analysis: {
            ...updatedAnalysis,
          },
        };
      });
    },
    [autoFmbState?.analysis, setAutoFmbState, setAutoFmbAnalysisCalculation]
  );

  const pzPointTableRow: Row<any>[] = useMemo(() => {
    const headerNotationRows = [
      {
        rowId: "header",
        cells: [
          ...manualPointColumn.map((column) => {
            return {
              type: "text",
              text: column.label ?? "",
              style: tableHeaderStyle,
              nonEditable: true,
            };
          }),
        ],
        height: 40,
      },
      {
        rowId: "notation",
        cells: [
          ...manualPointColumn.map((column) => {
            return {
              type: "text",
              text: column.notation,
              style: tableHeaderNotationStyle,
              height: 30,
              nonEditable: true,
            };
          }),
        ],
      },
    ];

    const resultRows =
      [...(autoFmbState?.analysis?.add_p_on_z_points ?? []), ...Array(100).fill(undefined)].map((item, index) => {
        const disabled = index === 0;
        return {
          rowId: index,
          height: 30,
          cells: [
            {
              type: "text",
              text: formatToEngineeringNotation(item?.gas_cumulative_production ?? ""),
              style: disabled ? tableCellStyleDisabled : tableCellStyle,
              nonEditable: disabled,
            },
            {
              type: "text",
              text: formatToEngineeringNotation(item?.static_pressure ?? ""),
              style: disabled ? tableCellStyleDisabled : tableCellStyle,
              nonEditable: disabled,
            },
          ],
        };
      }) ?? [];

    return [...headerNotationRows, ...resultRows];
  }, [autoFmbState?.analysis?.add_p_on_z_points]);

  const onPointTableCellChange = useCallback(
    (changes: CellChange[]) => {
      if (!autoFmbState?.analysis) return;
      setErrorInputValidation([]);
      setAutoFmbAnalysisCalculation(undefined);
      let updatedAnalysisPoint: AnalysisFmbState["add_p_on_z_points"] = _.cloneDeep(autoFmbState.analysis.add_p_on_z_points);

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell } = change as CellChange<any>;

        if (!updatedAnalysisPoint[Number(rowId)]) {
          updatedAnalysisPoint[Number(rowId)] = {
            gas_cumulative_production: 0,
            static_pressure: 0,
          };
        }
        updatedAnalysisPoint[Number(rowId)][columnId as keyof PzPoint] = Number(newCell.text);
        if (updatedAnalysisPoint[Number(rowId)].gas_cumulative_production === 0 && updatedAnalysisPoint[Number(rowId)].static_pressure === 0) {
          updatedAnalysisPoint.splice(Number(rowId), 1);
        }
      }

      setAutoFmbState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          analysis: {
            ...prev.analysis,
            add_p_on_z_points: updatedAnalysisPoint,
          },
        };
      });
    },
    [autoFmbState?.analysis, setAutoFmbState, setAutoFmbAnalysisCalculation]
  );

  // drag chart handlebar
  const onDragChartHandlebar = useCallback(
    async (l: number[][] | [string, number][], index: number, _isEnd?: boolean, line?: FossilyticsChartLine) => {
      if (cannotCallApi || !l || l.length < 2) return;

      try {
        const handlebar: HandlebarCoordinateItem = {
          coordinates: [
            // first line will be plot to y axis, x will always be 0
            { x: 0, y: Number(l[0][1]) },
            // depend on the chart, pi chart have different y plot
            { x: Number(l[1][0]), y: Number(l[1][1]) },
          ],
          identity: line?.id ?? "",
          change_index: index,
        };

        const calculation = await createPoll<AnalysisFmbChartResponse, AnalysisFmbChartRequest>({
          path: pollAutoFmbChartApi(analysisIdentity!.project_id),
          type: "post",
          body: {
            data_set_ids: analysisIdentity!.data_set_ids,
            inputs: autoFmbState!.inputs,
            analysis: {
              ...autoFmbState!.analysis,
              // if user interact mean auto update is false
              auto_update_smart_fit: false,
            },
            handlebar,
          },
        });
        if (calculation.task_result) {
          const parsed = analysisFmbChartResponseScheme.parse(calculation.task_result);
          setAutoFmbAnalysisCalculation(parsed);
          if (!_.isEqual(autoFmbState!.analysis, parsed.analysis_input) || !_.isEqual(autoFmbState!.inputs, parsed.input_page_inputs)) {
            setAutoFmbForecastCalculation(undefined);
            setUpdateInputFromAnalysis(true);
            setAutoFmbState((prev) => {
              if (!prev) return prev;
              return {
                ...prev,
                analysis: parsed.analysis_input,
                inputs: parsed.input_page_inputs,
              };
            });
          }
        }
      } catch (error: any) {
        console.log(error);
      }
    },
    [
      analysisIdentity,
      autoFmbState,
      cannotCallApi,
      createPoll,
      setAutoFmbAnalysisCalculation,
      setUpdateInputFromAnalysis,
      setAutoFmbForecastCalculation,
      setAutoFmbState,
    ]
  );

  return {
    errorInputValidation,
    updateAnalysisFmb,
    autoFmbAnalysisCalculation,
    setAutoFmbAnalysisCalculation,
    onCalculateAnalysisFmb,
    summaryTableData,
    parameterTableRow,
    onTableCellChange,
    pzPointTableRow,
    onPointTableCellChange,
    onDragChartHandlebar,
  };
};
export default useAutoFmbAnalysis;
