import { useCallback, useMemo, useState } from "react";
import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";
import _ from "lodash";

import dictionary from "@/constants/dictionary";
import { tableCellStyle, tableHeaderNotationStyle, tableHeaderStyle } from "@/components/CustomTable";
import { camelToSnakeCase, formatToEngineeringNotation, mapEnumToDropdownReactGrid } from "@/utils/general";

import { UseModelComponentInputProps } from "../KoldunCsgContext";
import { geomechanicBase } from "@/constants/gridTable";
import helpLinkUrl from "@/constants/helpLinkUrl";
import { GeomechanicModelEnum, MatrixShrinkage } from "@/models/InputGeneric";

const geomechanicsHeader: { [key: string]: string } = {
  measure: dictionary.koldunCsg.measure,
  ...geomechanicBase,
};

const defaultParam: { [key: string]: MatrixShrinkage["parameter"] } = {
  [GeomechanicModelEnum.BurgoyneShrivastava]: {
    maximum_productivity_multiplier: 2,
    shrinkage_factor: 0.5,
  },
  [GeomechanicModelEnum.NoModel]: null,
  [GeomechanicModelEnum.PalmerMansoori]: {
    grain_compressibility: 0.0000013,
    langmuir_volume_strain: 0.0123,
    poissons_ratio: 0.39,
    shrinkage_exponent: 3,
    shrinkage_factor: 0.5,
    young_module: 445000,
  },
  [GeomechanicModelEnum.YilmazNur]: {
    permeability_modulus: 0.0015,
  },
};
const useGeomechanics = ({ loadingState, measures, setKoldunCsgState }: UseModelComponentInputProps) => {
  const [activeDropdown, setActiveDropdown] = useState<number>(0);

  const columns = Object.keys(geomechanicsHeader).map((columnId, index) => {
    return { columnId: camelToSnakeCase(columnId), width: index === 0 ? 70 : 135 };
  });

  const rows: Row<any>[] = useMemo(() => {
    if (!measures) return [];
    const clonedMeasures = _.cloneDeep(measures);
    const headerKeys = Object.keys(geomechanicsHeader);
    return [
      {
        rowId: "header",
        cells: headerKeys.map((header, index) => {
          return {
            type: "custom",
            text: geomechanicsHeader[header],
            style: tableHeaderStyle,
            link: helpLinkUrl.koldunCsgHelpLinks.geoMechanics?.[header] ?? null,
          };
        }),
        height: 80,
      },
      {
        rowId: "notation",
        cells: headerKeys.map((header: string) => {
          return {
            type: "header",
            text: dictionary.tableUnits[header],
            style: tableHeaderNotationStyle,
          };
        }),
      },
      ...clonedMeasures.map((measure, rowIndex) => {
        const matrixShrinkage = measure.geomechanics;
        const matrixShrinkageParams = (measure.geomechanics.parameter ?? {}) as { [key: string]: number };
        return {
          rowId: rowIndex,
          height: 30,
          cells: [
            {
              type: "number",
              value: rowIndex + 1,
              nonEditable: true,
              style: tableCellStyle,
            },
            ...headerKeys.slice(1, headerKeys.length).map((header, index) => {
              const val = index === 0 ? matrixShrinkage.selected_geomechanic_model ?? undefined : matrixShrinkageParams[camelToSnakeCase(header)];
              if (index === 0) {
                return {
                  type: "dropdown",
                  selectedValue: val ?? undefined,
                  values: mapEnumToDropdownReactGrid(GeomechanicModelEnum),
                  style: tableCellStyle,
                  isOpen: activeDropdown === rowIndex + 1,
                  nonEditable: loadingState,
                };
              } else if (isNaN(Number(val)) && String(val) === "undefined") {
                return {
                  type: "text",
                  text: "-",
                  style: tableCellStyle,
                  nonEditable: true,
                };
              }

              return {
                type: "text",
                text: formatToEngineeringNotation(val),
                style: tableCellStyle,
                nonEditable: loadingState,
              };
            }),
          ],
        };
      }),
    ];
  }, [activeDropdown, measures, loadingState]);

  const onCellsChanged = useCallback(
    (changes: CellChange[]) => {
      if (!measures) return;
      const updatedRows = [...measures];

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell, previousCell, type } = change as CellChange<any>;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        rowId = rowId as number;
        columnId = columnId as string;

        const matrixShrinkage = updatedRows[rowId].geomechanics;
        const matrixShrinkageParams = updatedRows[rowId].geomechanics.parameter as { [key: string]: any };

        if (type === "dropdown") {
          const newDropdown = dropDownNewCell.isOpen ? rowId + 1 : 0;
          if (prevCell.isOpen !== dropDownNewCell.isOpen && newDropdown !== activeDropdown) {
            setActiveDropdown(dropDownNewCell.isOpen ? rowId + 1 : 0);
          } else {
            setActiveDropdown(0);
          }
          if (dropDownNewCell.isOpen) return;
          if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
            matrixShrinkage.selected_geomechanic_model = dropDownNewCell.selectedValue as GeomechanicModelEnum;
            updatedRows[rowId].geomechanics.parameter = _.cloneDeep(defaultParam[dropDownNewCell.selectedValue as string]);
          }
        } else {
          matrixShrinkageParams[columnId] = newCell.value ?? newCell.text ?? 0;
        }
      }

      setKoldunCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            measures: updatedRows,
          },
        };
      });
    },
    [activeDropdown, measures, setKoldunCsgState]
  );

  return {
    columns,
    rows,
    onCellsChanged,
  };
};

export default useGeomechanics;
