import React, { useMemo } from "react";
import Tabs from "@/components/Tabs";

import { ModelComponentsParamProps } from "../../hooks/AutoRtaContext";
import dictionary from "@/constants/dictionary";
import { InputWaterPvt, InputGasPvt, InputOilPvt, InputLangmuirIsotherm, WellboreInput } from "@/features/shared";

const ModelComponents = ({
  value,
  calculateValue,
  isLoading,
  updateInputGridValue,
  validationError,
  flowingPressureOptions,
}: ModelComponentsParamProps) => {
  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.inputPage.gasPvt}</span>,
        key: dictionary.inputPage.gasPvt,
        content: (
          <InputGasPvt
            validationError={validationError}
            gasPvtCalculation={calculateValue?.gas_pvt_charts}
            gasPvtInput={value?.gas_pvt}
            isLoading={isLoading}
            updateInputValue={updateInputGridValue}
          />
        ),
      },
      {
        key: "Water PVT",
        label: <span>Water PVT</span>,
        content: (
          <InputWaterPvt
            validationError={validationError}
            waterPvtCalculation={calculateValue?.water_pvt_charts}
            waterPvtInput={value?.water_pvt}
            isLoading={isLoading}
            updateInputValue={updateInputGridValue}
          />
        ),
      },
      {
        key: "Oil PVT",
        label: <span>Oil PVT</span>,
        content: (
          <InputOilPvt
            validationError={validationError}
            oilPvtInput={value?.oil_pvt}
            oilPvtCalculation={calculateValue?.oil_pvt_charts}
            isLoading={isLoading}
            updateInputValue={updateInputGridValue}
          />
        ),
      },
      {
        key: "Langmuir Isotherm",
        label: <span>Langmuir Isotherm</span>,
        content: (
          <InputLangmuirIsotherm
            langmuirIsothermInput={value?.langmuir_isotherm}
            langmuirIsothermCalculation={calculateValue?.langmuir_charts}
            isLoading={isLoading}
            updateInputValue={updateInputGridValue}
            validationError={validationError}
          />
        ),
      },
      {
        key: "Wellbore Model",
        label: <span>Wellbore Model</span>,
        content: (
          <WellboreInput
            flowingPressureOptions={flowingPressureOptions}
            updateInputValue={updateInputGridValue}
            wellboreInput={value?.wellbore_model}
            isLoading={isLoading}
            validationError={validationError}
          />
        ),
      },
    ];
  }, [calculateValue, isLoading, validationError, updateInputGridValue, value, flowingPressureOptions]);

  return <Tabs tabList={tabList} centered />;
};

export default ModelComponents;
