import React from "react";

import LoadingIndicator from "@/components/LoadingIndicator";

import { useSpadDeclineState } from "../../hooks/SpadDeclineContext";
import { Container } from "./style";

import SpadDeclineAnalysisParameter from "../SpadDeclineAnalysisParameter";
import SpadDeclineAnalysisChart from "../SpadDeclineAnalysisChart";
import useCompareSpadAnalysis from "../../hooks/useCompareSpadAnalysis";
import { useUserPreference } from "@/features/settings/UserPreference/context";
import CustomCard from "@/components/Card";
import useExpandParameter from "@/utils/useExpandParameter";

const SpadDeclineAnalysis = () => {
  const {
    isLoading,
    spadDeclineState,
    setSpadDeclineState,
    onClickCalculate,
    onClickExport,
    currentModule,
    projects,
    dataSetList,
    selectedDataSets,
    dataSets,
    validationError,
    spadDeclineType,
  } = useSpadDeclineState();
  const { unitPreference } = useUserPreference();

  const { expandParameter } = useExpandParameter();

  const { compareAnalysis, compareName, setCompareName, setCompareDataSet } = useCompareSpadAnalysis({
    currentModule,
  });

  const dataSetIds = dataSets.map((dataset) => dataset.id).join(",");

  return (
    <Container style={{ gridTemplateColumns: expandParameter ? "420px 1fr" : "1fr" }}>
      <CustomCard style={{ height: "95%", overflow: "auto", display: expandParameter ? "unset" : "none" }}>
        <SpadDeclineAnalysisParameter
          unitPreference={unitPreference}
          isLoading={isLoading}
          onClickCalculate={onClickCalculate}
          spadDeclineState={spadDeclineState}
          setSpadDeclineState={setSpadDeclineState}
          onClickExport={onClickExport}
          setCompareDataSet={setCompareDataSet}
          setCompareName={setCompareName}
          dataSets={dataSetList}
          projects={projects}
          selectedDataSets={selectedDataSets}
          validationError={validationError}
          spadDeclineType={spadDeclineType}
        />
      </CustomCard>
      <CustomCard style={{ height: "95%", overflow: "auto" }}>
        <SpadDeclineAnalysisChart key={dataSetIds} compareAnalysis={compareAnalysis} compareName={compareName} />
      </CustomCard>
      {isLoading && <LoadingIndicator />}
    </Container>
  );
};

export default SpadDeclineAnalysis;
