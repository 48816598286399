import useThemeStyling from "@/utils/useThemeStyling";
import LoadingIndicator from "@/components/LoadingIndicator";

import ModelComponents from "./ModelComponents";

import useAutoRtaInputGridTab, { AutoRtaInputGridTabProps } from "../../hooks/rtaInputGrid/useAutoRtaInputGridTab";
import DefinedParameters from "@/features/shared/DefinedParameters";
import InputSummaryCard from "@/features/shared/InputSummaryCard";
import CustomCard from "@/components/Card";

const AutoRtaInputGridContainer = (props: AutoRtaInputGridTabProps) => {
  const { inputGridValues, inputGridCalculate, loadInputGrid, updateInputGridValue, flowingPressureOptions } = useAutoRtaInputGridTab(props);
  const { palette } = useThemeStyling();
  const loadingIndicator = props.isLoading || loadInputGrid;

  // don't display anything if no inputGridValues
  if (!inputGridValues && loadingIndicator) return <LoadingIndicator />;

  return (
    <div
      style={{
        padding: 20,
        overflow: "scroll",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 20,
          marginBottom: 20,
        }}
      >
        <DefinedParameters
          isLoading={loadingIndicator}
          definedParameters={inputGridValues?.defined_parameters}
          onChange={(val, key) => {
            updateInputGridValue(val, key, "defined_parameters");
          }}
          validationError={props.validationError}
        />

        <InputSummaryCard isLoading={loadingIndicator} withCard summaryCard={inputGridCalculate?.summary_card} />
      </div>

      <CustomCard>
        <h3 style={{ color: palette.primary.main, margin: 0 }}>Model Components</h3>
        <div
          style={{
            opacity: loadingIndicator ? 0.5 : 1,
          }}
        >
          <ModelComponents
            flowingPressureOptions={flowingPressureOptions}
            validationError={props.validationError}
            value={inputGridValues}
            calculateValue={inputGridCalculate}
            isLoading={loadingIndicator}
            updateInputGridValue={updateInputGridValue}
          />
        </div>
      </CustomCard>
    </div>
  );
};

export default AutoRtaInputGridContainer;
