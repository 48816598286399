import { TahkCsgInputLayerCharts, TahkCsgInputState, TahkCsgStateResponse } from "@/models/tahk";
import { camelToSnakeCase, formatToEngineeringNotation, mapEnumToDropdownReactGrid } from "@/utils/general";
import {
  dataTableHeaderStyles,
  headerDataTableUnitStyles,
  tableCellStyle,
  tableHeaderNotationStyle,
  tableHeaderStyle,
  waterPvtHeader,
} from "../../../constants/grid";
import { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import dictionary from "@/constants/dictionary";
import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";
import { CustomCell } from "@/components/CustomTable";
import { formatReactGridCell } from "@/util";
import { WaterCompressibilityCorrelation, WaterViscosityCorrelation } from "@/models/InputGeneric";

export type TahkCsgWaterPvtProps = {
  inputs?: TahkCsgInputState;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  loadingState: boolean;
  layers: TahkCsgInputLayerCharts[];
  isTest?: boolean;
};

const useTahkCsgWaterPvt = ({ inputs, setTahkCsgState, loadingState, layers, isTest }: TahkCsgWaterPvtProps) => {
  const [activeDropdown, setActiveDropdown] = useState<{ columnId: string; row: number } | null>(null);
  const [selectedLayer, setSelectedLayer] = useState(0);

  const layerOption = useMemo(() => {
    return Array.from(Array(layers?.length).keys()).map((_, index) => {
      return {
        key: index,
        text: `${dictionary.tahk.layer} ${index + 1}`,
      };
    });
  }, [layers?.length]);

  const waterPvtColumns = Object.keys(waterPvtHeader).map((columnId, index) => {
    return { columnId: camelToSnakeCase(columnId), width: index === 0 ? 50 : 160 };
  });

  const waterPvtRows: Row<any>[] = useMemo(() => {
    if (!inputs) return [];
    const input = _.cloneDeep(inputs);
    const headerKeys = Object.keys(waterPvtHeader);
    return [
      {
        rowId: "header",
        cells: Object.values(waterPvtHeader).map((header) => {
          return {
            type: "header",
            text: header,
            style: tableHeaderStyle,
          };
        }),
        height: 50,
      },
      {
        rowId: "notation",
        cells: headerKeys.map((header: string) => {
          return {
            type: "header",
            text: dictionary.tableUnits[header],
            style: tableHeaderNotationStyle,
          };
        }),
      },
      ...input.layers.map((layer, index) => {
        const waterPvt: { [key: string]: string | number } = layer.water_pvt as { [key: string]: string | number };
        return {
          rowId: index,
          height: 30,
          cells: [
            {
              type: "number",
              value: index + 1,
              nonEditable: true,
              style: tableCellStyle,
            },
            ...headerKeys.slice(1, headerKeys.length).map((header) => {
              const val = waterPvt[camelToSnakeCase(header)];
              if (isNaN(Number(val)) && val) {
                return {
                  type: "dropdown",
                  selectedValue: val ?? null,
                  values: mapEnumToDropdownReactGrid(
                    header === "selectedWaterCompressibilityCorrelation" ? WaterCompressibilityCorrelation : WaterViscosityCorrelation
                  ),
                  style: tableCellStyle,
                  isOpen: !!(activeDropdown && activeDropdown.columnId === camelToSnakeCase(header) && index === activeDropdown.row),
                  nonEditable: loadingState,
                };
              }

              return {
                type: "text",
                text: formatToEngineeringNotation(val),
                style: tableCellStyle,
                nonEditable: loadingState,
                validator: isTest
                  ? null
                  : (text: string) => {
                      return !isNaN(Number(text));
                    },
              };
            }),
          ],
        };
      }),
    ];
  }, [inputs, loadingState, isTest, activeDropdown]);

  const onChangeWaterCustomCells = useCallback(
    (changes: CellChange[]) => {
      if (!inputs) return;
      const updatedRows = [...inputs.layers];

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell, previousCell, type } = change as CellChange<any>;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        rowId = rowId as number;
        columnId = columnId as string;

        const water_pvt = updatedRows[rowId].water_pvt as { [key: string]: any };

        if (type === "dropdown") {
          const newDropdown = {
            row: rowId,
            columnId: columnId,
          };
          if (prevCell.isOpen !== dropDownNewCell.isOpen || !_.isEqual(newDropdown, activeDropdown)) {
            if (dropDownNewCell.isOpen) {
              setActiveDropdown(newDropdown);
            } else {
              setActiveDropdown(null);
            }
          }
          if (dropDownNewCell.isOpen) return;
          if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
            water_pvt[columnId] = dropDownNewCell.selectedValue;
          }
        } else {
          water_pvt[columnId] = newCell.value ?? newCell.text ?? 0;
        }
      }

      setTahkCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            layers: updatedRows,
          },
        };
      });
    },
    [inputs, setTahkCsgState, activeDropdown]
  );

  const waterPvtDataTableRows = useMemo<Row<any>[]>(() => {
    if (!layers) return [];

    const headerRow: Row<CustomCell> = {
      rowId: "header",
      cells: Object.values(dictionary.waterPvtHeaderUnitMapping).map(({ name, sub }) => ({
        type: "custom",
        text: name,
        style: dataTableHeaderStyles,
        sub,
      })),
      height: 40,
    };

    const unitsRow: Row = {
      rowId: "units",
      cells: Object.values(dictionary.waterPvtHeaderUnitMapping).map(({ unit }) => ({
        type: "header",
        text: unit,
        style: headerDataTableUnitStyles,
      })),
    };
    const safeTable: { [key: string]: number[] } = layers[selectedLayer].water_pvt_charts;
    return [
      headerRow,
      unitsRow,
      ...safeTable.pressure.map((_, j) => ({
        rowId: j,
        cells: Object.keys(dictionary.waterPvtHeaderUnitMapping).map((key) => formatReactGridCell(formatToEngineeringNotation(safeTable[key][j]))),
      })),
    ];
  }, [layers, selectedLayer]);

  return {
    selectedLayer,
    setSelectedLayer,
    waterPvtColumns,
    waterPvtRows,
    onChangeWaterCustomCells,
    layerOption,
    waterPvtDataTableRows,
  };
};

export default useTahkCsgWaterPvt;
