import { useCallback, useEffect, useState } from "react";
import { DialogEnum } from "../types";
import { NewGroups, NewProject, Project } from "@/model";
import { getIdFromActiveKey } from "../utils";
import { useAppStore } from "@/features/app";
import { shallow } from "zustand/shallow";
import { parseErrorThrown } from "@/utils/errorHandling";
import { fetchApi } from "@/utils/apiFetcher";
import { deleteDataSetApi, groupApi, projectApi, tenantUserList, transferProjectApi } from "@/constants/apiUrl";

type UseDialogSectionProps = {
  refreshProjects: (delayStateChange?: boolean) => Promise<Project[] | undefined>;
  activeKey: string | undefined;
  setActiveKey: (val: string) => void;
};

const useDialogSection = ({ refreshProjects, activeKey, setActiveKey }: UseDialogSectionProps) => {
  const { postRequest, setGroup, setProject, setSelectedDataSet, setSelectedKey, setApiError, setIsLoading, apiError } = useAppStore(
    (state) => ({
      postRequest: state.postRequest,
      setSelectedKey: state.setSelectedKey,
      setSelectedDataSet: state.setSelectedDataSets,
      setProject: state.setProject,
      setGroup: state.setGroup,
      setIsLoading: state.setIsLoading,
      setApiError: state.setApiError,
      apiError: state.apiError,
    }),
    shallow
  );

  const [activeDialog, setActiveDialog] = useState<DialogEnum | undefined>();
  const [copyFromSelected, setCopyFromSelected] = useState(false);
  const [newGroupsNames, setNewGroupsNames] = useState<string[]>([]);

  const [transferUserIdList, setTransferUserIdList] = useState<string[]>();

  const createNewProject = useCallback(
    async (name: string) => {
      if (!name) return;
      setActiveDialog(undefined);

      const newProject: NewProject = {
        name,
      };

      if (copyFromSelected && activeKey) {
        newProject.copy_from = activeKey;
      }

      try {
        await postRequest("/projects", {}, newProject);

        await refreshProjects();

        setCopyFromSelected(false);

        // force page refresh
        // this is a hacky workaround to get the settings to reload
        window.location.reload();
      } catch (e) {
        console.error(e);
      }
    },
    [copyFromSelected, activeKey, postRequest, refreshProjects]
  );

  const resetState = useCallback(async () => {
    // when user edit project or edit group or delete something reset the state so there's no leftover
    await refreshProjects();

    setProject(undefined);
    setGroup(undefined);
    setSelectedDataSet(undefined);
    setSelectedKey(undefined);
    setActiveKey("");
  }, [refreshProjects, setActiveKey, setGroup, setProject, setSelectedDataSet, setSelectedKey]);

  const editExistingProject = useCallback(
    async (name: string) => {
      if (!name) return;
      // Close the dialog
      setActiveDialog(undefined);
      // Get project and group ID
      if (!activeKey) return;
      const { projectId } = getIdFromActiveKey(activeKey);

      try {
        setIsLoading(true);
        await fetchApi({
          path: projectApi(projectId),
          type: "put",
          body: {
            name,
          },
        });

        resetState();
      } catch (error: any) {
        console.error(error);
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [activeKey, apiError, resetState, setApiError, setIsLoading]
  );

  const deleteProject = useCallback(async () => {
    if (!activeKey) return;
    // Get project and group ID
    const { projectId } = getIdFromActiveKey(activeKey);

    try {
      setIsLoading(true);
      await fetchApi({
        path: projectApi(projectId),
        type: "del",
      });
      await refreshProjects();

      // Close the dialog
      setActiveDialog(undefined);

      // force page refresh
      // this is a hacky workaround to get the settings to reload
      // TODO do this without forcing page refresh
      window.location.reload();
    } catch (error: any) {
      console.error(error);
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
    } finally {
      setIsLoading(false);
    }
  }, [activeKey, apiError, refreshProjects, setApiError, setIsLoading]);

  const removeDataSets = useCallback(async () => {
    // Get project and group ID
    if (!activeKey) return;
    const { projectId, dataSet, groupIds } = getIdFromActiveKey(activeKey);

    try {
      setIsLoading(true);
      await fetchApi({
        path: deleteDataSetApi(projectId, groupIds[groupIds.length - 1]),
        type: "del",
        body: {
          data_set_ids: dataSet,
        },
      });
      resetState();
      // Close the dialog
      setActiveDialog(undefined);
    } catch (error: any) {
      console.error(error);
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
    } finally {
      setIsLoading(false);
    }
  }, [activeKey, apiError, resetState, setApiError, setIsLoading]);

  const createNewGroups = useCallback(
    async (latestName: string) => {
      if ((newGroupsNames.length < 1 && !latestName) || !activeKey) return;
      // Close the dialog
      setActiveDialog(undefined);

      const { projectId, groupIds } = getIdFromActiveKey(activeKey);
      const nameList = newGroupsNames;

      if (latestName) {
        nameList.push(latestName);
      }
      // New groups
      const newGroups: NewGroups = {
        names: newGroupsNames,
      };
      if (groupIds.length > 0) {
        newGroups.parent_id = groupIds[groupIds.length - 1];
      }
      try {
        await postRequest(`/projects/${projectId}/groups`, {}, newGroups);
        // Close the dialog
        setActiveDialog(undefined);
        setNewGroupsNames([]);
        resetState();
      } catch (e) {
        console.error(e);
      }
    },
    [activeKey, newGroupsNames, postRequest, resetState]
  );

  const editExistingGroup = useCallback(
    async (name: string) => {
      if (!name || !activeKey) return;

      const { projectId, groupIds } = getIdFromActiveKey(activeKey);
      const latestGroupId = groupIds[groupIds.length - 1];
      try {
        await fetchApi({
          path: groupApi(projectId, latestGroupId),
          type: "put",
          body: {
            name,
          },
        });

        resetState();
      } catch (error: any) {
        console.error(error);
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [activeKey, apiError, resetState, setApiError, setIsLoading]
  );

  const getTenantUserList = useCallback(async () => {
    try {
      const res = await fetchApi<{ user_ids: string[] }>({ path: tenantUserList, type: "get" });
      if (res.data) setTransferUserIdList(res.data.user_ids);
    } catch (error) {
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
    }
  }, [apiError, setApiError]);

  const transferProject = useCallback(
    async (targetUserId: string) => {
      if (!activeKey) return;
      try {
        const { projectId } = getIdFromActiveKey(activeKey);

        await fetchApi({
          path: transferProjectApi(projectId),
          type: "post",
          body: {
            user_id: targetUserId,
          },
        });
        // force page refresh
        window.location.reload();
      } catch (error) {
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      }
    },
    [activeKey, apiError, setApiError]
  );

  useEffect(() => {
    if (!transferUserIdList && activeDialog === DialogEnum.TRANSFER_PROJECT) {
      getTenantUserList();
    }
  }, [activeDialog, getTenantUserList, transferUserIdList]);

  return {
    activeDialog,
    setActiveDialog,
    createNewProject,
    editExistingProject,
    copyFromSelected,
    setCopyFromSelected,
    deleteProject,
    removeDataSets,
    createNewGroups,
    editExistingGroup,

    newGroupsNames,
    setNewGroupsNames,

    getTenantUserList,
    transferUserIdList,
    transferProject,
  };
};

export default useDialogSection;
