import React, { useCallback, useMemo, useState } from "react";
import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";
import _ from "lodash";

import { TahkCsgInputLayerCharts, TahkCsgInputState, TahkCsgStateResponse, TahkCsgZCorrelationEnum } from "@/models/tahk";
import { camelToSnakeCase, formatToEngineeringNotation, mapEnumToDropdownReactGrid } from "@/utils/general";
import {
  dataTableHeaderStyles,
  gasPvtHeader,
  gasPvtWidth,
  headerDataTableUnitStyles,
  tableCellStyle,
  tableHeaderNotationStyle,
  tableHeaderStyle,
} from "../../../constants/grid";
import dictionary from "@/constants/dictionary";
import { CustomCell } from "@/components/CustomTable";
import { formatReactGridCell } from "@/util";
import helpLinkUrl from "@/constants/helpLinkUrl";
import { ViscosityCorrelationEnum } from "@/models/InputGeneric";

export type TahkCsgGasPvtProps = {
  inputs?: TahkCsgInputState;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  loadingState: boolean;
  layers: TahkCsgInputLayerCharts[];
};

const useTahkCsgGasPvt = ({ inputs, setTahkCsgState, loadingState, layers }: TahkCsgGasPvtProps) => {
  const [activeDropdown, setActiveDropdown] = useState<{ columnId: string; row: number } | null>(null);
  const [selectedLayer, setSelectedLayer] = useState(0);

  const gasPvtColumns = Object.keys(gasPvtHeader).map((columnId) => {
    return { columnId: camelToSnakeCase(columnId), width: gasPvtWidth[columnId] };
  });

  const gasPvtRows: Row<any>[] = useMemo(() => {
    if (!inputs) return [];
    const input = _.cloneDeep(inputs);
    const headerKeys = Object.keys(gasPvtHeader);
    return [
      {
        rowId: "header",
        cells: Object.keys(gasPvtHeader).map((header) => {
          return {
            type: "custom",
            text: gasPvtHeader[header],
            style: tableHeaderStyle,
            link: helpLinkUrl.input?.[header] ?? null,
          };
        }),
        height: 40,
      },
      {
        rowId: "notation",
        cells: headerKeys.map((header: string) => {
          return {
            type: "header",
            text: dictionary.tableUnits[header],
            style: tableHeaderNotationStyle,
          };
        }),
      },
      ...input.layers.map((layer, index) => {
        const gasPvt: { [key: string]: string | number } = layer.gas_pvt as { [key: string]: string | number };
        return {
          rowId: index,
          height: 30,
          cells: [
            {
              type: "number",
              value: index + 1,
              nonEditable: true,
              style: tableCellStyle,
            },
            ...headerKeys.slice(1, headerKeys.length).map((header) => {
              const val = gasPvt[camelToSnakeCase(header)];
              if (isNaN(Number(val)) && val) {
                return {
                  type: "dropdown",
                  selectedValue: val ?? undefined,
                  values: mapEnumToDropdownReactGrid(header === "selectedZCorrelation" ? TahkCsgZCorrelationEnum : ViscosityCorrelationEnum),
                  style: tableCellStyle,
                  isOpen: !!(activeDropdown && activeDropdown.columnId === camelToSnakeCase(header) && index === activeDropdown.row),
                  nonEditable: loadingState,
                };
              }

              return {
                type: "text",
                text: formatToEngineeringNotation(val),
                style: tableCellStyle,
                nonEditable: loadingState,
              };
            }),
          ],
        };
      }),
    ];
  }, [inputs, loadingState, activeDropdown]);

  const onChangeGasCustomCell = useCallback(
    (changes: CellChange[]) => {
      if (!inputs) return;
      const updatedRows = [...inputs.layers];

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell, previousCell, type } = change as CellChange<any>;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        rowId = rowId as number;
        columnId = columnId as string;

        const gasPvt = updatedRows[rowId].gas_pvt as { [key: string]: any };

        if (type === "dropdown") {
          const newDropdown = {
            row: rowId,
            columnId: columnId,
          };
          if (prevCell.isOpen !== dropDownNewCell.isOpen || !_.isEqual(newDropdown, activeDropdown)) {
            if (dropDownNewCell.isOpen) {
              setActiveDropdown(newDropdown);
            } else {
              setActiveDropdown(null);
            }
          }
          if (dropDownNewCell.isOpen) return;
          if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
            gasPvt[columnId] = dropDownNewCell.selectedValue;
          }
        } else {
          gasPvt[columnId] = newCell.value ?? newCell.text ?? 0;
        }
      }

      setTahkCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            layers: updatedRows,
          },
        };
      });
    },
    [inputs, setTahkCsgState, activeDropdown]
  );

  const gasPvtDataTableRows = useMemo<Row<any>[]>(() => {
    if (!layers) return [];

    const headerRow: Row<CustomCell> = {
      rowId: "header",
      cells: Object.values(dictionary.gasPvtHeaderUnitMapping).map(({ name, sub }) => ({
        type: "custom",
        text: name,
        style: dataTableHeaderStyles,
        sub,
      })),
      height: 40,
    };

    const unitsRow: Row = {
      rowId: "units",
      cells: Object.values(dictionary.gasPvtHeaderUnitMapping).map(({ unit }) => ({
        type: "header",
        text: unit,
        style: headerDataTableUnitStyles,
      })),
    };
    const safeTable: { [key: string]: number[] } = layers[selectedLayer].gas_pvt_charts;
    return [
      headerRow,
      unitsRow,
      ...safeTable.pressure.map((_, j) => ({
        rowId: j,
        cells: Object.keys(dictionary.gasPvtHeaderUnitMapping).map((key) => formatReactGridCell(formatToEngineeringNotation(safeTable[key][j]))),
      })),
    ];
  }, [layers, selectedLayer]);

  const layerOption = useMemo(() => {
    return Array.from(Array(layers?.length).keys()).map((_, index) => {
      return {
        key: index,
        text: `${dictionary.tahk.layer} ${index + 1}`,
      };
    });
  }, [layers?.length]);

  return {
    gasPvtColumns,
    onChangeGasCustomCell,
    gasPvtRows,
    gasPvtDataTableRows,
    selectedLayer,
    setSelectedLayer,
    layerOption,
  };
};

export default useTahkCsgGasPvt;
