import { useAutoFmbState } from "../../context/AutoFmbContext";
import useAutoFmbForecast from "../../hooks/useAutoFmbForecast";
import ForecastChartFmb from "./ForecastChart";
import ForecastParameter from "./ForecastParameter";

const AutoFmbForecast = () => {
  const {
    autoFmbState,
    setAutoFmbState,
    analysisIdentity,
    tabIndex,
    isLoading,
    setApiError,
    apiError,
    setIsLoading,
    setProgress,
    setPollStatus,
    setAutoFmbForecastCalculation,
    autoFmbForecastCalculation,
    expandParameter,
  } = useAutoFmbState();

  const {
    onCalculateForecastFmb,
    flowingPressureRow,
    onTableCellChange,
    updateForecastFmb,
    errorInputValidation,
    summaryTableData,
    onExportForecastFmb,
  } = useAutoFmbForecast({
    autoFmbState,
    setAutoFmbState,
    analysisIdentity,
    tabIndex,
    isLoading,
    setApiError,
    apiError,
    setIsLoading,
    setProgress,
    setPollStatus,
    setAutoFmbForecastCalculation,
    autoFmbForecastCalculation,
  });

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: expandParameter ? "480px 1fr" : "1fr",
        gridGap: 20,
        padding: "1em",
      }}
    >
      <div
        style={{
          display: expandParameter ? "unset" : "none",
        }}
      >
        <ForecastParameter
          isLoading={isLoading}
          forecastFmbState={autoFmbState?.forecast}
          onChangeParameter={updateForecastFmb}
          summaryTableData={summaryTableData}
          errorInputValidation={errorInputValidation}
          onTableCellChange={onTableCellChange}
          parameterTableRow={flowingPressureRow}
          onClickCalculate={onCalculateForecastFmb}
          onClickExport={onExportForecastFmb}
        />
      </div>

      <ForecastChartFmb autoFmbState={autoFmbState} isLoading={isLoading} autoFmbForecastCalculation={autoFmbForecastCalculation} />
    </div>
  );
};

export default AutoFmbForecast;
