import { useMemo } from "react";
import { Row, Column } from "@silevis/reactgrid";

import { tableHeaderNotationStyle, tableHeaderStyle, CustomCell } from "@/components/CustomTable";
import { PvtTableHeaderMap } from "@/models/Generic";

import { LangmuirIsotherm, LangmuirIsothermDataTable } from "@/models/InputGeneric";
import { formatToEngineeringNotation } from "@/utils/general";
import useThemeStyling from "@/utils/useThemeStyling";
import dictionary from "@/constants/dictionary";
import useChartInfo from "@/utils/useChartInfo";
import { FossilyticsChartSeries } from "@/components/FossilyticsChart";

const langmuirIsothermHeaderUnitMapping: { [key: string]: PvtTableHeaderMap } = {
  pressure: { name: "Pressure", unit: "(psia)" },
  gas_content: { name: "gas content", unit: "(scf/ton)" },
  desorption_compressibility: { name: "c", unit: "(psi⁻¹)", sub: "d" },
};

const langmuirIsothermDataTableCols = [
  { columnId: "pressure", width: 120 },
  { columnId: "gas_content", width: 120, justifyContent: "center" },
  { columnId: "desorption_compressibility", width: 120, justifyContent: "center" },
] as Column[];

type UseLangmuirIsothermProps = {
  langmuirIsothermCalculation?: LangmuirIsothermDataTable;
  langmuirIsothermInput?: LangmuirIsotherm;
};
const useLangmuirIsotherm = ({ langmuirIsothermCalculation, langmuirIsothermInput }: UseLangmuirIsothermProps) => {
  const { palette } = useThemeStyling();

  const langmuirIsothermDataTableRows = useMemo<Row[]>(() => {
    if (!langmuirIsothermCalculation) return [];

    const headerRow: Row<CustomCell> = {
      rowId: "header",
      height: 35,
      cells: Object.values(langmuirIsothermHeaderUnitMapping).map(({ name, sub }) => ({
        type: "custom",
        text: name,
        style: tableHeaderStyle,
        sub,
      })),
    };

    const unitsRow: Row = {
      rowId: "units",
      cells: Object.values(langmuirIsothermHeaderUnitMapping).map(({ unit }) => ({
        type: "header",
        text: unit,
        style: tableHeaderNotationStyle,
      })),
    };

    return [
      headerRow,
      unitsRow,
      ...langmuirIsothermCalculation.gas_content.map((_, j: number) => ({
        rowId: j,
        cells: Object.keys(langmuirIsothermHeaderUnitMapping).map((key) => {
          const safeParse: any = langmuirIsothermCalculation;
          return {
            type: "text",
            text: formatToEngineeringNotation(safeParse[key as keyof LangmuirIsothermDataTable][j]),
            style: { display: "flex", justifyContent: "center" },
            nonEditable: true,
          };
        }),
      })),
    ] as Row[];
  }, [langmuirIsothermCalculation]);

  const langmuirIsothermGasContentSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (langmuirIsothermCalculation) {
      const gasContent = langmuirIsothermCalculation.desorption_gas_content;
      const desorptionPressure = langmuirIsothermInput?.desorption_pressure ?? 0;
      return [
        {
          name: dictionary.inputPage.langmuirIsothermGc,
          type: "line",
          hideSymbol: true,
          color: palette.customColor.orangeLighter,
          lineWidth: 2,
          z: 2,
          data: langmuirIsothermCalculation.pressure.map((p, j) => [p, langmuirIsothermCalculation.gas_content[j]]),
        },
        {
          name: dictionary.inputPage.langmuirIsothermDesorptionGc,
          type: "line",
          hideSymbol: true,
          color: palette.customColor.black,
          lineWidth: 2,
          lineType: "dashed",
          z: 2,
          data: [
            [0, gasContent],
            [desorptionPressure, gasContent],
            [desorptionPressure, 0],
          ],
        },
        {
          name: dictionary.inputPage.langmuirIsothermDesorptionGcDot,
          type: "scatter",
          color: palette.customColor.black,
          z: 2,
          symbolSize: 15,
          data: [[desorptionPressure, gasContent]],
        },
      ];
    }
    return [];
  }, [langmuirIsothermCalculation, langmuirIsothermInput?.desorption_pressure, palette.customColor.orangeLighter, palette.customColor.black]);

  const langmuirGasContentAxes = useChartInfo({
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.gasContent,
    seriesName: dictionary.inputPage.gasZ,
    chartPlot: [],
    seriesColor: palette.customColor.orangeLighter,
  });

  const langmuirIsothermCd = useChartInfo({
    seriesColor: palette.customColor.orangeLighter,
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.gasContent,
    seriesName: dictionary.inputPage.langmuirIsothermCd,
    chartPlot: langmuirIsothermCalculation
      ? langmuirIsothermCalculation.pressure.map((p, j) => [p, langmuirIsothermCalculation.desorption_compressibility[j]])
      : [],
  });

  const langmuirIsothermGasContent = useMemo(() => {
    return {
      xAxes: langmuirGasContentAxes.xAxes,
      yAxes: langmuirGasContentAxes.yAxes,
      series: langmuirIsothermGasContentSeries,
    };
  }, [langmuirGasContentAxes.xAxes, langmuirGasContentAxes.yAxes, langmuirIsothermGasContentSeries]);

  return {
    langmuirIsothermDataTableCols,
    langmuirIsothermDataTableRows,
    langmuirIsothermGasContent,
    langmuirIsothermCd,
  };
};

export default useLangmuirIsotherm;
