import { object, z } from "zod";
import { defineParametersScheme, gasPvtScheme, langmuirIsothermScheme, oilPvtScheme, waterPvtScheme, wellboreInputScheme } from "../InputGeneric";

export const fmbInputStateScheme = object({
  defined_parameters: defineParametersScheme,
  gas_pvt: gasPvtScheme,
  water_pvt: waterPvtScheme,
  oil_pvt: oilPvtScheme,
  langmuir_isotherm: langmuirIsothermScheme,
  wellbore_model: wellboreInputScheme,
});

export type FmbInputState = z.infer<typeof fmbInputStateScheme>;
